/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components

// react-router components
import { Link } from "react-router-dom";
import Chip from "@mui/material/Chip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";

export default function data() {
  const onSearchValueChange = (event) => {
    setSearchValue(event.target.value);
  };

  const actions = (id) => (
    <MDBox display="flex" alignItems="end" lineHeight={1}>
      <Link to={`/admin/noticias/${id}`}>
        <EditIcon />
      </Link>
    </MDBox>
  );
  const api_key = localStorage.getItem("access_token") || "";

  // const [rows, setRows] = useState([]);
  const [filterModel, setFilterModel] = useState({
    items: [],
    quickFilterExcludeHiddenColumns: true,
    quickFilterValues: [""],
  });
  const columnVisibilityModel = {
    id: false,
  };
  const [searchValue, setSearchValue] = useState("");
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const columns = [
    {
      field: "id",
      type: "string",
      headerName: "",
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 30,
      sortable: true,
    },
    {
      field: "fecha_publicacion",
      type: "string",
      headerName: "FECHA DE PUBLICACIÓN",
      flex: 1,
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      sortable: true,
    },
    {
      field: "title",
      type: "string",
      headerName: "TÍTULO",
      flex: 1,
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      sortable: true,
    },
    {
      field: "active",
      type: "string",
      headerName: "ACTIVO",
      flex: 1,
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      sortable: false,
      renderCell: (params) => (
        <>
          {params.value ? (
            <Chip label="ACTIVO" color="success" variant="outlined" />
          ) : (
            <Chip label="INACTIVO" color="error" variant="outlined" />
          )}
        </>
      ),
    },
    {
      field: "actions",
      type: "string",
      headerName: "",
      flex: 1,
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      sortable: false,
      renderCell: (params) => actions(params.id),
    },
  ];

  useEffect(() => {
    setFilteredRows(rows);
  }, [rows]);

  useEffect(() => {
    setFilteredRows(
      rows.filter((row) =>
        Object.values(row).some((val) =>
          val.toString().toLowerCase().includes(searchValue.toLowerCase())
        )
      )
    );
  }, [searchValue]);

  useEffect(() => {
    // Asegúrate de cambiar la URL por la del endpoint correcto de tu backend
    fetch(`${process.env.REACT_APP_API_BASE_URL}/noticias`, {
      method: "GET",
      headers: {
        authToken: api_key,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setRows(
          data
            .sort((a, b) => {
              const nameA = a.pos;
              const nameB = b.pos;
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            })
            .map((item) => ({
              id: item.ID,
              fecha_publicacion: item.FECHA_PUBLICACION,
              title: item.TITULO,
              active: item.ACTIVO,
            }))
        );
      })
      .catch((error) => console.error("Error fetching data: ", error));
  }, []); // El array vacío asegura que el efecto se ejecute solo una vez

  const [dialogOpen, setDialogOpen] = useState(false);
  const [submitForm, setSubmitForm] = useState(false);
  const [formState, setFormState] = useState({
    image: "",
  });

  return {
    columns: columns,
    rows,
    filteredRows,
    searchValue,
    filterModel,
    columnVisibilityModel,
    onSearchValueChange,
    setFilteredRows,
    setFilterModel,
  };
}
