import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Grid,
  Box,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Container,
} from "@mui/material";
import { TraduccionesContext } from "components/shared/TraduccionesContext";
import ContactoCover from "../../../assets/images/Contacto.png";
import IconMail from "../../../assets/images/icon-mail.svg";
import IconPhone from "../../../assets/images/icon-phone.svg";
import HeaderFrontOffice from "../../../components/FrontOfficeHeaders/HeaderFrontOffice";
import { FrontOfficeFooter } from "../../../components/FrontOfficeFooter";
import { ContactForm } from "../../../components/ContactForm";
import SeoRoutesContext from "../../../components/shared/SeoRoutesContext";
import { Helmet } from "react-helmet";

function ContactosPage() {
  const { traducciones } = useContext(TraduccionesContext);
  const seoRoutes = useContext(SeoRoutesContext);
  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${document.location.href}`} />
        {seoRoutes
          .filter((x) => x.SEO_PAGE_ID === "8")
          .map(
            (
              lang,
              index // `index` es el índice del elemento actual en el array
            ) => (
              <link
                key={lang.ACRONIMO_ISO + index} // Concatenación del ISO y el índice para asegurar unicidad
                rel="alternate"
                hrefLang={lang.ACRONIMO_ISO.toLowerCase()}
                href={`${document.location.origin}/${lang.ACRONIMO_ISO.toLowerCase()}/${
                  lang.SEO_URL_FRIENDLY.endsWith("/")
                    ? lang.SEO_URL_FRIENDLY
                    : lang.SEO_URL_FRIENDLY + "/"
                }`}
              />
            )
          )}
      </Helmet>
      <Box>
        <HeaderFrontOffice keySelected="contacto" />
      </Box>
      <Box
        sx={{ background: "#FFF", display: "flex", justifyContent: "center", marginTop: "64px" }}
      >
        <Container
          sx={{
            paddingTop: "3rem",
            maxWidth: {
              xl: "1320px!important", // max-width 1320px para xl
              lg: "1140px!important", // max-width 1140px para lg
              md: "100%!important", // max-width 100% para md
              sm: "100%!important", // max-width 100% para sm
              xs: "100%!important", // max-width 100% para xs
            },
            "& *": { fontFamily: "Nunito !important" },
          }}
        >
          <Grid container sx={{ marginBottom: "2rem" }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={7}
              xl={7}
              padding={2}
              paddingLeft={0}
              display={"flex"}
              alignContent={"center"}
              flexWrap={"wrap"}
              justifyContent={{
                xs: "center",
                lg: "flex-end",
                xl: "flex-end",
              }}
              sx={{
                "@media (min-width:991px) and (max-width:1100px)": {
                  justifyContent: "flex-start", // Esto hace que se oculte a partir de 920px
                },
              }}
            >
              <Box
                component="img"
                src={ContactoCover}
                alt="Contacto"
                style={{
                  maxWidth: "666px",
                  maxHeight: "500px",
                  aspectRatio: "666/500",
                  textAlign: "center",
                }}
                sx={{
                  width: {
                    xs: "100%", // max-width 100% para xs
                    sm: "100%", // max-width 100% para sm
                    md: "100%", // max-width 100% para md
                    lg: "100%", // max-width 1140px para lg
                    xl: "100%", // max-width 1320px para xl
                  },

                  "@media (min-width:1400px)": {
                    width: "auto", // Esto hace que se oculte a partir de 920px
                  },
                  "@media (min-width:991px) and (max-width:1100px)": {
                    width: "80%", // Esto hace que se oculte a partir de 920px
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={5}
              xl={5}
              padding={2}
              paddingLeft={"0"}
              display={"flex"}
              sx={{
                justifyContent: {
                  xs: "center",
                  lg: "flex-end!important", // max-width 1140px para lg
                },
              }}
            >
              <Box
                sx={{
                  display: {
                    xs: "block", // max-width 100% para xs
                    lg: "flex", // max-width 1140px para lg
                  },

                  maxWidth: {
                    xs: "100%", // max-width 100% para xs
                    lg: "408px", // max-width 1140px para lg
                  },

                  flexDirection: "column",
                }}
              >
                <Box>
                  <Typography
                    gutterBottom
                    fontSize={"40px"}
                    sx={{
                      lineHeight: "1",
                      color: "#222222",
                      fontWeight: "bold",
                      marginBottom: 0,
                      paddingTop: "5px",
                    }}
                  >
                    {traducciones && traducciones.CONTACTO.form_title}
                  </Typography>
                  <Typography fontSize={"16px"}>
                    {traducciones && traducciones.CONTACTO.form_subtitle}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    marginTop: "25px",
                  }}
                >
                  <Box sx={{ width: "408px" }}>
                    <ContactForm rightAligned={true} />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container sx={{ marginTop: "4rem", textAlign: "center" }}>
            <Grid item xs={12}>
              <Typography
                marginBottom={"5px"}
                fontSize={"40px"}
                sx={{ lineHeight: "1", color: "#222222", fontWeight: "bold" }}
              >
                {traducciones && traducciones.CONTACTO.address_title}
              </Typography>
              <Typography fontSize={"16px"} color={"#6C6B70"}>
                {traducciones && traducciones.CONTACTO.address_subtitle}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Grid
        container
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "#FFF",
          paddingTop: "48px",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          padding={2}
          paddingLeft={{
            xs: "16px",
            md: "0px",
          }}
        >
          <iframe
            src={traducciones && traducciones.CONTACTO.address_two_iframe}
            width="600"
            height="450"
            style={{ border: 0, width: "100%" }}
            allowfullscreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          padding={2}
          paddingRight={{
            xs: "16px",
            md: "0px",
          }}
        >
          <iframe
            src={traducciones && traducciones.CONTACTO.address_one_iframe}
            width="600"
            height="450"
            style={{ border: 0, width: "100%" }}
            allowfullscreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </Grid>
      </Grid>

      <Box sx={{ background: "#FFF", display: "flex", justifyContent: "center" }}>
        <Container
          sx={{
            paddingTop: "3rem",
            paddingBottom: "63px",
            "& *": { fontFamily: "Nunito !important" },
          }}
        >
          <Grid container sx={{ marginBottom: "2rem" }}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} padding={2} paddingLeft={0}>
              <Typography
                gutterBottom
                fontSize={"40px"}
                sx={{ lineHeight: "1", color: "#222222", fontWeight: "bold" }}
              >
                {traducciones && traducciones.CONTACTO.contact_title}
              </Typography>
              <Typography fontSize={"16px"} width={"408px"}>
                {traducciones && traducciones.CONTACTO.contact_subtitle}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              padding={2}
              sx={{
                paddingLeft: {
                  xs: "0",
                  xl: "77.5px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: {
                    xs: "start",
                    sm: "start",
                    md: "start",
                    lg: "start",
                    xl: "start",
                  },
                  width: "100%",
                  alignItems: "center",
                  gap: ".5rem",
                  marginBottom: "0.5rem",
                }}
              >
                <img src={IconMail} alt="email" />
                <Link
                  style={{ fontWeight: "bold", fontSize: "19px", color: "#000000de" }}
                  to={traducciones && "mailto:" + traducciones.CONTACTO.contact_email}
                >
                  {traducciones && traducciones.CONTACTO.contact_email}
                </Link>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: {
                    xs: "start",
                    xl: "start",
                  },
                  width: "100%",
                  alignItems: "center",
                  gap: ".5rem",
                  marginBottom: "0.5rem",
                }}
              >
                <img src={IconPhone} alt="phone" />
                <Link
                  style={{ fontWeight: "bold", fontSize: "19px", color: "#000000de" }}
                  to={traducciones && "tel:" + traducciones.MASTER.contact_number_link}
                >
                  {traducciones && traducciones.CONTACTO.contact_number}
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <FrontOfficeFooter />
    </>
  );
}
export default ContactosPage;
