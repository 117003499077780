import React, { useState, useContext, useEffect } from "react";
import { Grid, Box, Typography, Container } from "@mui/material";
import { useLocation } from "react-router-dom";
import { ServiceCard } from "../../../components/ServiceCard";
import HeaderFrontOffice from "../../../components/FrontOfficeHeaders/HeaderFrontOffice";
import { FrontOfficeFooter } from "../../../components/FrontOfficeFooter";
import { ContactForm } from "../../../components/ContactForm";
import { TraduccionesContext } from "components/shared/TraduccionesContext";
import { Component } from "react-image-crop";
import { Helmet } from "react-helmet";
import SeoRoutesContext from "../../../components/shared/SeoRoutesContext";

function ServiciosPage() {
  const { pathname } = useLocation();
  const seoRoutes = useContext(SeoRoutesContext);
  const { traducciones, loading, setLoading, idioma } = useContext(TraduccionesContext);
  const [services, setServices] = useState([]);
  useEffect(() => {
    const cargarServicios = async () => {
      setLoading(true);
      try {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/services/list/${idioma}`, {
          method: "GET",
        })
          .then((response) => response.json())
          .then((data) => {
            setLoading(false);
            setServices(
              data.response.data
                .filter((item) => item.active)
                .sort((a, b) => {
                  const nameA = a.pos;
                  const nameB = b.pos;
                  if (nameA < nameB) {
                    return -1;
                  }
                  if (nameA > nameB) {
                    return 1;
                  }
                  return 0;
                })
                .map((item) => ({
                  title: item.title,
                  content:
                    item.description.length > 300
                      ? item.description.slice(0, 300) + "..."
                      : item.description,
                  url: `${
                    window.location.pathname.split("/").filter((segment) => segment !== "")[0]
                  }/${item.seoURLFriendly}/`,
                  // url: `${pathname.substring(0, pathname.lastIndexOf("/"))}/${
                  //  item.seoURLFriendly
                  //},
                  // url: `${pathname}/${item.id}/${item.seoURLFriendly}`,
                  seoURLFriendly: item.seoURLFriendly,
                }))
            );
          })
          .catch((error) => {
            setLoading(false);
            console.error("Error fetching data: ", error);
          });
      } catch (error) {
        setLoading(false);
        console.error("Error al cargar los datos:", error);
      }
    };
    if (idioma) cargarServicios();
  }, [idioma]);
  return (
    <>
      {" "}
      <Helmet>
        <link rel="canonical" href={`${document.location.href}`} />
        {seoRoutes
          .filter((x) => x.SEO_PAGE_ID === "2")
          .map(
            (
              lang,
              index // `index` es el índice del elemento actual en el array
            ) => (
              <link
                key={lang.ACRONIMO_ISO + index} // Concatenación del ISO y el índice para asegurar unicidad
                rel="alternate"
                hrefLang={lang.ACRONIMO_ISO.toLowerCase()}
                href={`${document.location.origin}/${lang.ACRONIMO_ISO.toLowerCase()}/${
                  lang.SEO_URL_FRIENDLY.endsWith("/")
                    ? lang.SEO_URL_FRIENDLY
                    : lang.SEO_URL_FRIENDLY + "/"
                }`}
              />
            )
          )}
      </Helmet>
      <Box>
        <HeaderFrontOffice keySelected="servicios" />
      </Box>
      <Box
        sx={{
          background: "#FFF",
          display: "flex",
          justifyContent: "center",
          marginTop: "64px",
        }}
      >
        <Container
          sx={{
            paddingTop: "70px",
            paddingBottom: "100px",
            "& *": {
              fontFamily: "Nunito !important",
              maxWidth: "1152px",
            },
            maxWidth: {
              xl: "1320px!important", // max-width 1320px para xl
              lg: "1140px!important", // max-width 1140px para lg
              md: "100%!important", // max-width 100% para md
              sm: "100%!important", // max-width 100% para sm
              xs: "100%!important", // max-width 100% para xs
            },
          }}
        >
          <Grid
            container
            sx={{
              marginBottom: "2rem",
              textAlign: "center",
              marginBottom: "54px",
            }}
          >
            <Grid item xs={12}>
              <Typography
                gutterBottom
                fontSize={"48px"}
                sx={{ lineHeight: "1", color: "#222222", fontWeight: "bold" }}
                component={"h1"}
              >
                {traducciones && traducciones.SERVICIOS.title}
              </Typography>
              <Typography fontSize={"18px"}>
                {traducciones && traducciones.SERVICIOS.subtitle}
              </Typography>
            </Grid>
          </Grid>
          {!loading && (
            <Grid container rowGap={"56px"} justifyContent={"center"}>
              {services.map((service) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  key={service.title}
                  sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                >
                  <ServiceCard {...service} />
                </Grid>
              ))}
            </Grid>
          )}
          <Grid container sx={{ margin: "70px 0 0 0", textAlign: "center" }}>
            <Grid item xs={12}>
              <Typography
                gutterBottom
                fontSize={"40px"}
                sx={{ lineHeight: "1", color: "#222222", fontWeight: "bold" }}
                component={"h3"}
              >
                {traducciones && traducciones.CONTACTO.form_title}
              </Typography>
              <Typography fontSize={"16px"}>
                {traducciones && traducciones.CONTACTO.form_subtitle}
              </Typography>
            </Grid>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "2rem",
              }}
            >
              <Box sx={{ width: "408px" }}>
                <ContactForm rightAligned={false} />
              </Box>
            </Box>
          </Grid>
        </Container>
      </Box>
      <FrontOfficeFooter />
    </>
  );
}
export default ServiciosPage;
