import React, { useContext, useState, useEffect } from "react";
import Cookies from "js-cookie";
import { TraduccionesContext } from "../shared/TraduccionesContext";

const CookiePanel = () => {
  const [hasCookie, setHasCookie] = useState(false);
  const [cookieValue, setCookieValue] = useState(Cookies.get("cookieValue")); // Initialize with the current cookie value
  const [cookieScript, setCookieScript] = useState(null);
  const { idioma, idiomaISO } = useContext(TraduccionesContext);
  useEffect(() => {
    // Update hasCookie based on the presence of cookieValue
    setHasCookie(!!cookieValue);
  }, [cookieValue]); // Dependency on cookieValue to trigger re-evaluation

  useEffect(() => {
    var iso = Cookies.get("lang") || "es";
    document.documentElement.lang = iso; // Establece el atributo lang en la etiqueta <html>
  }, [idioma]);

  const getScriptCookie = async (id) => {
    if (cookieValue) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/Cookies/buscar/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Add any additional headers you need, such as authorization token
          },
        });

        if (!response.ok) {
          throw new Error("Error al obtener el script de la cookie");
        }

        const data = await response.json();
        const cookieArray = cookieValue.split("&").map((item) => item.replace("checkitem", ""));
        for (const item of data) {
          const filteredCookie = cookieArray.find((cookie) => {
            const [id, value] = cookie.split("=");
            return id == item.IdCookie && value.toLowerCase() === "true";
          });

          if (filteredCookie) {
            // Aquí puedes hacer lo que necesites con los elementos que cumplen la condición
            setCookieScript(item.Script);
          }
        }
        // setCookieScript(data[0].Script.replaceAll("<script>", "").replaceAll("</script>", ""));
        return data[0].Script;
      } catch (error) {
        console.error("Error al obtener el script de la cookie:", error);
        return null; // Return null if there's an error
      }
    }
  };

  useEffect(() => {
    getScriptCookie(1);
  }, []); // Run only once when component mounts

  useEffect(() => {
    if (cookieScript) {
      // Buscar todos los scripts en cookieScript
      const scripts = cookieScript.match(/<script[^>]*>[\s\S]*?<\/script>/gi);

      // Iterar sobre los scripts encontrados
      if (scripts) {
        for (const script of scripts) {
          // Verificar si el script tiene un atributo src
          const srcMatch = script.match(/src="([^"]+)"/);
          if (srcMatch) {
            // Limpiar y normalizar el valor del src
            const scriptSrc = srcMatch[1].trim().replace(/\s+/g, "");

            // Verificar si el script ya existe en el documento de forma segura
            const existingScript = Array.from(document.getElementsByTagName("script")).find(
              (el) => el.src === scriptSrc
            );

            if (!existingScript) {
              // Crear y añadir el script con src
              const scriptElement = document.createElement("script");
              scriptElement.async = true;
              scriptElement.src = scriptSrc;
              document.head.appendChild(scriptElement);
            }
          } else {
            // Extraer el contenido del script
            const scriptContent = script.replace(/<\/?script>/g, "");
            const scriptHash = btoa(scriptContent);

            // Verificar si el script con el mismo contenido ya existe
            const existingScript = Array.from(document.getElementsByTagName("script")).find(
              (el) => el.getAttribute("data-content") === scriptHash
            );

            if (!existingScript) {
              // Crear y añadir el script con contenido interno
              const scriptElement = document.createElement("script");
              scriptElement.innerHTML = scriptContent;
              scriptElement.setAttribute("data-content", scriptHash);
              document.head.appendChild(scriptElement);
            }
          }
        }
      }
    }
  }, [cookieScript]);

  return (
    <>
      {
        <div>
          {!hasCookie && ( // If the cookie is not found, render the iframe
            <iframe
              src={`/cookiePanel/cookie-display.html?api=${encodeURIComponent(
                process.env.REACT_APP_API_BASE_URL
              )}`}
              style={{
                width: "100%",
                height: "100vh",
                position: "fixed",
                top: "0",
                left: "0",
                border: "none",
                zIndex: "9999",
              }}
              title="Cookie Consent"
            ></iframe>
          )}
        </div>
      }
    </>
  );
};
export default CookiePanel; // Aquí exporta correctamente el componente
