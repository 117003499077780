/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useFormik, Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useFormikContext } from "formik";
import {
  TextField,
  Button,
  FormHelperText,
  Switch,
  FormControlLabel,
  Divider,
} from "@mui/material";
import MDInput from "components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
// Eliminado import { Autocomplete as FormikAutocomplete } from "formik-mui-lab";
import PropTypes from "prop-types";
import { Grid, Card, Box } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getLanguages, fetchData, saveData, saveImgFolder } from "./Data";
import colors from "assets/theme/base/colors";
import { UploadImageBanner } from "components/shared/UploadImageBanner";
import { Cropper } from "components/Cropper/Cropper";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Close } from "@mui/icons-material";
import { useDropzone } from "react-dropzone";

function Edit() {
  const [idiomas, setIdiomas] = useState([]);
  const [idiomaSeleccionado, setIdiomaSeleccionado] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({
    ID: 0,
    LANGUAGE_ID: 1,
    ACTIVO: true,
    LOGUED_ID: 1,
    NOMBRE: "Nombre y Apellido",
    IMAGEN: "",
    TIENE_DETALLE: false,
    POS: 1,
    SUBTITULO: "Subtítulo lorem ipsum",
    DESC_CORTA: "lorem",
    DESC_LARGA: "lorem",
    UBICACION: "lorem",
    ESTUDIOS: "lorem",
    CONOCIMIENTOS: "lorem",
    SEO_TITLE: "lorem",
    SEO_METAKEYWORD: "lorem",
    SEO_METADESCRIPTION: "lorem",
    SEO_URL_FRIENDLY: "lorem",
  });

  useEffect(() => {
    const loadLanguages = async () => {
      const langs = await getLanguages();
      setIdiomas(langs);
      const idiomaPorDefecto = langs.find((idioma) => idioma.ID == 1);
      // Evita establecer el idioma por defecto aquí si ya tienes uno seleccionado
      if (idiomaPorDefecto && !idiomaSeleccionado) {
        setIdiomaSeleccionado(idiomaPorDefecto);
      }
    };
    loadLanguages();
  }, []);
  useEffect(() => {
    // Carga los datos iniciales
    const loadData = async () => {
      if (!idiomaSeleccionado) return;
      const data = await fetchData(id, idiomaSeleccionado.ID); // Asumiendo que fetchData es una función que obtiene los datos del registro por su ID
      setData(
        data[0]
        //   {
        //   ...data,
        //   LANGUAGE_ID: idiomaSeleccionado.LANGUAGE_ID,
        // }
      );

      // setInitialValues({
      //   ID: data[0].ID,
      //   NOMBRE: data[0].NOMBRE,
      //   SUBTITULO: data[0].SUBTITULO,
      //   LANGUAGE_ID: data[0].LANGUAGE_ID,
      // });
    };
    if (id > 0) loadData();
  }, [id, idiomaSeleccionado]);

  const handleIdiomaChange = (event, newValue) => {
    // newValue es el objeto de la opción seleccionada
    setIdiomaSeleccionado(newValue); // Guarda el idioma seleccionado si necesitas usarlo en otro lugar
  };

  const CustomToastContent = ({ title, subtitle }) => (
    <div>
      <strong style={{ fontSize: "16px" }}>{title}</strong>
      {subtitle && <p style={{ fontSize: "14px" }}>{subtitle}</p>}
    </div>
  );
  CustomToastContent.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
  };
  CustomToastContent.defaultProps = {
    title: "¡Guardado correctamente!",
    subtitle: "",
  };

  const msgOk = (title, subtitle) =>
    toast.success(<CustomToastContent title={title} subtitle={subtitle} />, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });

  const msgError = (title, subtitle) =>
    toast.error(<CustomToastContent title={title} subtitle={subtitle} />, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });

  const FormikAutocomplete = ({ ...props }) => {
    const { setFieldValue } = useFormikContext();

    return (
      <Autocomplete
        disableClearable
        options={idiomas}
        value={idiomaSeleccionado}
        getOptionLabel={(option) => option.NOMBRE} // Asumiendo que cada idioma tiene una propiedad 'nombre'
        renderInput={(params) => <TextField {...params} label="Idioma" variant="standard" />}
        onChange={handleIdiomaChange} // Maneja el cambio de selección
      />
    );
  };
  const titleNav = id == 0 ? "registro" : "registro " + data.NOMBRE;

  // Esquema de validación
  const validationSchema = Yup.object().shape({
    NOMBRE: Yup.string().max(255).required("Nombre es obligatorio"),
    POS: Yup.number().required("Posición es obligatorio").positive().integer(),
    SUBTITULO: Yup.string().max(500),
    LANGUAGE_ID: Yup.number().required("Idioma es requerido"),
    // Incluye otras validaciones según corresponda
  });
  const handleSubmit = async (values, actions) => {
    try {
      var imgOK = await saveImgOnSubmit(values);
      if (!imgOK) return;
      const response = await saveData(values);
      if (response.ok) {
        const msgResponse = await response.json();
        // Si la solicitud fue exitosa
        if (msgResponse.error != "") {
          msgError(msgResponse.error);
        } else {
          msgOk();
          var id = msgResponse.response[0][""];
          navigate("/admin/equipo/" + id);
        }
      } else {
        // Si hubo un problema con la solicitud
        const errorData = await response.json(); // Suponiendo que el servidor responde con detalles del error en JSON
        msgError(`Error al guardar: ${errorData.message}`);
        // Aquí podrías establecer algún estado para mostrar el error en la UI en lugar de un alert
      }
    } catch (error) {
      msgError("Ocurrió un error al guardar los datos");
      console.error(error);
    } finally {
      actions.setSubmitting(false);
    }
  };
  /*CROPPPER*/
  const folderSave = "equipo";
  const [imageHasBeenCropped, setImageHasBeenCropped] = useState(false);
  const [showImageError, setShowImageError] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [file, setFile] = useState(null);
  const [rootFile, setRootFile] = useState(null);
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    multiple: false,
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      setRootFile(acceptedFiles[0]);
      setFile({
        ...acceptedFiles[0],
        preview: URL.createObjectURL(acceptedFiles[0]),
      });
    },
  });

  useEffect(() => {
    if (croppedImage) {
      setImageHasBeenCropped(true);
    } else {
      setImageHasBeenCropped(false);
    }
  }, [croppedImage]);

  async function saveImgOnSubmit(values) {
    var res = true;
    if (imageHasBeenCropped) {
      let image = values.IMAGEN;
      if (!image) {
        let fileToUpload = rootFile;
        if (croppedImage) {
          const res = await fetch(croppedImage);
          const blob = await res.blob();
          fileToUpload = new File([blob], rootFile.name, {
            type: rootFile.type,
          });
        }
        const data = new FormData();
        data.append("file", fileToUpload);
        const imageReq = await saveImgFolder(data, folderSave);
        const imageResult = imageReq.filename;
        // image = `${
        //   process.env.REACT_APP_API_BASE_URL
        // }/files/${folderSave}/${imageResult.path.split("\\").pop()}`.replaceAll("api/", "");
        var image_path =
          `${process.env.REACT_APP_API_BASE_URL}/files/${folderSave}/${imageResult}`.replaceAll(
            "api/",
            ""
          );
        image = imageResult;
      }
      setData({
        ...data,
        IMAGEN: image,
      });
      values.IMAGEN = image;
      setFile(null);
      setCroppedImage(null);
      // setSubmitForm(true);
    }
    if (!imageHasBeenCropped && !values.IMAGEN) {
      setShowImageError(true);
      res = false;
    }
    return res;
  }
  function getFullPathImg(img) {
    var image_path = `${process.env.REACT_APP_API_BASE_URL}/files/${folderSave}/${img}`.replaceAll(
      "api/",
      ""
    );
    return image_path;
  }
  /*//CROPPER*/
  return (
    <DashboardLayout>
      <ToastContainer />
      <DashboardNavbar title={`Edición de ${titleNav}`} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Edición del registro
                </MDTypography>
              </MDBox>
              <MDBox py={3} px={3}>
                <Formik
                  initialValues={data} // Usas directamente el estado data como initialValues
                  enableReinitialize // Esto asegura que los valores del formulario se actualicen cuando cambie data
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    submitForm,
                    isSubmitting,
                    touched,
                    values,
                    setFieldValue,
                  }) => (
                    <Form>
                      <MDBox pt={0} pb={3}>
                        <Grid container spacing={6}>
                          <Grid item xs={12}>
                            <Grid container spacing={6}>
                              <Grid item xs={6}>
                                <Grid container spacing={4}>
                                  <Grid item xs={2}>
                                    <MDInput
                                      name="POS"
                                      fullWidth
                                      label="Posición"
                                      type="number"
                                      variant="standard"
                                      value={values.POS}
                                      error={touched.NOMBRE && Boolean(errors.NOMBRE)}
                                      onChange={handleChange}
                                    />
                                    {touched.POS && errors.POS && (
                                      <FormHelperText error>{errors.POS}</FormHelperText>
                                    )}
                                  </Grid>
                                  <Grid item xs={10}>
                                    <MDInput
                                      variant="standard"
                                      fullWidth
                                      name="NOMBRE"
                                      label="Nombre"
                                      error={touched.NOMBRE && Boolean(errors.NOMBRE)}
                                      value={values.NOMBRE}
                                      onChange={handleChange}
                                    />
                                    {touched.NOMBRE && errors.NOMBRE && (
                                      <FormHelperText error>{errors.NOMBRE}</FormHelperText>
                                    )}
                                  </Grid>
                                  <Grid item xs={12}>
                                    <MDInput
                                      variant="standard"
                                      fullWidth
                                      name="SUBTITULO"
                                      label="Subtítulo"
                                      error={touched.SUBTITULO && Boolean(errors.SUBTITULO)}
                                      value={values.SUBTITULO}
                                      onChange={handleChange}
                                    />
                                    {touched.SUBTITULO && errors.SUBTITULO && (
                                      <FormHelperText error>{errors.SUBTITULO}</FormHelperText>
                                    )}
                                  </Grid>
                                  <Grid item xs={12}>
                                    <MDInput
                                      type="text"
                                      variant="standard"
                                      fullWidth
                                      name="DESC_CORTA"
                                      label="Descripción corta"
                                      error={touched.DESC_CORTA && Boolean(errors.DESC_CORTA)}
                                      value={values.DESC_CORTA}
                                      onChange={handleChange}
                                      multiline
                                      rows={5}
                                    />
                                    {touched.DESC_CORTA && errors.DESC_CORTA && (
                                      <FormHelperText error>{errors.DESC_CORTA}</FormHelperText>
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={6}>
                                <Grid container spacing={4}>
                                  <Grid item xs={3} textAlign={"start"}>
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          checked={values.ACTIVO}
                                          onChange={handleChange}
                                          name="ACTIVO"
                                        />
                                      }
                                      label="Activo"
                                    />
                                  </Grid>
                                  <Grid item xs={3} textAlign={"end"}>
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          checked={values.TIENE_DETALLE}
                                          onChange={handleChange}
                                          name="TIENE_DETALLE"
                                        />
                                      }
                                      label="Tiene detalle"
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <FormikAutocomplete
                                      name="LANGUAGE_ID"
                                      fullWidth
                                      options={idiomas}
                                      getOptionLabel={(option) => option.label}
                                      label="Idioma"
                                      onChange={handleChange}
                                      value={values.LANGUAGE_ID}
                                    />
                                  </Grid>
                                  {/* Uaaaaaaaaaa */}
                                  <Grid item xs={12}>
                                    <MDInput
                                      variant="standard"
                                      fullWidth
                                      name="UBICACION"
                                      label="Ubicación"
                                      error={touched.UBICACION && Boolean(errors.UBICACION)}
                                      value={values.UBICACION}
                                      onChange={handleChange}
                                    />
                                    {touched.UBICACION && errors.UBICACION && (
                                      <FormHelperText error>{errors.UBICACION}</FormHelperText>
                                    )}
                                  </Grid>

                                  <Grid item xs={12}>
                                    <MDInput
                                      variant="standard"
                                      fullWidth
                                      name="ESTUDIOS"
                                      label="Estudios"
                                      error={touched.ESTUDIOS && Boolean(errors.ESTUDIOS)}
                                      value={values.ESTUDIOS}
                                      onChange={handleChange}
                                    />
                                    {touched.ESTUDIOS && errors.ESTUDIOS && (
                                      <FormHelperText error>{errors.ESTUDIOS}</FormHelperText>
                                    )}
                                  </Grid>
                                  <Grid item xs={12}>
                                    <MDInput
                                      variant="standard"
                                      fullWidth
                                      name="CONOCIMIENTOS"
                                      label="Conocimientos"
                                      error={touched.CONOCIMIENTOS && Boolean(errors.CONOCIMIENTOS)}
                                      value={values.CONOCIMIENTOS}
                                      onChange={handleChange}
                                    />
                                    {touched.CONOCIMIENTOS && errors.CONOCIMIENTOS && (
                                      <FormHelperText error>{errors.CONOCIMIENTOS}</FormHelperText>
                                    )}
                                  </Grid>
                                  {/* sdasdsadasdasdsda */}
                                </Grid>
                              </Grid>

                              <Grid item xs={12}>
                                <MDInput
                                  type="text"
                                  variant="standard"
                                  fullWidth
                                  name="DESC_LARGA"
                                  label="Descripción larga"
                                  error={touched.DESC_LARGA && Boolean(errors.DESC_LARGA)}
                                  value={values.DESC_LARGA}
                                  onChange={handleChange}
                                  multiline
                                  rows={5}
                                />
                                {touched.DESC_LARGA && errors.DESC_LARGA && (
                                  <FormHelperText error>{errors.DESC_LARGA}</FormHelperText>
                                )}
                              </Grid>

                              <Grid item xs={12} pb={0}>
                                <Box
                                  sx={{
                                    borderBottom: 2,
                                    borderColor: "black",
                                    width: "100%",
                                  }}
                                ></Box>
                              </Grid>

                              <Grid
                                item
                                xs={6}
                                sx={{
                                  paddingTop: "20px!important",
                                  borderRight: "1px solid #ccc",
                                  paddingRight: "30px",
                                }}
                              >
                                <Grid item xs={12} pb={3}>
                                  <MDTypography variant="h7">Panel SEO</MDTypography>
                                </Grid>
                                <Grid container spacing={4}>
                                  <Grid item xs={12}>
                                    <MDInput
                                      variant="standard"
                                      fullWidth
                                      name="SEO_TITLE"
                                      label="Título"
                                      error={touched.SEO_TITLE && Boolean(errors.SEO_TITLE)}
                                      value={values.SEO_TITLE}
                                      onChange={handleChange}
                                    />
                                    {touched.SEO_TITLE && errors.SEO_TITLE && (
                                      <FormHelperText error>{errors.SEO_TITLE}</FormHelperText>
                                    )}
                                  </Grid>
                                  <Grid item xs={12}>
                                    <MDInput
                                      variant="standard"
                                      fullWidth
                                      name="SEO_URL_FRIENDLY"
                                      label="Url amigable"
                                      error={
                                        touched.SEO_URL_FRIENDLY && Boolean(errors.SEO_URL_FRIENDLY)
                                      }
                                      value={values.SEO_URL_FRIENDLY}
                                      onChange={handleChange}
                                    />
                                    {touched.SEO_URL_FRIENDLY && errors.SEO_URL_FRIENDLY && (
                                      <FormHelperText error>
                                        {errors.SEO_URL_FRIENDLY}
                                      </FormHelperText>
                                    )}
                                  </Grid>
                                  <Grid item xs={12}>
                                    <MDInput
                                      variant="standard"
                                      fullWidth
                                      name="SEO_METAKEYWORD"
                                      label="MetaKeywords"
                                      error={
                                        touched.SEO_METAKEYWORD && Boolean(errors.SEO_METAKEYWORD)
                                      }
                                      value={values.SEO_METAKEYWORD + ""}
                                      onChange={handleChange}
                                    />
                                    {touched.SEO_METAKEYWORD && errors.SEO_METAKEYWORD && (
                                      <FormHelperText error>
                                        {errors.SEO_METAKEYWORD}
                                      </FormHelperText>
                                    )}
                                  </Grid>
                                  <Grid item xs={12}>
                                    <MDInput
                                      variant="standard"
                                      fullWidth
                                      name="SEO_METADESCRIPTION"
                                      label="Metadescripción"
                                      error={
                                        touched.SEO_METADESCRIPTION &&
                                        Boolean(errors.SEO_METADESCRIPTION)
                                      }
                                      value={values.SEO_METADESCRIPTION}
                                      onChange={handleChange}
                                    />
                                    {touched.SEO_METADESCRIPTION && errors.SEO_METADESCRIPTION && (
                                      <FormHelperText error>
                                        {errors.SEO_METADESCRIPTION}
                                      </FormHelperText>
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                xs={6}
                                sx={{
                                  paddingTop: "20px!important",
                                  paddingLeft: "30px!important",
                                }}
                              >
                                <Grid container>
                                  <Grid item xs={11} pb={3} mb={3}>
                                    <MDTypography variant="h7">Imagen</MDTypography>
                                  </Grid>

                                  {file || values.IMAGEN ? (
                                    <Grid item xs={1}>
                                      <IconButton
                                        onClick={() => {
                                          setData({
                                            ...values,
                                            IMAGEN: "",
                                          });
                                          setFile(null);
                                          setCroppedImage(null);
                                          setImageHasBeenCropped(false);
                                        }}
                                      >
                                        <Close />
                                      </IconButton>
                                    </Grid>
                                  ) : (
                                    <Grid item xs={1} />
                                  )}
                                </Grid>
                                <Grid container spacing={4}>
                                  {/* IMG */}
                                  <>
                                    {values.IMAGEN !== "" && (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          backgroundColor: "#eee",
                                          padding: "2rem",
                                          width: "100%",
                                          height: "100%",
                                          minHeight: "300px",
                                        }}
                                      >
                                        <img
                                          style={{
                                            width: "auto",
                                            height: "100%",
                                            maxHeight: "300px",
                                            maxWidth: "100%;",
                                          }}
                                          src={getFullPathImg(values.IMAGEN)}
                                          alt="service"
                                        />
                                      </Box>
                                    )}
                                    {values.IMAGEN === "" && (
                                      <Grid container>
                                        {file && rootFile ? (
                                          <Grid
                                            item
                                            xs={12}
                                            sx={{
                                              ml: 0,
                                              mr: 0,
                                              minHeight: "350px",
                                              width: "100%",
                                              border: `1px dashed black`,
                                              borderRadius: "0px",
                                              textAlign: "center",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <Grid container>
                                              <Grid item xs={6}>
                                                <MDTypography
                                                  sx={{
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  Imagen Original
                                                </MDTypography>
                                                <Box style={{ maxWidth: "90%", padding: "1rem" }}>
                                                  <Cropper
                                                    imageToCrop={URL.createObjectURL(rootFile)}
                                                    croppedImage={(value) => setCroppedImage(value)}
                                                    ratio={1}
                                                  />
                                                </Box>
                                              </Grid>
                                              <Grid item xs={6}>
                                                <MDTypography
                                                  sx={{
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  Vista Previa
                                                </MDTypography>

                                                {croppedImage ? (
                                                  <img
                                                    src={croppedImage}
                                                    alt="preview"
                                                    style={{ maxWidth: "90%", padding: "1rem" }}
                                                  />
                                                ) : (
                                                  <img
                                                    src={file.preview}
                                                    alt="preview"
                                                    style={{ maxWidth: "90%", padding: "1rem" }}
                                                  />
                                                )}
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        ) : (
                                          <Grid
                                            item
                                            id="imageBAnnercontainer"
                                            xs={12}
                                            sx={{
                                              ml: "30px",
                                              mr: 0,
                                              minHeight: "250px",
                                              width: "100%",
                                              border: `1px dashed black`,
                                              borderRadius: "0px",
                                              padding: "2rem",
                                            }}
                                          >
                                            <UploadImageBanner rootProps={getRootProps} />
                                            <input
                                              accept=".jpg, .jpeg, .png, .gif, .svg, .pdf"
                                              {...getInputProps()}
                                            />
                                          </Grid>
                                        )}
                                      </Grid>
                                    )}
                                    {showImageError && (
                                      <MDTypography fontSize={14} color="error" px={4} pt={1}>
                                        Debe subir y recortar una imagen
                                      </MDTypography>
                                    )}
                                  </>
                                  {/* //IMG */}
                                </Grid>
                              </Grid>

                              <Grid item xs={12}>
                                <MDBox display="flex" justifyContent="flex-end">
                                  <MDButton
                                    type="submit"
                                    variant="gradient"
                                    color="primary"
                                    size="medium"
                                  >
                                    GUARDAR DATOS
                                  </MDButton>
                                </MDBox>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </MDBox>
                    </Form>
                  )}
                </Formik>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Edit;
