/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import SEO from "layouts/seo";
import TEST from "layouts/seo/test";
import EditSEO from "layouts/seo/edit";
import Users from "layouts/users";
import EditUser from "layouts/users/edit";
import EditSMTP from "layouts/SMTP";
import PaginasLegales from "layouts/paginasLegales";
import EditPaginasLegales from "layouts/paginasLegales/edit";
import Traducciones from "layouts/traducciones";
import EditTraduccion from "layouts/traducciones/edit";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import RecoverPass from "layouts/authentication/recover-pass";
import ServiciosList from "layouts/servicios";
import CreateServicios from "layouts/servicios/create";
import EditServicios from "layouts/servicios/edit";
import Equipo from "layouts/equipo";
import EditEquipo from "layouts/equipo/edit";
import Noticias from "layouts/noticias";
import EditNoticias from "layouts/noticias/edit";
import TagsNoticias from "layouts/tags_noticias";
import EditTagsNoticias from "layouts/tags_noticias/edit";
import SiteMap from "layouts/sitemap";

// Front pages
import Home from "layouts/frontpage/home/index";
import NoticiasPage from "layouts/frontpage/noticias/index";
import NoticiasDetailPage from "layouts/frontpage/noticias/detail";
import ContactosPage from "layouts/frontpage/contactos";
import SuccessContactPage from "layouts/frontpage/contactos/success";
import EmpresaPage from "layouts/frontpage/empresa";
import EquipoPage from "layouts/frontpage/empresa/detail";
import ServiciosPage from "layouts/frontpage/servicios/index";
import ServiciosDetailPage from "layouts/frontpage/servicios/detail";
import Compilance from "layouts/frontpage/legalPages/compilance";
import Cookies from "layouts/frontpage/legalPages/cookies";
import Privacidad from "layouts/frontpage/legalPages/privacidad";
import AvisoLegal from "layouts/frontpage/legalPages/avisoLegal";
// import Cookies from "layouts/frontpage/legalPages/cookies";

// import { useEffect, useState } from "react";
// import routesData from "./layouts/seo/data/routesData";

// @mui icons
import Icon from "@mui/material/Icon";
// const { rows } = routesData();

// const api_key = localStorage.getItem("access_token") || "";
// useEffect(() => {
//   const fetchData = async () => {
//     const result = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/seo`, {
//       method: "GET",
//       headers: {
//         authToken: api_key,
//       },
//     });
//   };
// }, []);

const routes = [
  {
    type: "collapse",
    name: "Home",
    key: "home",
    icon: "",
    route: "/",
    component: <Home />,
  },
  {
    type: "collapse",
    name: "Noticias",
    key: "noticias",
    icon: "",
    route: "/noticias",
    component: <NoticiasPage />,
    detailComponent: <NoticiasDetailPage />,
  },
  {
    type: "collapse",
    name: "Servicios",
    key: "servicios",
    icon: "",
    route: "/servicios",
    component: <ServiciosPage />,
    detailComponent: <ServiciosDetailPage />,
  },
  {
    type: "collapse",
    name: "Contactos",
    key: "contacto",
    icon: "",
    route: "/contacto",
    component: <ContactosPage />,
    detailComponent: <SuccessContactPage />,
  },
  {
    type: "collapse",
    name: "Empresa",
    key: "empresa",
    icon: "",
    route: "/empresa",
    component: <EmpresaPage />,
    detailComponent: <EquipoPage />,
  },
  {
    type: "collapse",
    name: "Compilance",
    key: "Compilance",
    icon: "",
    route: "/compilance",
    component: <Compilance />,
  },
  {
    type: "collapse",
    name: "Cookies",
    key: "Cookies",
    icon: "",
    route: "/cookies",
    component: <Cookies />,
  },
  {
    type: "collapse",
    name: "Privacidad",
    key: "Privacidad",
    icon: "",
    route: "/privacidad",
    component: <Privacidad />,
  },
  {
    type: "collapse",
    name: "AvisoLegal",
    key: "aviso-legal",
    icon: "",
    route: "/aviso-legal",
    component: <AvisoLegal />,
  },

  {
    type: "collapse",
    name: "Noticias",
    key: "ListNoticias",
    icon: <Icon fontSize="small">newspaper</Icon>,
    route: "admin/noticias",
    component: <Noticias />,
  },
  {
    type: "collapse",
    name: "Noticias",
    key: "EditNoticia",
    icon: "",
    route: "admin/noticias/:id",
    component: <EditNoticias />,
  },
  {
    type: "collapse",
    name: "Tags Noticias",
    key: "ListTagsNoticias",
    icon: <Icon fontSize="small">newspaper</Icon>,
    route: "admin/tags_noticias",
    component: <TagsNoticias />,
  },
  {
    type: "collapse",
    name: "Tags Noticias",
    key: "EditNoticia",
    icon: "",
    route: "admin/tags_noticias/:id",
    component: <EditTagsNoticias />,
  },
  {
    type: "collapse",
    name: "SiteMap",
    key: "sitemap",
    icon: <Icon fontSize="small">account_tree</Icon>,
    route: "admin/sitemap",
    component: <SiteMap />,
  },
  {
    type: "collapse",
    name: "Usuarios",
    key: "users",
    icon: <Icon fontSize="small">people</Icon>,
    route: "admin/Users",
    component: <Users />,
  },
  {
    type: "collapse",
    name: "EditUser",
    key: "EditUser",
    icon: "",
    route: "admin/Users/:id",
    component: <EditUser />,
  },
  {
    type: "collapse",
    name: "Servicios",
    key: "ListServicios",
    icon: <Icon fontSize="small">design_services</Icon>,
    route: "admin/Servicios",
    component: <ServiciosList />,
  },
  {
    type: "collapse",
    name: "CreateServicios",
    key: "CreateServicios",
    icon: "",
    route: "admin/Servicios/create",
    component: <CreateServicios />,
  },
  {
    type: "collapse",
    name: "CreateServicios",
    key: "CreateServicios",
    icon: "",
    route: "admin/Servicios/:id",
    component: <EditServicios />,
  },
  {
    type: "collapse",
    name: "Equipo",
    key: "ListEquipo",
    icon: <Icon fontSize="small">diversity_3</Icon>,
    route: "admin/Equipo",
    component: <Equipo />,
  },
  {
    type: "collapse",
    name: "EditUser",
    key: "EditUser",
    icon: "",
    route: "admin/Equipo/:id",
    component: <EditEquipo />,
  },
  {
    type: "collapse",
    name: "SEO",
    key: "SEO",
    icon: <Icon fontSize="small">control_camera</Icon>,
    route: "admin/SEO",
    component: <SEO />,
  },
  {
    type: "collapse",
    name: "EditSEO",
    key: "Editseo",
    icon: "",
    route: "admin/SEO/:id",
    component: <EditSEO />,
  },
  {
    type: "collapse",
    name: "Diccionario",
    key: "Diccionario",
    icon: <Icon fontSize="small">g_translate</Icon>,
    route: "admin/Traducciones",
    component: <Traducciones />,
  },
  {
    type: "collapse",
    name: "Diccionario",
    key: "Diccionario",
    icon: "",
    route: "admin/Traducciones/:id",
    component: <EditTraduccion />,
  },

  {
    type: "collapse",
    name: "Conf. email",
    key: "SMTP",
    icon: <Icon fontSize="small">email</Icon>,
    route: "admin/SMTP",
    component: <EditSMTP />,
  },
  {
    type: "collapse",
    name: "Paginas legales",
    key: "PaginasLegales",
    icon: <Icon fontSize="small">toc</Icon>,
    route: "admin/PaginasLegales",
    component: <PaginasLegales />,
  },
  {
    type: "collapse",
    name: "EditPaginasLegales",
    key: "EditPaginasLegales",
    icon: "",
    route: "admin/PaginasLegales/:id",
    component: <EditPaginasLegales />,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: "",
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: "",
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
  {
    type: "collapse",
    name: "Recuperar contraseña",
    key: "recover-pass",
    icon: "",
    route: "/authentication/recover-pass/:id",
    component: <RecoverPass />,
  },
  {
    type: "logout",
    name: "Logout",
    key: "logout",
    icon: <Icon fontSize="small">logout</Icon>,
  },
];

export default routes;
