/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton"; // Si decides usar IconButton
import AddIcon from "@mui/icons-material/Add"; // Importa el icono de añadir
import { Link } from "react-router-dom";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import DataTable from "examples/Tables/DataTable_Edit";
// import Chip from "@mui/material/Chip";
import CustomDataGridToolBar from "components/shared/CustomDataGridToolBar";
import CustomDataGridLocale from "components/shared/CustomDataGridLocale";

// Data
import gridData from "layouts/noticias/data/Data";
import { DataGrid } from "@mui/x-data-grid";
import { esES } from "@mui/x-data-grid/locales";

const myLocaleText = {
  footerRowPerPageLabel: "Filas por página:", // Cambia 'Rows per page' a 'Filas por página'
};

function Tables() {
  const {
    columns,
    rows,
    filteredRows,
    searchValue,
    onSearchValueChange,
    filterModel,
    columnVisibilityModel,
    setFilteredRows,
    setFilterModel,
  } = gridData();
  const folderImg = "noticias";
  // const onSearchValueChange = (event) => {
  //   setSearchValue(event.target.value);
  // };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container>
                  <Grid item xs={10}>
                    <MDTypography variant="h6" color="white">
                      Resultados ({filteredRows.length})
                    </MDTypography>
                  </Grid>
                  <Grid item xs={2} textAlign={"right"}>
                    <a
                      href="/admin/noticias/0"
                      title="Añadir"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <IconButton color="inherit" size="large" component="span">
                        <AddIcon />
                      </IconButton>
                    </a>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <DataGrid
                  rows={filteredRows}
                  columns={columns}
                  localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 15 },
                    },
                  }}
                  slots={{ toolbar: CustomDataGridToolBar }}
                  slotProps={{ toolbar: { searchValue, onSearchValueChange } }}
                  filterModel={filterModel}
                  onFilterModelChange={(newModel) => setFilterModel(newModel)}
                  columnVisibilityModel={columnVisibilityModel}
                  sx={{ height: "Calc(100vh - 300px)", minHeight: 400 }}
                  pageSizeOptions={[5, 10, 15]}
                  checkboxSelection={false}
                  disableDensitySelector
                  disableColumnMenu
                  disableColumnSelector
                  disableRowSelectionOnClick
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
