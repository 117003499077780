import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useFormik, Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useFormikContext } from "formik";
import {
  TextField,
  Button,
  FormHelperText,
  Switch,
  FormControlLabel,
  Divider,
} from "@mui/material";
import MDInput from "components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
// Eliminado import { Autocomplete as FormikAutocomplete } from "formik-mui-lab";
import PropTypes from "prop-types";
import { Grid, Card, Box } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getLanguages, fetchData, saveData } from "./Data";
import colors from "assets/theme/base/colors";

function Edit() {
  const [idiomas, setIdiomas] = useState([]);
  const [idiomaSeleccionado, setIdiomaSeleccionado] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({
    ID: 0,
    LANGUAGE_ID: 1,
    ACTIVO: true,
    NOMBRE: "",
  });

  useEffect(() => {
    const loadLanguages = async () => {
      const langs = await getLanguages();
      setIdiomas(langs);
      const idiomaPorDefecto = langs.find((idioma) => idioma.ID == 1);
      // Evita establecer el idioma por defecto aquí si ya tienes uno seleccionado
      if (idiomaPorDefecto && !idiomaSeleccionado) {
        setIdiomaSeleccionado(idiomaPorDefecto);
      }
    };
    loadLanguages();
  }, []);
  useEffect(() => {
    // Carga los datos iniciales
    const loadData = async () => {
      if (!idiomaSeleccionado) return;
      const data = await fetchData(id, idiomaSeleccionado.ID); // Asumiendo que fetchData es una función que obtiene los datos del registro por su ID
      setData(data[0]);
    };
    if (id > 0) loadData();
  }, [id, idiomaSeleccionado]);

  const handleIdiomaChange = (event, newValue) => {
    // newValue es el objeto de la opción seleccionada
    setIdiomaSeleccionado(newValue); // Guarda el idioma seleccionado si necesitas usarlo en otro lugar
  };

  const CustomToastContent = ({ title, subtitle }) => (
    <div>
      <strong style={{ fontSize: "16px" }}>{title}</strong>
      {subtitle && <p style={{ fontSize: "14px" }}>{subtitle}</p>}
    </div>
  );
  CustomToastContent.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
  };
  CustomToastContent.defaultProps = {
    title: "¡Guardado correctamente!",
    subtitle: "",
  };

  const msgOk = (title, subtitle) =>
    toast.success(<CustomToastContent title={title} subtitle={subtitle} />, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });

  const msgError = (title, subtitle) =>
    toast.error(<CustomToastContent title={title} subtitle={subtitle} />, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });

  const FormikAutocomplete = ({ ...props }) => {
    const { setFieldValue } = useFormikContext();

    return (
      <Autocomplete
        disableClearable
        options={idiomas}
        value={idiomaSeleccionado}
        getOptionLabel={(option) => option.NOMBRE} // Asumiendo que cada idioma tiene una propiedad 'nombre'
        renderInput={(params) => <TextField {...params} label="Idioma" variant="standard" />}
        onChange={handleIdiomaChange} // Maneja el cambio de selección
      />
    );
  };
  const titleNav = id == 0 ? "registro" : "registro " + data.NOMBRE;

  // Esquema de validación
  const validationSchema = Yup.object().shape({
    NOMBRE: Yup.string().max(255).required("Nombre es obligatorio"),
    LANGUAGE_ID: Yup.number().required("Idioma es requerido"),
  });
  const handleSubmit = async (values, actions) => {
    try {
      const response = await saveData(values);
      if (response.ok) {
        const msgResponse = await response.json();
        // Si la solicitud fue exitosa
        if (msgResponse.error != "") {
          msgError(msgResponse.error);
        } else {
          msgOk();
          var id = msgResponse.response[0][""];
          navigate("/admin/tags_noticias/" + id);
        }
      } else {
        // Si hubo un problema con la solicitud
        const errorData = await response.json(); // Suponiendo que el servidor responde con detalles del error en JSON
        msgError(`Error al guardar: ${errorData.message}`);
        // Aquí podrías establecer algún estado para mostrar el error en la UI en lugar de un alert
      }
    } catch (error) {
      msgError("Ocurrió un error al guardar los datos");
      console.error(error);
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <DashboardLayout>
      <ToastContainer />
      <DashboardNavbar title={`Edición de ${titleNav}`} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Edición del registro
                </MDTypography>
              </MDBox>
              <MDBox py={3} px={3}>
                <Formik
                  initialValues={data} // Usas directamente el estado data como initialValues
                  enableReinitialize // Esto asegura que los valores del formulario se actualicen cuando cambie data
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    submitForm,
                    isSubmitting,
                    touched,
                    values,
                    setFieldValue,
                  }) => (
                    <Form>
                      <MDBox pt={0} pb={3}>
                        <Grid container spacing={6}>
                          <Grid item xs={12}>
                            <Grid container spacing={6}>
                              <Grid item xs={6}>
                                <Grid container spacing={4}>
                                  <Grid item xs={12}>
                                    <MDInput
                                      variant="standard"
                                      fullWidth
                                      name="NOMBRE"
                                      label="Nombre"
                                      error={touched.NOMBRE && Boolean(errors.NOMBRE)}
                                      value={values.NOMBRE}
                                      onChange={handleChange}
                                    />
                                    {touched.NOMBRE && errors.NOMBRE && (
                                      <FormHelperText error>{errors.NOMBRE}</FormHelperText>
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={6}>
                                <Grid container spacing={4}>
                                  <Grid item xs={6} textAlign={"start"}>
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          checked={values.ACTIVO}
                                          onChange={handleChange}
                                          name="ACTIVO"
                                        />
                                      }
                                      label="Activo"
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <FormikAutocomplete
                                      name="LANGUAGE_ID"
                                      fullWidth
                                      options={idiomas}
                                      getOptionLabel={(option) => option.label}
                                      label="Idioma"
                                      onChange={handleChange}
                                      value={values.LANGUAGE_ID}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid item xs={12}>
                                <MDBox display="flex" justifyContent="flex-end">
                                  <MDButton
                                    type="submit"
                                    variant="gradient"
                                    color="primary"
                                    size="medium"
                                  >
                                    GUARDAR DATOS
                                  </MDButton>
                                </MDBox>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </MDBox>
                    </Form>
                  )}
                </Formik>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Edit;
