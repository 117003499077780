// Asume que la URL base de tu API podría cambiar o ser reutilizada, por lo que podría ser buena idea definirla como constante.
export const BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/traducciones`;

/**
 * Obtiene los datos de LegalPages para un ID específico.
 * @param {string} id - El ID del registro LegalPages que se desea obtener.
 * @returns {Promise<any>} - Los datos de LegalPages obtenidos.
 */
export const fetchitem = async (id, lang) => {
  if (!lang) lang = 1;
  try {
    const response = await fetch(`${BASE_URL}/${id}?lang=${lang}`, {
      method: "GET",
      headers: {
        authToken: api_key,
      },
    });
    if (!response.ok) {
      throw new Error("Error al obtener los datos de LegalPages");
    }
    return await response.json();
  } catch (error) {
    console.error("Error al obtener datos de LegalPages:", error);
    throw error; // Propagar el error para manejarlo en el componente.
  }
};

/**
 * Envía los datos modificados de LegalPages al servidor.
 * @param {Object} itemEdit - Los datos modificados de LegalPages a guardar.
 * @returns {Promise<void>}
 */
const api_key = localStorage.getItem("access_token") || "";
export const saveData = async (itemEdit) => {
  itemEdit.TRADUCCION = itemEdit.TRADUCCION.replace("</q2b>", "").replace("<q2b>", "");
  var response = null;
  try {
    response = await fetch(`${BASE_URL}`, {
      method: "POST", // Cambiar a "PUT" si tu API usa PUT para actualizaciones.
      headers: {
        "Content-Type": "application/json",
        authToken: api_key,
      },
      body: JSON.stringify({
        ...itemEdit,
      }),
    });

    // Opcional: Procesar la respuesta si es necesario.
  } catch (error) {
    throw error; // Propagar el error para manejarlo en el componente.
  }
  return response;
};
export const getLanguages = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/traducciones/getIdiomas`, {
      method: "GET",
      headers: {
        authToken: api_key,
      },
    });
    if (!response.ok) {
      throw new Error("Error al obtener los datos de LegalPages");
    }
    return await response.json();
  } catch (error) {
    console.error("Error al obtener datos de LegalPages:", error);
    throw error; // Propagar el error para manejarlo en el componente.
  }
};
