/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton"; // Si decides usar IconButton
import AddIcon from "@mui/icons-material/Add"; // Importa el icono de añadir

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable_Edit";

// Data
import CustomDataGridToolBar from "components/shared/CustomDataGridToolBar";
import gridData from "layouts/traducciones/data/Data";
import { DataGrid } from "@mui/x-data-grid";
import { esES } from "@mui/x-data-grid/locales";

function Tables() {
  const {
    columns,
    rows,
    filteredRows,
    searchValue,
    onSearchValueChange,
    filterModel,
    columnVisibilityModel,
    setFilteredRows,
    setFilterModel,
  } = gridData();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container>
                  <Grid item xs={10}>
                    <MDTypography variant="h6" color="white">
                      Resultados ({filteredRows.length})
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <DataGrid
                  rows={filteredRows}
                  columns={columns}
                  localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 15 },
                    },
                  }}
                  slots={{ toolbar: CustomDataGridToolBar }}
                  slotProps={{ toolbar: { searchValue, onSearchValueChange } }}
                  filterModel={filterModel}
                  onFilterModelChange={(newModel) => setFilterModel(newModel)}
                  columnVisibilityModel={columnVisibilityModel}
                  sx={{ height: "Calc(100vh - 300px)", minHeight: 400 }}
                  pageSizeOptions={[5, 10, 15]}
                  checkboxSelection={false}
                  disableDensitySelector
                  disableColumnMenu
                  disableColumnSelector
                  disableRowSelectionOnClick
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
