import React, { useState, useContext } from "react";
import { Grid, Box, Typography, Divider, Button, Container } from "@mui/material";
import HeaderFrontOffice from "../../../components/FrontOfficeHeaders/HeaderFrontOffice";
import { FrontOfficeFooter } from "../../../components/FrontOfficeFooter";
import { TraduccionesContext } from "components/shared/TraduccionesContext";
import { useNavigate } from "react-router-dom";

function SuccessContactPage() {
  const navigate = useNavigate();
  const { traducciones } = useContext(TraduccionesContext);
  return (
    <>
      <Box>
        <HeaderFrontOffice />
      </Box>
      <Box
        sx={{
          background: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "85px",
          paddingTop: "50px",
          "& *": { fontFamily: "Nunito !important" },
          minHeight: "calc(100vh - 400px)",
        }}
      >
        <Container>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              fontSize: "40px",
              lineHeight: "56px",
              letterSpacing: "0.48px",
              fontWeight: "bold",
              color: "#222222",
            }}
          >
            {traducciones && traducciones.CONTACTO.success_title}
          </Box>
          <Box
            sx={{
              marginBottom: "81px",
              display: "flex !important",
              justifyContent: "center",
              marginTop: "2rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center!important",
              ".ql-size-large": {
                fontSize: "24px",
                lineHeight: "30px",
                margin: "auto",
                width: "100%",
                maxWidth: "546px",
                marginBottom: "54px",
                marginTop: "14px",
              },
              ".ql-align-center": {
                textAlign: "center",
              },
            }}
          >
            {traducciones && (
              <div
                style={{
                  textAlign: "center",
                  color: "#6C6B70",
                  font: "normal normal normal 16px / 24px Nunito",
                  width: "546px",
                }}
                dangerouslySetInnerHTML={{ __html: traducciones.CONTACTO.success_content }}
              />
            )}
            <Button
              sx={{
                marginTop: "64px",
                background: "#FFF !important",
                borderRadius: "80px !important",
                color: "white!important",
                fontSize: "13px",
                height: "32px",
                width: "226px",
                textTransform: "none",
                fontWeight: "bold",
                background:
                  "transparent linear-gradient(352deg, #A24B5D 0%, #4B5DA2 100%) 0% 0% no-repeat padding-box",
              }}
              onClick={() => navigate("/")}
            >
              {traducciones && traducciones.CONTACTO.contacto_button_text}
            </Button>
          </Box>
        </Container>
      </Box>
      <FrontOfficeFooter />
    </>
  );
}
export default SuccessContactPage;
