import PropTypes from "prop-types";
import { useNavigate, Link } from "react-router-dom";
import { Card, CardMedia, CardContent, Typography, Box, Button, Chip } from "@mui/material";

export const ServiceCardSmall = ({ title, url, seoURLFriendly }) => {
  const navigate = useNavigate();
  return seoURLFriendly.trim() !== "" ? (
    <Link to={"/" + url}>
      <Box
        sx={{
          width: "275px",
          height: "90px",
          borderRadius: "0",
          boxShadow: "none",
          cursor: "pointer",
          padding: 0,
          background:
            "transparent linear-gradient(355deg, rgba(162, 75, 93, 1) 0%, rgba(75, 93, 162, 1) 100%) 0% 0% no-repeat padding-box",
          "& *": { fontFamily: "Nunito !important", color: "#FFF" },
        }}
      >
        <Box
          sx={{
            padding: "30px !important",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            textAlign: "left",
          }}
        >
          <Typography
            sx={{ fontSize: "21px", fontWeight: "bold", lineHeight: "1" }}
            component={"h3"}
          >
            {title}
          </Typography>
        </Box>
      </Box>
    </Link>
  ) : (
    <Box
      sx={{
        width: "275px",
        height: "90px",
        borderRadius: "0",
        boxShadow: "none",
        cursor: "pointer",
        padding: 0,
        background:
          "transparent linear-gradient(355deg, rgba(162, 75, 93, 1) 0%, rgba(75, 93, 162, 1) 100%) 0% 0% no-repeat padding-box",
        "& *": { fontFamily: "Nunito !important", color: "#FFF" },
      }}
    >
      <Box
        sx={{
          padding: "30px !important",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          textAlign: "left",
        }}
      >
        <Typography sx={{ fontSize: "21px", fontWeight: "bold", lineHeight: "1" }} component={"h3"}>
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

ServiceCardSmall.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  seoURLFriendly: PropTypes.string.isRequired,
};
