import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useNavigate, Link } from "react-router-dom";
import { Card, CardMedia, CardContent, Typography, Box, Button, Chip } from "@mui/material";
import { TraduccionesContext } from "components/shared/TraduccionesContext";

export const NewsCard = ({ title, image, content, url, tags, handleClickTag }) => {
  //si url empieza por "/" eliminar el primer caracter
  url = url.startsWith("/") ? url.substring(1) : url;

  const navigate = useNavigate();
  const { traducciones } = useContext(TraduccionesContext);
  return (
    <Card sx={{ width: "266px", height: "auto", "& *": { fontFamily: "Nunito !important" } }}>
      <CardMedia
        component="img"
        image={image}
        sx={{ margin: "0 !important", borderRadius: "8px 8px 0 0 !important", height: "145px" }}
      />
      <CardContent sx={{ textAlign: "left", paddingX: "1rem", paddingTop: "0" }}>
        {tags.map((tag) => (
          <Chip
            key={tag}
            label={`${tag}`}
            sx={{
              marginRight: "1rem",
              height: "24px",
              fontSize: "11px",
              color: "#AAAAAA",
              marginBottom: "12px",
              marginTop: "23px",
              cursor: "pointer",
            }}
            onClick={() => handleClickTag(tag)}
          />
        ))}
        <Typography
          gutterBottom
          sx={{ fontSize: "19px", color: "#222222", lineHeight: "23px", fontWeight: "bold" }}
          minHeight={"85px"}
        >
          {title}
        </Typography>
        <Typography
          minHeight={"60px"}
          gutterBottom
          sx={{ fontSize: "12px", lineHeight: "17px", color: "#222222" }}
        >
          {content}
        </Typography>
        <Box>
          <Link to={"/" + url}>
            <Button
              sx={{
                background:
                  "transparent linear-gradient(352deg, #A24B5D 0%, #4B5DA2 100%) 0% 0% no-repeat padding-box",
                borderRadius: "80px !important",
                color: "#FFFFFF !important",
                fontSize: "13px",
                height: "32px",
                width: "226px",
                textTransform: "none",
                marginTop: "21px",
              }}
            >
              {traducciones && traducciones.NOTICIAS.card_button_text}
            </Button>
          </Link>
        </Box>
      </CardContent>
    </Card>
  );
};

NewsCard.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  handleClickTag: PropTypes.func,
};
