import React, { useState, useContext, useEffect } from "react";
import { Grid, Box, Typography, Divider, Button, Container } from "@mui/material";
import Cookies from "js-cookie";
import { useNavigate, Link, useLocation } from "react-router-dom";
import HomeHeaderBG from "../../../assets/images/HomeHeaderBG.png";
import Pilares from "../../../assets/images/Pilares.png";
import Experiencia from "../../../assets/images/Experiencia.png";
import Contadores from "../../../assets/images/Contadores.png";
import Premio from "../../../assets/images/Premio.png";
import ALTHLogo from "../../../assets/images/ALTHLogo.svg";
import { NewsCard } from "../../../components/NewsCard";
import HeaderHome from "../../../components/FrontOfficeHeaders/HeaderHome";
import { FrontOfficeFooter } from "../../../components/FrontOfficeFooter";
import SeoRoutesContext from "../../../components/shared/SeoRoutesContext";
import { TraduccionesContext } from "components/shared/TraduccionesContext";
import { getImageUrl } from "../../../shared/getImageUrl";
import dayjs from "dayjs";
import { Helmet } from "react-helmet";

function HomePage() {
  const { pathname } = useLocation();
  const seoRoutes = useContext(SeoRoutesContext);
  const { traducciones, loading, setLoading, idioma, idiomas } = useContext(TraduccionesContext);
  const navigate = useNavigate();
  const navigateTo = (routeKey, seoRoutes, lang) => {
    let path = "";
    const seoEntry = seoRoutes.find(
      (seo) =>
        seo.PAGE.replaceAll(".aspx", "").toLowerCase() === routeKey.toLowerCase() &&
        seo.ACRONIMO_ISO === lang
    );
    if (seoEntry) {
      path = `/${seoEntry.ACRONIMO_ISO}/${seoEntry.SEO_URL_FRIENDLY}`;
    } else {
      path = routeKey;
    }
    path = path.replace("//", "/");
    return path;
  };

  const handleNavigation = (key) => {
    const pathSegments = location.pathname.split("/").filter(Boolean);
    const lang = pathSegments[0] || "es"; // Asumir "es" como idioma por defecto si no se encuentra ninguno
    Cookies.set("lang", lang, { expires: 7 });
    const path = navigateTo(key, seoRoutes, lang);
    return path; // Not typically needed unless you have other uses for path after navigation
  };
  const [news, setNews] = useState([]);

  useEffect(() => {
    const cargarNoticias = async () => {
      setLoading(true);
      try {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/noticias?lang=${idioma}`, {
          method: "GET",
        })
          .then((response) => response.json())
          .then((data) => {
            setLoading(false);
            setNews(
              data
                .filter((item) => item.ACTIVO)
                .sort((a, b) => {
                  const dateA = dayjs(a.FECHA_PUBLICACION);
                  const dateB = dayjs(b.FECHA_PUBLICACION);
                  if (dateA.isBefore(dateB)) {
                    return -1;
                  }
                  if (dateB.isBefore(dateA)) {
                    return 1;
                  }
                  return 0;
                })
                .slice(0, 3)
                .map((item) => ({
                  title: item.TITULO,
                  content:
                    item.DESC_CORTA.length > 100
                      ? item.DESC_CORTA.slice(0, 100) + "..."
                      : item.DESC_CORTA,
                  // url: `${handleNavigation("noticias")}/${item.ID}/${item.SEO_URL_FRIENDLY}`,,
                  url:
                    pathname === "/"
                      ? `/${seoEntry.ACRONIMO_ISO}/${item.SEO_URL_FRIENDLY}`
                      : `${pathname.substring(0, pathname.lastIndexOf("/"))}/${
                          item.SEO_URL_FRIENDLY
                        }/`,
                  image: getImageUrl(item.IMAGEN, "noticias"),
                  tags:
                    JSON.parse(item.TAGS) && JSON.parse(item.TAGS).length > 0
                      ? JSON.parse(item.TAGS).map((tag) => tag.NOMBRE)
                      : ["No Tags"],
                }))
            );
          })
          .catch((error) => {
            setLoading(false);
            console.error("Error fetching data: ", error);
          });
      } catch (error) {
        setLoading(false);
        console.error("Error al cargar los datos:", error);
      }
    };
    if (idioma) cargarNoticias();
  }, [idioma]);
  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${document.location.href}`} />
        {seoRoutes
          .filter((x) => x.SEO_PAGE_ID === "1")
          .map(
            (
              lang,
              index // `index` es el índice del elemento actual en el array
            ) => (
              <link
                key={lang.ACRONIMO_ISO + index} // Concatenación del ISO y el índice para asegurar unicidad
                rel="alternate"
                hrefLang={lang.ACRONIMO_ISO.toLowerCase()}
                href={`${document.location.origin}/${lang.ACRONIMO_ISO.toLowerCase()}${
                  lang.SEO_URL_FRIENDLY.endsWith("/")
                    ? lang.SEO_URL_FRIENDLY
                    : lang.SEO_URL_FRIENDLY + "/"
                }`}
              />
            )
          )}
      </Helmet>
      <Box
        sx={{
          height: "600px",
          backgroundImage: `url(${HomeHeaderBG})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          "& *": { fontFamily: "Nunito !important" },
        }}
      >
        <HeaderHome keySelected="home" />
        <Container>
          <Box
            sx={{
              display: { xs: "flex", sm: "flex", md: "block", lg: "block", xl: "block" },
              justifyContent: "center",
              alignItems: "center",
              marginTop: { xs: "2rem", sm: "2rem", md: "43px", lg: "43px", xl: "43px" },
            }}
          >
            <Box
              sx={{
                width: "546px",
                minHeight: "304px",
                backgroundColor: "#F7F7F7",
                padding: "2rem",
                borderRadius: "8px",
                fontFamily: "Poppins",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                margin: {
                  xs: "2rem",
                  sm: "2rem",
                  md: "0",
                  lg: "0",
                  xl: "0",
                },
              }}
            >
              <Typography
                gutterBottom
                fontSize={{
                  xs: "35px",
                  sm: "40px",
                  md: "39px",
                }}
                sx={{ lineHeight: "1", color: "#222222", fontWeight: "bold" }}
                component={"h1"}
              >
                {traducciones && traducciones.HOME.title}
              </Typography>
              <Typography
                fontSize={"20px"}
                sx={{ color: "#6C6B70" }}
                lineHeight={{
                  xs: "1.3",
                  sm: "1.6",
                }}
              >
                {traducciones && traducciones.HOME.subtitle}
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
      <Grid
        container
        component={Box}
        sx={{
          padding: "61px 0 77px 0",
          "& *": { fontFamily: "Nunito !important" },
          background: "white",
          height: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12}>
          <Box textAlign={"center"}>
            <Typography
              gutterBottom
              fontSize={"40px"}
              sx={{ lineHeight: "1", fontWeight: "bold" }}
              component={"h2"}
            >
              {traducciones && traducciones.HOME.pilares}
            </Typography>

            <Box
              display={{ xs: "none", sm: "none", md: "block", lg: "block", xl: "block" }}
              marginTop={"40px"}
            >
              <Box component="img" src={Pilares} alt="Pilares" sx={{ maxWidth: "100%" }} />
            </Box>
            <Box
              sx={{
                width: "100%",
                justifyContent: "space-evenly",
                padding: "0px",
                marginTop: "60px",
                maxWidth: "830px",
                margin: "auto",
              }}
              display={{ xs: "block", sm: "block", md: "none", lg: "none", xl: "none" }}
            >
              <Box>
                <Typography fontSize={"48px"} sx={{ color: "#A24B5D" }} component={""}>
                  A
                </Typography>

                <Typography
                  fontSize={"16px"}
                  sx={{ color: "#666", fontWeight: "bold" }}
                  dangerouslySetInnerHTML={{
                    __html: traducciones && traducciones.HOME.a_texto,
                  }}
                  component={"h3"}
                ></Typography>
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "center", flexDirection: "column-reverse" }}
              >
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  sx={{
                    borderRight: "2px solid",
                    height: "20%",
                    position: "relative",
                    top: "-40px",
                  }}
                />
              </Box>
              <Box>
                <Typography fontSize={"48px"} sx={{ color: "#A24B5D" }} component={""}>
                  L
                </Typography>
                <Typography
                  fontSize={"16px"}
                  sx={{ color: "#666", fontWeight: "bold" }}
                  dangerouslySetInnerHTML={{
                    __html: traducciones && traducciones.HOME.l_texto,
                  }}
                  component={"h3"}
                ></Typography>
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "center", flexDirection: "column-reverse" }}
              >
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  sx={{
                    borderRight: "2px solid",
                    height: "20%",
                    position: "relative",
                    top: "-40px",
                  }}
                />
              </Box>
              <Box>
                <Typography fontSize={"48px"} sx={{ color: "#A24B5D" }} component={""}>
                  T
                </Typography>
                <Typography
                  fontSize={"16px"}
                  sx={{ color: "#666", fontWeight: "bold" }}
                  dangerouslySetInnerHTML={{
                    __html: traducciones && traducciones.HOME.t_texto,
                  }}
                  component={"h3"}
                ></Typography>
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "center", flexDirection: "column-reverse" }}
              >
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  sx={{
                    borderRight: "2px solid",
                    height: "20%",
                    position: "relative",
                    top: "-40px",
                  }}
                />
              </Box>
              <Box>
                <Typography fontSize={"48px"} sx={{ color: "#A24B5D" }} component={""}>
                  H
                </Typography>
                <Typography
                  fontSize={"16px"}
                  sx={{ color: "#666", fontWeight: "bold" }}
                  dangerouslySetInnerHTML={{
                    __html: traducciones && traducciones.HOME.h_texto,
                  }}
                  component={"h3"}
                ></Typography>
              </Box>
            </Box>
            <Box marginTop={"57px"}>
              <Link
                to={handleNavigation("servicios")}
                style={{ textDecoration: "none", color: "white" }}
              >
                <Button
                  sx={{
                    background:
                      "transparent linear-gradient(352deg, #A24B5D 0%, #4B5DA2 100%) 0% 0% no-repeat padding-box",
                    borderRadius: "80px !important",
                    color: "#FFFFFF !important",
                    fontSize: "13px",
                    height: "32px",
                    width: "226px",
                    textTransform: "none",
                  }}
                >
                  {traducciones && traducciones.HOME.conocenos}
                </Button>
              </Link>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box
        container
        component={Box}
        sx={{
          height: {
            xs: "auto",
            md: "500px",
          },
          // padding: "2rem",
          backgroundImage: `url(${Experiencia})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          "& *": { fontFamily: "Nunito !important" },
          display: "flex",
          alignItems: "flex-start",
          padding: {
            xs: "30px 0",
            md: "61px 0 0 0 ",
          },
        }}
      >
        <Container
          sx={{
            maxWidth: {
              xl: "1320px!important", // max-width 1320px para xl
              lg: "1140px!important", // max-width 1140px para lg
              md: "100%!important", // max-width 100% para md
              sm: "100%!important", // max-width 100% para sm
              xs: "100%!important", // max-width 100% para xs
            },
          }}
        >
          <Grid container>
            <Grid
              item
              md={4}
              lg={6}
              xl={6}
              component={Box}
              display={{ xs: "none", sm: "none", md: "flex", lg: "flex", xl: "flex" }}
              sx={{
                alignItems: "center",
                justifyContent: "center",
                img: {
                  maxWidth: {
                    xs: "30%", // max-width 100% para md
                    sm: "30%", // max-width 100% para md
                    md: "80%", // max-width 100% para md
                    lg: "80%", // max-width 100% para md
                    xl: "450px", // max-width 1140px para lg
                  },
                },
              }}
            >
              <img src={ALTHLogo} alt="alth logo" width="100%" />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={6}
              xl={6}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: {
                  xs: "center",
                  md: "flex-end",
                },
              }}
            >
              <Box
                sx={{
                  width: "546px",
                  minHeight: "317px",
                  backgroundColor: "#F7F7F7",
                  padding: "36px 48px 46px 48px",
                  borderRadius: "8px",
                  // fontFamily: "Poppins",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  marginRight: "0px",
                }}
              >
                <Typography
                  gutterBottom
                  fontSize={"40px"}
                  sx={{ lineHeight: "1", color: "#222222", fontWeight: "bold" }}
                  component={"h2"}
                >
                  {traducciones && traducciones.HOME.experiencia}
                </Typography>
                <Typography fontSize={"17px"} lineHeight={"23px"} sx={{ color: "#666" }}>
                  {traducciones && traducciones.HOME.descripcion_experiencia}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          height: {
            xs: "auto",
            sm: "auto",
            md: "auto",
            lg: "450px",
            xl: "573px",
          },
          padding: "0",
          "& *": { fontFamily: "Nunito !important" },
          background: "white",
        }}
      >
        <Container
          sx={{
            maxWidth: {
              xl: "1320px!important", // max-width 1320px para xl
              lg: "1140px!important", // max-width 1140px para lg
              md: "100%!important", // max-width 100% para md
              sm: "100%!important", // max-width 100% para sm
              xs: "100%!important", // max-width 100% para xs
            },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Grid
            container
            component={Box}
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: {
                xs: "row",
                sm: "row",
                md: "row-reverse",
                lg: "row-reverse",
                xl: "row-reverse",
              },
              // maxWidth: "1300px",
              padding: {
                xs: "20px 0 30px 0",
                lg: "0",
              },
            }}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <img
                src={Premio}
                alt="PremioBestPerfomance"
                style={{ maxWidth: "500px", width: "100%" }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              sx={{ display: "flex", justifyContent: "flex-start" }}
            >
              <Box
                sx={{
                  maxWidth: {
                    xs: "100%",
                    lg: "500px",
                  },
                  Width: "100%",
                }}
              >
                <Typography sx={{ fontSize: "21px", color: "#020200", lineHeight: "30px" }}>
                  {traducciones && traducciones.HOME.reconocimiento}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          backgroundImage: `url(${Contadores})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          "& *": { fontFamily: "Nunito !important" },
          height: {
            xs: "100%",
            sm: "100%",
            md: "237px",
            lg: "237px",
            xl: "237px",
          },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          container
          component={Box}
          sx={{
            padding: {
              xs: "20px 0",
              lg: "0",
              xl: "0",
            },
            maxWidth: {
              xs: "100%",
              lg: "920px",
            },
            color: "#FFFFFF",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <Typography gutterBottom sx={{ fontSize: "30px", fontWeight: "bold" }}>
              {traducciones && traducciones.HOME.counter_description_1}
            </Typography>
            <Typography gutterBottom sx={{ fontSize: "18px" }}>
              {traducciones && traducciones.HOME.counter_1}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <Typography gutterBottom sx={{ fontSize: "30px", fontWeight: "bold" }}>
              {traducciones && traducciones.HOME.counter_description_2}
            </Typography>
            <Typography gutterBottom sx={{ fontSize: "18px" }}>
              {traducciones && traducciones.HOME.counter_2}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <Typography gutterBottom sx={{ fontSize: "30px", fontWeight: "bold" }}>
              {traducciones && traducciones.HOME.counter_description_3}
            </Typography>
            <Typography gutterBottom sx={{ fontSize: "18px" }}>
              {traducciones && traducciones.HOME.counter_3}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <Typography gutterBottom sx={{ fontSize: "30px", fontWeight: "bold" }}>
              {traducciones && traducciones.HOME.counter_description_4}
            </Typography>
            <Typography gutterBottom sx={{ fontSize: "18px" }}>
              {traducciones && traducciones.HOME.counter_4}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Grid
        component={Box}
        sx={{
          background: "white",
        }}
      >
        <Grid
          container
          component={Box}
          sx={{
            padding: "50px 0  2px 0",
            "& *": { fontFamily: "Nunito !important" },
            background: "white",
          }}
        >
          <Grid item xs={12}>
            <Box textAlign={"center"}>
              <Typography
                gutterBottom
                fontSize={"48px"}
                sx={{ lineHeight: "1", fontWeight: "bold" }}
                component={"h2"}
              >
                {traducciones && traducciones.HOME.actualidad}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {!loading && (
          <Box
            display={"none"}
            sx={{
              padding: "2rem",
              paddingBottom: "100px",
              "& *": { fontFamily: "Nunito !important" },
              background: "#FFF",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              color: "#FFFFFF",
              textAlign: "center",
              justifyContent: "center",
              gap: "2rem",
              margin: "auto",

              maxWidth: {
                xl: "1320px!important", // max-width 1320px para xl
                lg: "1140px!important", // max-width 1140px para lg
                md: "100%!important", // max-width 100% para md
                sm: "100%!important", // max-width 100% para sm
                xs: "100%!important", // max-width 100% para xs
              },

              "@media (min-width:920px)": {
                display: "flex", // Esto hace que se oculte a partir de 920px
              },
            }}
          >
            <Grid
              container
              sx={{
                maxWidth: "850px",
              }}
            >
              {news.map((noticia) => (
                <Grid item key={noticia.title} sm={6} md={4}>
                  <NewsCard
                    title={noticia.title}
                    image={noticia.image}
                    content={noticia.content}
                    url={noticia.url}
                    tags={noticia.tags}
                    handleClickTag={() => {}}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
        {!loading && (
          <Box
            display={"flex"}
            sx={{
              justifyContent: "center",
              background: "#FFF",
              paddingY: "3rem",
              "@media (min-width:920px)": {
                display: "none", // Esto hace que se oculte a partir de 920px
              },
              margin: "auto",
            }}
          >
            <Grid container rowGap={"2rem"}>
              {news.map((noticia) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={3}
                  key={noticia.title}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <NewsCard
                    title={noticia.title}
                    image={noticia.image}
                    content={noticia.content}
                    url={noticia.url}
                    tags={noticia.tags}
                    handleClickTag={() => {}}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Grid>
      <FrontOfficeFooter />
    </>
  );
}
export default HomePage;
