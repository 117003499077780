/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import { Formik } from "formik";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  DialogActions,
  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { UploadImageBanner } from "components/shared/UploadImageBanner";
import { RootDialog } from "components/RootDialog/RootDialog";
import { NoCropper } from "components/Cropper/NoCropper";

function SectionsCreateDialog({
  open,
  setOpen,
  formState,
  setFormState,
  setSubmitForm,
  saveImg,
  folderSave,
}) {
  const api_key = localStorage.getItem("access_token") || "";
  const [imageHasBeenCropped, setImageHasBeenCropped] = useState(false);
  const [showImageError, setShowImageError] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [file, setFile] = useState(null);
  const [rootFile, setRootFile] = useState(null);
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    multiple: false,
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      setRootFile(acceptedFiles[0]);
      setFile({
        ...acceptedFiles[0],
        preview: URL.createObjectURL(acceptedFiles[0]),
      });
    },
  });

  useEffect(() => {
    if (croppedImage) {
      setImageHasBeenCropped(true);
    } else {
      setImageHasBeenCropped(false);
    }
  }, [croppedImage]);
  const handleClose = () => {
    setOpen(false);
    setFile(null);
    setCroppedImage(null);
  };

  const handleCancelForm = () => {
    handleClose();
  };

  return (
    <>
      <RootDialog
        maxWidth="sm"
        fullWidth
        onClose={handleClose}
        aria-labelledby="compatible-mfa-applications-modal"
        open={open}
      >
        <Formik
          initialValues={{}}
          validationSchema={Yup.object().shape({})}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            setSubmitting(true);
            setShowImageError(false);
            console.log({ rootFile, img: formState.image });
            if (rootFile || formState.image) {
              let { image } = formState;
              if (!image) {
                let fileToUpload = rootFile;
                /* if (rootFile) {
                  const res = await fetch(croppedImage);
                  const blob = await res.blob();
                  fileToUpload = new File([blob], rootFile.name, {
                    type: rootFile.type,
                  });
                } */
                const data = new FormData();
                data.append("file", fileToUpload);
                const imageReq = await fetch(
                  `${process.env.REACT_APP_API_BASE_URL}/uploader?folder=${folderSave}`,
                  {
                    method: "POST",
                    body: data,
                    headers: {
                      authToken: api_key,
                    },
                  }
                );
                const imageResult = await imageReq.json();
                // image = `${
                //   process.env.REACT_APP_API_BASE_URL
                // }/files/${folderSave}/${imageResult.path.split("\\").pop()}`.replaceAll("api/", "");
                var image_path =
                  `${process.env.REACT_APP_API_BASE_URL}/files/${folderSave}/${imageResult.filename}`.replaceAll(
                    "api/",
                    ""
                  );
                image = imageResult.filename;
              }
              setFormState({
                image: image_path,
              });
              setFile(null);
              setCroppedImage(null);
              setSubmitForm(true);
              saveImg(image);
            }
            if (!imageHasBeenCropped && !formState.image) {
              setShowImageError(true);
            }
            setSubmitting(false);
            setOpen(false);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            submitForm,
            setFieldValue,
            touched,
            values,
          }) => (
            <>
              <DialogTitle sx={{ m: 0, p: 2, paddingBottom: "0 !important" }} variant="h6">
                Editar imagen del equipo
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: "#CCC",
                }}
              >
                <CloseIcon />
              </IconButton>
              <DialogContent>
                <>
                  <Grid container>
                    <Grid item xs={11}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                      >
                        Imagen
                      </Typography>
                    </Grid>
                    {file || formState.image ? (
                      <Grid item xs={1}>
                        <IconButton
                          onClick={() => {
                            setFormState({
                              ...formState,
                              image: "",
                            });
                            setFile(null);
                            setCroppedImage(null);
                            setImageHasBeenCropped(false);
                          }}
                        >
                          <Close />
                        </IconButton>
                      </Grid>
                    ) : (
                      <Grid item xs={1} />
                    )}
                  </Grid>
                  {formState.image !== "" && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#eee",
                        padding: "2rem",
                        overflow: "hidden",
                      }}
                    >
                      <img src={formState.image} alt="service" style={{ maxWidth: "100%" }} />
                    </Box>
                  )}
                  {formState.image === "" && (
                    <Grid container>
                      {file && rootFile ? (
                        <Grid
                          item
                          xs={12}
                          sx={{
                            ml: 0,
                            mr: 0,
                            minHeight: "350px",
                            width: "100%",
                            border: `1px dashed black`,
                            borderRadius: "0px",
                            textAlign: "center",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: 600,
                                }}
                              >
                                Vista Previa
                              </Typography>
                              <Box style={{ maxWidth: "90%", padding: "1rem" }}>
                                <NoCropper
                                  imageToCrop={URL.createObjectURL(rootFile)}
                                  croppedImage={(value) => setCroppedImage(value)}
                                  ratio={16 / 9}
                                />
                              </Box>
                            </Grid>
                            {/* <Grid item xs={6}>
                              <Typography
                                sx={{
                                  fontWeight: 600,
                                }}
                              >
                                Vista Previa
                              </Typography>
                              {croppedImage ? (
                                <img
                                  src={croppedImage}
                                  alt="preview"
                                  style={{ maxWidth: "90%", padding: "1rem" }}
                                />
                              ) : (
                                <img
                                  src={file.preview}
                                  alt="preview"
                                  style={{ maxWidth: "90%", padding: "1rem" }}
                                />
                              )}
                            </Grid> */}
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid
                          item
                          id="imageBAnnercontainer"
                          xs={12}
                          sx={{
                            ml: 0,
                            mr: 0,
                            minHeight: "189px",
                            width: "100%",
                            border: `1px dashed black`,
                            borderRadius: "0px",
                            padding: "2rem",
                          }}
                        >
                          <UploadImageBanner rootProps={getRootProps} />
                          <input
                            accept=".jpg, .jpeg, .png, .gif, .svg, .pdf"
                            {...getInputProps()}
                          />
                        </Grid>
                      )}
                    </Grid>
                  )}
                  {showImageError && (
                    <Typography fontSize={14} color="error">
                      Debe subir y recortar una imagen
                    </Typography>
                  )}
                </>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCancelForm} variant="outlined" sx={{ color: "#000" }}>
                  CANCELAR
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={submitForm}
                  disabled={isSubmitting}
                  sx={{ color: "#FFF" }}
                >
                  GUARDAR
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
      </RootDialog>
    </>
  );
}

SectionsCreateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  formState: PropTypes.object.isRequired,
  setFormState: PropTypes.func.isRequired,
  setSubmitForm: PropTypes.func.isRequired,
  saveImg: PropTypes.func.isRequired,
  folderSave: PropTypes.string,
};

export default SectionsCreateDialog;
