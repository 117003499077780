import React from "react";
import { CircularProgress, Box, Typography } from "@mui/material";

const LoadingScreen = () => {
  return (
    <Box
      style={{
        position: "fixed", // Fijo en toda la pantalla
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.85)", // Fondo negro con opacidad
        zIndex: 9999, // Elevación para estar sobre otros elementos
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box style={{ textAlign: "center" }}>
        <CircularProgress style={{ color: "#1a90ff" }} size={80} />
        <Typography variant="h6" style={{ marginTop: 20, color: "#FFFFFF" }}>
          {/* Cargando... */}
        </Typography>
      </Box>
    </Box>
  );
};

export default LoadingScreen;
