import React, { useState, useContext } from "react";
import { TraduccionesContext } from "components/shared/TraduccionesContext";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Grid,
  FormControl,
  TextField,
  FormHelperText,
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import SeoRoutesContext from "../../components/shared/SeoRoutesContext";
import Cookies from "js-cookie";
import { useNavigate, useLocation } from "react-router-dom";

export const ContactForm = ({ rightAligned }) => {
  const { traducciones } = useContext(TraduccionesContext);
  const seoRoutes = useContext(SeoRoutesContext);
  const navigateTo = (routeKey, seoRoutes, lang) => {
    let path = "";
    const seoEntry = seoRoutes.find(
      (seo) =>
        seo.PAGE.replaceAll(".aspx", "").toLowerCase() === routeKey.toLowerCase() &&
        seo.ACRONIMO_ISO === lang
    );
    if (seoEntry) {
      path = `/${seoEntry.ACRONIMO_ISO}/${seoEntry.SEO_URL_FRIENDLY}`;
    } else {
      path = routeKey;
    }
    path = path.replace("//", "/");
    return path;
  };
  const handleNavigation = (key) => {
    const pathSegments = location.pathname.split("/").filter(Boolean);
    const lang = pathSegments[0] || "es"; // Asumir "es" como idioma por defecto si no se encuentra ninguno
    Cookies.set("lang", lang, { expires: 7 });
    const path = navigateTo(key, seoRoutes, lang);
    return path; // Not typically needed unless you have other uses for path after navigation
  };
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <Box>
      <Formik
        initialValues={{
          email: "",
          subject: "",
          message: "",
          checked: false,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().max(255).required("E-mail es obligatorio"),
          subject: Yup.string().max(255).required("Asunto es obligatorio"),
          message: Yup.string().max(255).required("Mensaje es obligatorio"),
          checked: Yup.boolean().oneOf([true], "Debe aceptar la política de privacidad."),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          const { email, subject, message: msg } = values;
          const result = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/contact-form`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email, subject, msg }),
          });
          const data = await result.json();
          console.log(data);
          setSubmitting(false);
          navigate(`${handleNavigation("contacto")}/success`);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          submitForm,
          isValid,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid
              container
              spacing={2}
              sx={{
                input: {
                  fontSize: "13px",
                  padding: "9px 18px",
                },

                textarea: {
                  fontSize: "13px",
                  padding: "9px 18px 9px 5px",
                },
              }}
            >
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.email && errors.email)}
                  sx={{ marginBottom: "0" }}
                >
                  <TextField
                    type="text"
                    value={values.email}
                    name="email"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      setFieldValue("email", e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") e.preventDefault();
                    }}
                    variant="filled"
                    size="small"
                    sx={{
                      "& .MuiInputBase-input": {},
                    }}
                    placeholder={traducciones && traducciones.CONTACTO.email_placeholder}
                    InputProps={{
                      disableUnderline: true,
                      notched: false,
                      sx: {
                        paddingTop: "0 !important",
                      },
                    }}
                  />
                  {touched.email && errors.email && (
                    <FormHelperText error id="standard-weight-helper-text-password-login">
                      {errors.email}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.subject && errors.subject)}
                  sx={{ marginBottom: "0" }}
                >
                  <TextField
                    type="text"
                    value={values.subject}
                    name="subject"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      setFieldValue("subject", e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") e.preventDefault();
                    }}
                    variant="filled"
                    size="small"
                    sx={{
                      "& .MuiInputBase-input": {},
                    }}
                    placeholder={traducciones && traducciones.CONTACTO.subject_placeholder}
                    InputProps={{
                      disableUnderline: true,
                      notched: false,
                      sx: {
                        paddingTop: "0 !important",
                      },
                    }}
                  />
                  {touched.subject && errors.subject && (
                    <FormHelperText error id="standard-weight-helper-text-password-login">
                      {errors.subject}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.email && errors.email)}
                  sx={{ marginBottom: "0" }}
                >
                  <TextField
                    multiline
                    rows="5"
                    type="text"
                    value={values.message}
                    name="message"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      setFieldValue("message", e.target.value);
                    }}
                    variant="filled"
                    size="small"
                    sx={{
                      "& .MuiInputBase-input": {},
                    }}
                    placeholder={traducciones && traducciones.CONTACTO.msg_placeholder}
                    InputProps={{
                      disableUnderline: true,
                      notched: false,
                      sx: {
                        paddingTop: "0 !important",
                      },
                    }}
                  />
                  {touched.message && errors.message && (
                    <FormHelperText error id="standard-weight-helper-text-password-login">
                      {errors.message}
                    </FormHelperText>
                  )}
                </FormControl>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  sx={{ "& .MuiFormControlLabel-root": { display: "flex" } }}
                >
                  <FormControlLabel
                    label={
                      <Typography fontSize="13px" sx={{ color: "rgba(186, 188, 189, 1)" }}>
                        {traducciones && traducciones.CONTACTO.pre_private_policy}{" "}
                        <span style={{ cursor: "pointer", color: "rgba(162, 75, 93, 1)" }}>
                          {traducciones && traducciones.CONTACTO.private_policy}
                        </span>
                      </Typography>
                    }
                    control={
                      <Checkbox
                        checked={values.checked}
                        onChange={() => setFieldValue("checked", !values.checked)}
                      />
                    }
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                marginTop: "2rem",
                textAlign: rightAligned ? "right" : "center",
              }}
            >
              <Button
                sx={{
                  background:
                    "transparent linear-gradient(352deg, #A24B5D 0%, #4B5DA2 100%) 0% 0% no-repeat padding-box",
                  borderRadius: "80px !important",
                  color: "#FFFFFF !important",
                  fontSize: "13px",
                  height: "32px",
                  width: "109px",
                  textTransform: "none",
                }}
                disabled={!isValid || isSubmitting}
                type="submit"
                variant="gradient"
                color="primary"
                size="medium"
              >
                {traducciones && !isSubmitting && traducciones.CONTACTO.submit_text}
                {isSubmitting && "..."}
              </Button>
            </Grid>
          </form>
        )}
      </Formik>
    </Box>
  );
};

ContactForm.propTypes = {
  rightAligned: PropTypes.boolean,
};
