import { useState } from "react";
import PropTypes from "prop-types";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Crop } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

export const Cropper = ({ imageToCrop, croppedImage, ratio = 1 }) => {
  const [crop, setCrop] = useState({ aspect: ratio });
  const [image, setImage] = useState(null);

  const cropImageNow = () => {
    if (image) {
      const canvas = document.createElement("canvas");
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");

      const pixelRatio = window.devicePixelRatio;
      canvas.width = crop.width * pixelRatio;
      canvas.height = crop.height * pixelRatio;
      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = "high";

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      const base64Image = canvas.toDataURL("image/jpeg");
      croppedImage(base64Image);
    }
  };
  return (
    <div>
      <div>
        {imageToCrop && (
          <div>
            <ReactCrop
              src={imageToCrop}
              onImageLoaded={setImage}
              crop={crop}
              onChange={setCrop}
              aspect={1}
            />
            <br />
            <Tooltip title="Crop Image">
              <IconButton onClick={cropImageNow}>
                <Crop />
              </IconButton>
            </Tooltip>
            <br />
            <br />
          </div>
        )}
      </div>
    </div>
  );
};

Cropper.propTypes = {
  croppedImage: PropTypes.object.isRequired,
  imageToCrop: PropTypes.object.isRequired,
  ratio: PropTypes.object,
};
