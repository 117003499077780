import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  Grid,
  Typography,
  Divider,
  Avatar,
  IconButton,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { RootDialog } from "components/RootDialog/RootDialog";
import SectionsCreateDialog from "./sections_create_dialog";
import MDTypography from "components/MDTypography";
function SectionsTable({ mode, list, setList }) {
  //   Modal
  const [dialogOpen, setDialogOpen] = useState(false);
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [submitForm, setSubmitForm] = useState(false);
  const [previewImageUrl, setPreviewImageUrl] = useState("");
  const [formState, setFormState] = useState({
    image: "",
    title: "",
    subtitle: "",
    description: "",
  });

  useEffect(() => {
    if (
      submitForm &&
      formState.title !== "" &&
      formState.subtitle !== "" &&
      formState.description !== ""
    ) {
      let sectionList = list.filter((section) => section.title && section.title !== "");
      if (formState.id && !String(formState.id).includes("-marker") && Number(formState.id) > 0) {
        sectionList = sectionList.map((section) =>
          section.id === formState.id ? formState : section
        );
      } else if (
        String(formState.id).includes("-marker") &&
        sectionList.find((section) => section.id === formState.id)
      ) {
        sectionList = sectionList.map((section) =>
          section.id === formState.id ? formState : section
        );
      } else {
        sectionList.push(formState);
      }
      setList(sectionList);
      setFormState({
        image: "",
        title: "",
        subtitle: "",
        description: "",
      });
      setSubmitForm(false);
      setDialogOpen(false);
    }
  }, [submitForm, formState, list, setList]);

  const handleNew = () => {
    setFormState({
      pos: 1,
      image: "",
      title: "",
      subtitle: "",
      description: "",
    });
    setDialogOpen(true);
  };

  const handleDelete = (index) => {
    const newList = [...list];
    if (mode === "create") {
      newList.splice(index, 1);
      setList(newList);
    } else {
      const protoList = newList
        .map((item, _pos) => (_pos !== index ? item : { ...item, id: `-${item.id}` }))
        .filter((item) => String(item.id).includes("-") || +item.id >= 0);
      console.log("!!!!!", {
        index,
        list: protoList[0],
        c1: String(protoList[0].id).includes("-"),
        c2: protoList[0].id,
      });
      setList(
        newList.map((item, _pos) => (_pos !== index ? item : { ...item, id: `-${item.id}` }))
      );
    }
  };

  useEffect(() => {
    console.log("list", list);
  }, [list]);

  const handleEdit = (index) => {
    setFormState(
      list.sort((a, b) => {
        const nameA = a.pos;
        const nameB = b.pos;
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })[index]
    );
    setDialogOpen(true);
  };

  const handlePreview = (url) => {
    setPreviewImageUrl(url);
    setPreviewDialogOpen(true);
  };

  const handleClosePreview = () => {
    setPreviewDialogOpen(false);
  };

  return (
    <Box>
      <Card
        sx={{
          marginTop: "2rem",
          padding: "2rem",
          border: "1px solid rgb(118, 118, 118)",
          borderRadius: 0,
        }}
      >
        <Box
          sx={{
            marginBottom: "1rem",
          }}
        >
          <Grid container sx={{ alignItems: "center" }}>
            <Grid item xs={8}>
              <MDTypography variant="h7">Secciones</MDTypography>
            </Grid>
            {mode !== "view" && (
              <Grid item xs={4}>
                <Button
                  sx={{ textTransform: "capitalize", color: "#FFF" }}
                  disableElevation
                  fullWidth
                  size="large"
                  variant="contained"
                  onClick={handleNew}
                >
                  Agregar Sección <Add />
                </Button>
              </Grid>
            )}
          </Grid>
          <Grid container sx={{ alignItems: "center", padding: ".5rem" }}>
            <Grid item xs={3} sx={{ textAlign: "left" }}>
              <Typography sx={{ fontSize: "16px" }}>Imagen</Typography>
            </Grid>
            <Grid item xs={2} sx={{ textAlign: "left" }}>
              <Typography sx={{ fontSize: "16px" }}>Titulo</Typography>
            </Grid>
            <Grid item xs={2} sx={{ textAlign: "left" }}>
              <Typography sx={{ fontSize: "16px" }}>Subtitulo</Typography>
            </Grid>
            <Grid item xs={3} sx={{ textAlign: "left" }}>
              <Typography sx={{ fontSize: "16px" }}>Descripción</Typography>
            </Grid>
          </Grid>
          <Divider sx={{ borderColor: "#000" }} />
          {list.length > 0 ? (
            <>
              {list
                .filter((item) => !String(item.id).includes("-") && +item.id >= 0)
                .sort((a, b) => {
                  const nameA = a.pos;
                  const nameB = b.pos;
                  if (nameA < nameB) {
                    return -1;
                  }
                  if (nameA > nameB) {
                    return 1;
                  }
                  return 0;
                })
                .map((item, _index) => (
                  <>
                    <Grid key={_index} container sx={{ alignItems: "center", padding: ".5rem" }}>
                      <Grid item xs={3} sx={{ textAlign: "left" }}>
                        <Avatar variant="square">
                          <img src={`${item.image}`} alt="thumbnail" style={{ width: "100%" }} />
                        </Avatar>
                      </Grid>
                      <Grid item xs={2} sx={{ textAlign: "left" }}>
                        <Typography fontSize={"14px"}>{item.title}</Typography>
                      </Grid>
                      <Grid item xs={2} sx={{ textAlign: "left" }}>
                        <Typography fontSize={"14px"}>{item.subtitle}</Typography>
                      </Grid>
                      <Grid item xs={3} sx={{ textAlign: "left" }}>
                        <Typography fontSize={"14px"}>{item.description}</Typography>
                      </Grid>
                      <Grid item xs={2} sx={{ textAlign: "right" }}>
                        <Grid container>
                          <Grid item xs={8} />
                          <Grid item xs={2}>
                            <IconButton
                              onClick={() => {
                                handleEdit(_index);
                              }}
                            >
                              <Edit sx={{ color: "#000" }} />
                            </IconButton>
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton
                              onClick={() => {
                                handleDelete(_index);
                              }}
                            >
                              <Delete sx={{ color: "#000" }} />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider key={`${_index}-divider`} sx={{ borderColor: "#000" }} />
                  </>
                ))}
            </>
          ) : (
            <Box sx={{ margin: "1rem 0" }}>
              <Typography sx={{ textAlign: "center" }}>Sin secciones agregadas</Typography>
            </Box>
          )}
        </Box>
        <RootDialog open={previewDialogOpen} onClose={handleClosePreview}>
          <DialogTitle sx={{ m: 0, p: 2, paddingBottom: "0 !important" }} variant="h6">
            Image Preview
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClosePreview}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#CCC",
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent sx={{ maxWidth: "100%" }}>
            <img src={`${previewImageUrl}`} alt="preview" style={{ maxWidth: "100%" }} />
          </DialogContent>
        </RootDialog>
        <SectionsCreateDialog
          open={dialogOpen}
          setOpen={setDialogOpen}
          formState={formState}
          setFormState={setFormState}
          setSubmitForm={setSubmitForm}
          mode={mode}
        />
      </Card>
    </Box>
  );
}

SectionsTable.propTypes = {
  mode: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  setList: PropTypes.func.isRequired,
};

export default SectionsTable;
