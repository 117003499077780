/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo, useContext } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import LoadingScreen from "components/LoadingScreen";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 React routes
import routes from "routes";
import routesData from "./layouts/seo/data/routesData";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";

import NoticiasPage from "layouts/frontpage/noticias/index";
import NoticiasDetailPage from "layouts/frontpage/noticias/detail";
import SeoRoutesContext from "./components/shared/SeoRoutesContext";
import { TraduccionesContextProvider } from "./components/shared/TraduccionesContext";

export default function App() {
  const [seoRoutes, setSeoRoutes] = useState([]);
  const [seoServiciosEs, setseoServiciosEs] = useState([]);
  const [seoServiciosEn, setseoServiciosEn] = useState([]);
  const [seoNoticiasEs, setseoNoticiasEs] = useState([]);
  const [seoNoticiasEn, setseoNoticiasEn] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isDataLoadedServiciosEs, setisDataLoadedServiciosEs] = useState(false);
  const [isDataLoadedServiciosEn, setisDataLoadedServiciosEn] = useState(false);
  const [isDataLoadedNoticiasEs, setisDataLoadedNoticiasEs] = useState(false);
  const [isDataLoadedNoticiasEn, setisDataLoadedNoticiasEn] = useState(false);
  // const { traducciones } = useContext(TraduccionesContextProvider);
  // Fetch SEO routes
  useEffect(() => {
    const api_key = localStorage.getItem("access_token") || "";
    fetch(`${process.env.REACT_APP_API_BASE_URL}/seo/getRoutes`, {
      method: "GET",
      headers: { authToken: api_key },
    })
      .then((response) => response.json())
      .then((data) => {
        setSeoRoutes(data);
        setIsDataLoaded(true); // Indicate that data has been loaded
      })
      .catch((error) => console.error("Error fetching SEO routes: ", error));
  }, []);

  useEffect(() => {
    const api_key = localStorage.getItem("access_token") || "";
    fetch(`${process.env.REACT_APP_API_BASE_URL}/services/list/1`, {
      method: "GET",
      headers: { authToken: api_key },
    })
      .then((response) => response.json())
      .then((data) => {
        setseoServiciosEs(data.response.data);
        setisDataLoadedServiciosEs(true); // Indicate that data has been loaded
      })
      .catch((error) => console.error("Error fetching SEO routes: ", error));
  }, []);

  useEffect(() => {
    const api_key = localStorage.getItem("access_token") || "";
    fetch(`${process.env.REACT_APP_API_BASE_URL}/services/list/2`, {
      method: "GET",
      headers: { authToken: api_key },
    })
      .then((response) => response.json())
      .then((data) => {
        setseoServiciosEn(data.response.data);
        setisDataLoadedServiciosEn(true); // Indicate that data has been loaded
      })
      .catch((error) => console.error("Error fetching SEO routes: ", error));
  }, []);

  useEffect(() => {
    const api_key = localStorage.getItem("access_token") || "";
    fetch(`${process.env.REACT_APP_API_BASE_URL}/noticias?lang=1`, {
      method: "GET",
      headers: { authToken: api_key },
    })
      .then((response) => response.json())
      .then((data) => {
        setseoNoticiasEs(data);
        setisDataLoadedNoticiasEs(true); // Indicate that data has been loaded
      })
      .catch((error) => console.error("Error fetching SEO routes: ", error));
  }, []);

  useEffect(() => {
    const api_key = localStorage.getItem("access_token") || "";
    fetch(`${process.env.REACT_APP_API_BASE_URL}/noticias?lang=2`, {
      method: "GET",
      headers: { authToken: api_key },
    })
      .then((response) => response.json())
      .then((data) => {
        setseoNoticiasEn(data);
        setisDataLoadedNoticiasEn(true); // Indicate that data has been loaded
      })
      .catch((error) => console.error("Error fetching SEO routes: ", error));
  }, []);

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const [frontPageRoutes, setFrontPageRoutes] = useState([]);
  const { pathname } = useLocation();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  if (
    !isDataLoaded ||
    !isDataLoadedNoticiasEn ||
    !isDataLoadedNoticiasEs ||
    !isDataLoadedServiciosEn ||
    !isDataLoadedServiciosEs /* || traducciones.traducciones == null*/
  ) {
    return <LoadingScreen />;
  }

  const getRoutes = (
    allRoutes,
    seoRoutes,
    seoServiciosEs,
    seoServiciosEn,
    seoNoticiasEs,
    seoNoticiasEn
  ) => {
    let finalroutes = allRoutes.flatMap((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse, seoRoutes); // Recursively process any nested routes
      }
      // Check if there are SEO-friendly URLs for this route
      // Filtrar para encontrar entradas SEO que coincidan con la clave de la ruta
      const matchedSeoEntries = seoRoutes.filter(
        (seoEntry) =>
          seoEntry.PAGE.toLowerCase().replaceAll(".aspx", "") === route.key.toLowerCase()
      );
      if (matchedSeoEntries.length > 0) {
        return matchedSeoEntries.flatMap((seoEntry) => {
          const baseRoute = (
            <Route
              exact
              path={`/${seoEntry.ACRONIMO_ISO}/${seoEntry.SEO_URL_FRIENDLY}`}
              element={route.component}
              key={`${route.key}-${seoEntry.ACRONIMO_ISO}`}
            />
          );
          let detailRoutes = [];
          if (seoEntry.SEO_PAGE_ID === "2") {
            //servicios

            var seoServicios = [];
            if (seoEntry.LANGUAGE_ID == 1) {
              seoServicios = seoServiciosEs;
            } else if (seoEntry.LANGUAGE_ID == 2) {
              seoServicios = seoServiciosEn;
            }
            detailRoutes = seoServicios.map((seoEntryRow) => (
              <Route
                exact
                path={`/${seoEntry.ACRONIMO_ISO}/${seoEntryRow.seoURLFriendly}`}
                element={route.detailComponent}
                key={`${seoEntry.ACRONIMO_ISO}-${seoEntry.LANGUAGE_ID}-${seoEntry.SEO_PAGE_ID}-${seoEntryRow.id}`}
              />
            ));

            // const detailRoute = detailRoutes[0];
            // (
            //   <Route
            //     exact
            //     path={`/${seoEntry.ACRONIMO_ISO}/recobro-amistoso`}
            //     element={route.detailComponent || route.component}
            //     key={`${route.key}-${seoEntry.LANGUAGE_ID}`}
            //   />
            // );

            const baseRouteParent = (
              <Route
                exact
                path={`/${seoEntry.ACRONIMO_ISO}/${seoEntry.SEO_URL_FRIENDLY}`}
                element={route.component}
                key={`${route.key}-${seoEntry.ACRONIMO_ISO}`}
              />
            );
            return [baseRouteParent, ...detailRoutes];
          } else if (seoEntry.SEO_PAGE_ID === "6") {
            //noticias
            var seoNoticias = [];
            if (seoEntry.LANGUAGE_ID == 1) {
              seoNoticias = seoNoticiasEs;
            } else if (seoEntry.LANGUAGE_ID == 2) {
              seoNoticias = seoNoticiasEn;
            }
            const detailRoutes = seoNoticias.map((seoEntryRow) => (
              <Route
                exact
                path={`/${seoEntry.ACRONIMO_ISO}/${seoEntryRow.SEO_URL_FRIENDLY}`}
                element={route.detailComponent}
                key={`${seoEntry.ACRONIMO_ISO}-${seoEntry.LANGUAGE_ID}-${seoEntry.SEO_PAGE_ID}-${seoEntryRow.ID}`}
              />
            ));

            const baseRouteParent = (
              <Route
                exact
                path={`/${seoEntry.ACRONIMO_ISO}/${seoEntry.SEO_URL_FRIENDLY}`}
                element={route.component}
                key={`${route.key}-${seoEntry.ACRONIMO_ISO}`}
              />
            );
            return [baseRouteParent, ...detailRoutes];
          } else {
            var detailRoute = (
              <Route
                exact
                path={`/${seoEntry.ACRONIMO_ISO}/${seoEntry.SEO_URL_FRIENDLY}/:id${
                  seoEntry.SEO_PAGE_ID === "6" || seoEntry.SEO_PAGE_ID === "2" ? "/:seo_url" : ""
                }`}
                element={route.detailComponent || route.component}
                key={`${route.key}-${seoEntry.LANGUAGE_ID}`}
              />
            );
          }

          // Si es la página 'home' y el idioma es 'es', añadir rutas especiales para '/'
          // if (route.key === "home" && seoEntry.ACRONIMO_ISO === "es") {
          //   return [
          //     baseRoute,
          //     <Route exact path="/" element={route.component} key={`${route.key}-root`} />,
          //   ];
          // }

          if (route.key !== "home") {
            return [baseRoute, detailRoute];
          }

          return baseRoute;
        });
      }
      // If no SEO-friendly URL, return the default route
      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

    return finalroutes;
  };

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return (
    <>
      {localStorage.getItem("access_token") ||
      pathname.includes("authentication") ||
      !pathname.includes("admin") ? (
        <TraduccionesContextProvider>
          <SeoRoutesContext.Provider value={seoRoutes}>
            <ThemeProvider theme={darkMode ? themeDark : theme}>
              <CssBaseline />
              {pathname.includes("admin") && (
                <>
                  <Sidenav
                    color={sidenavColor}
                    brand={
                      (transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite
                    }
                    brandName="ATHLOAN"
                    routes={routes}
                    onMouseEnter={handleOnMouseEnter}
                    onMouseLeave={handleOnMouseLeave}
                  />
                  <Configurator />
                </>
              )}
              {layout === "vr" && <Configurator />}
              <Routes>
                {getRoutes(
                  routes,
                  seoRoutes,
                  seoServiciosEs,
                  seoServiciosEn,
                  seoNoticiasEs,
                  seoNoticiasEn
                )}
                <Route path="*" element={<Navigate to="/es/" />} />
              </Routes>
            </ThemeProvider>
          </SeoRoutesContext.Provider>
        </TraduccionesContextProvider>
      ) : (
        <Navigate to="/authentication/sign-in" />
      )}
    </>
  );
}
