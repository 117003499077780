import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { DataGrid } from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import CustomDataGridToolBar from "components/shared/CustomDataGridToolBar";
import { Add, Edit } from "@mui/icons-material";
import { IconButton, Tooltip, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { esES } from "@mui/x-data-grid/locales";
import AddIcon from "@mui/icons-material/Add"; // Importa el icono de añadir

function ServiciosList() {
  const api_key = localStorage.getItem("access_token") || "";
  const [filterModel, setFilterModel] = useState({
    items: [],
    quickFilterExcludeHiddenColumns: true,
    quickFilterValues: [""],
  });
  const columnVisibilityModel = {
    id: false,
  };
  const [searchValue, setSearchValue] = useState("");
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const columns = [
    {
      field: "id",
      type: "string",
      headerName: "",
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 30,
      sortable: true,
    },
    {
      field: "title",
      type: "string",
      headerName: "TITULO",
      flex: 1,
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      sortable: true,
    },
    {
      field: "subtitle",
      type: "string",
      headerName: "SUBTITULO",
      flex: 1,
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      sortable: true,
    },
    {
      field: "description",
      type: "string",
      headerName: "DESCRIPCIÓN",
      flex: 1,
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      sortable: true,
      renderCell: (params) => (
        <div
          dangerouslySetInnerHTML={{
            __html: params.value,
          }}
        />
      ),
    },
    {
      field: "active",
      type: "string",
      headerName: "ACTIVO",
      flex: 1,
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      sortable: false,
      renderCell: (params) => (
        <>
          {params.value ? (
            <Chip label="ACTIVO" color="success" variant="outlined" />
          ) : (
            <Chip label="INACTIVO" color="error" variant="outlined" />
          )}
        </>
      ),
    },
    {
      field: "key",
      type: "string",
      headerName: "ACCIONES",
      flex: 1,
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      sortable: false,
      renderCell: (params) => (
        <Box>
          <Box>
            <Tooltip title="Editar Servicio">
              <IconButton>
                <Link to={`/admin/Servicios/${params.value}`}>
                  <Edit />
                </Link>
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/services/list/1`, {
      method: "GET",
      headers: {
        authToken: api_key,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setRows(
          data.response.data
            .sort((a, b) => {
              const nameA = a.pos;
              const nameB = b.pos;
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            })
            .map((item) => ({
              id: item.id,
              key: item.id,
              active: item.active,
              title: item.title,
              subtitle: item.subtitle,
              description: item.description,
            }))
        );
      })
      .catch((error) => console.error("Error fetching data: ", error));
  }, []);

  useEffect(() => {
    setFilteredRows(rows);
  }, [rows]);

  useEffect(() => {
    setFilteredRows(
      rows.filter((row) =>
        Object.values(row).some((val) =>
          val.toString().toLowerCase().includes(searchValue.toLowerCase())
        )
      )
    );
  }, [searchValue]);

  const onSearchValueChange = (event) => {
    setSearchValue(event.target.value);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container>
                  <Grid item xs={10}>
                    <MDTypography variant="h6" color="white">
                      Resultados ({filteredRows.length})
                    </MDTypography>
                  </Grid>
                  <Grid item xs={2} textAlign={"right"}>
                    <a
                      href="/admin/Servicios/create"
                      title="AÑADIR"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <IconButton color="inherit" size="large" component="span">
                        <AddIcon />
                      </IconButton>
                    </a>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <DataGrid
                  rows={filteredRows}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 15 },
                    },
                  }}
                  slots={{ toolbar: CustomDataGridToolBar }}
                  slotProps={{ toolbar: { searchValue, onSearchValueChange } }}
                  filterModel={filterModel}
                  onFilterModelChange={(newModel) => setFilterModel(newModel)}
                  columnVisibilityModel={columnVisibilityModel}
                  sx={{ height: "Calc(100vh - 300px)", minHeight: 400 }}
                  pageSizeOptions={[5, 10, 15]}
                  checkboxSelection={false}
                  disableDensitySelector
                  disableColumnMenu
                  disableColumnSelector
                  disableRowSelectionOnClick
                  localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ServiciosList;
