import PropTypes from "prop-types";
import React, { useState, createContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export const TraduccionesContext = createContext({});

export const TraduccionesContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [idiomas, setIdiomas] = useState([]);
  const [idioma, setIdioma] = useState(null);
  const [traducciones, setTraducciones] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const cargarIdiomas = async () => {
      try {
        setLoading(true);
        fetch(`${process.env.REACT_APP_API_BASE_URL}/Traducciones/getIdiomas`, {
          method: "GET",
        })
          .then((response) => response.json())
          .then((data) => {
            setIdiomas(
              data.map((lang) => ({
                id: +lang.ID,
                label: lang.ACRONIMO_ISO.toUpperCase(),
              }))
            );
            var iso = Cookies.get("lang");
            if (iso) {
              setIdioma(
                data.find((x) => x.ACRONIMO_ISO.toLowerCase() === Cookies.get("lang").toLowerCase())
                  ? +data.find(
                      (x) => x.ACRONIMO_ISO.toLowerCase() === Cookies.get("lang").toLowerCase()
                    ).ID
                  : 1
              );
            } else {
              setIdioma(1);
            }
            setLoading(false);
          })
          .catch((error) => console.error("Error fetching data: ", error));
      } catch (error) {
        console.error("Error al cargar los datos:", error);
        setLoading(false);
      }
    };

    cargarIdiomas();
  }, []);

  useEffect(() => {
    const cargarTraducciones = async () => {
      try {
        setLoading(true);
        fetch(
          `${process.env.REACT_APP_API_BASE_URL}/Traducciones/getPagesTranslations?lang=${idioma}`,
          {
            method: "GET",
          }
        )
          .then((response) => response.json())
          .then((data) => {
            setTraducciones(data);
          })
          .catch((error) => console.error("Error fetching data: ", error));
      } catch (error) {
        console.error("Error al cargar los datos:", error);
        setLoading(false);
      }
    };
    if (!pathname.includes("authentication") && !pathname.includes("admin")) {
      if (idioma) {
        const selectedLang = idiomas.find((lang) => lang.id === Number(idioma));
        const pathSegments = pathname.split("/").filter(Boolean);
        cargarTraducciones();
        if (!pathSegments.includes(selectedLang.label.toLowerCase())) {
          Cookies.set("lang", selectedLang.label.toLowerCase(), {
            expires: 7,
          });
          // navigate(`/${selectedLang.label.toLowerCase()}/`);
        }
      }
    }
  }, [idioma]);

  return (
    <TraduccionesContext.Provider
      value={{
        loading,
        setLoading,
        idiomas,
        setIdiomas,
        idioma,
        setIdioma,
        traducciones,
      }}
    >
      {children}
    </TraduccionesContext.Provider>
  );
};

TraduccionesContextProvider.propTypes = {
  children: PropTypes.any,
};
