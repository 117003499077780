import React, { useState } from "react";
import styled from "styled-components";
import { sortableContainer, sortableElement, sortableHandle } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import BaseTable, { Column } from "react-base-table";
import "react-base-table/styles.css";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const Handle = styled.div`
  cursor: grab;
  margin-right: 10px;
  background-color: #ccc;
  width: 20px;
  height: 20px;
  border-radius: 4px;
`;

const DragHandle = sortableHandle(() => <Handle />);

const SortableItem = sortableElement(({ value }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      padding: "10px",
      borderBottom: "1px solid #ccc",
    }}
  >
    <DragHandle />
    {value}
  </div>
));

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

function DraggableTable() {
  // Define las columnas de tu tabla aquí
  const columns = [
    { title: "Columna 1", key: "col1", width: 100 },
    { title: "Columna 2", key: "col2", width: 100 },
    { title: "Columna 3", key: "col3", width: 100 },
    // Añade más columnas según sea necesario
  ];

  // Define los datos de tu tabla aquí
  const data = Array.from({ length: 10 }, (_, index) => ({
    id: index + 1, // Asegúrate de que cada fila tenga un ID único
    // key: "col" + (index + 1),
    col1: `Dato ${index + 1} - Col 1`,
    col2: `Dato ${index + 1} - Col 2`,
    col3: `Dato ${index + 1} - Col 3`,
    // Añade más campos según corresponda con tus columnas
  }));
  const [items, setItems] = useState(["Item 1", "Item 2", "Item 3", "Item 4"]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems(arrayMoveImmutable(items, oldIndex, newIndex));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Resultados ({data.length})
                </MDTypography>
              </MDBox>
              <BaseTable data={data} width={1200} height={400} columns={columns}></BaseTable>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default DraggableTable;
