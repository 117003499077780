/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Formik } from "formik";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  DialogActions,
  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
} from "@mui/material";
import { RootDialog } from "components/RootDialog/RootDialog";

function KeysCreateDialog({ mode, open, setOpen, formState, setFormState, setSubmitForm }) {
  const handleClose = () => {
    setOpen(false);
  };

  const handleCancelForm = () => {
    handleClose();
  };

  return (
    <>
      <RootDialog
        maxWidth="sm"
        fullWidth
        onClose={handleClose}
        aria-labelledby="compatible-mfa-applications-modal"
        open={open}
      >
        <Formik
          initialValues={{
            title: formState.title || "",
            subtitle: formState.subtitle || "",
            pos: formState.pos || "",
          }}
          validationSchema={Yup.object().shape({
            title: Yup.string().max(255).required("Titulo es obligatorio"),
            subtitle: Yup.string().max(255).required("Subtitulo es obligatorio"),
            pos: Yup.string().max(255).required("Posición es obligatorio"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            setSubmitting(true);
            if (mode === "create") {
              setFormState({
                ...values,
                id: formState.id ? formState.id : `${Date.now()}-marker`,
              });
            } else {
              setFormState({
                ...values,
                id: formState.id || 0,
              });
            }
            setSubmitForm(true);
            handleClose();
            setSubmitting(false);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            submitForm,
            setFieldValue,
            touched,
            values,
          }) => (
            <>
              <DialogTitle sx={{ m: 0, p: 2, paddingBottom: "0 !important" }} variant="h6">
                Nueva Clave
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: "#CCC",
                }}
              >
                <CloseIcon />
              </IconButton>
              <DialogContent>
                <Grid container>
                  <form noValidate onSubmit={handleSubmit} style={{ width: "100%" }}>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.pos && errors.pos)}
                      sx={{ marginBottom: "1rem" }}
                    >
                      <OutlinedInput
                        key={`input-pos`}
                        notched={false}
                        size="small"
                        type="number"
                        value={values.pos}
                        name="pos"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label={`Posición`}
                        placeholder={`Posición`}
                        inputProps={{}}
                      />
                      {touched.pos && errors.pos && (
                        <FormHelperText key={`error-pos`} error>
                          {errors.pos}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.title && errors.title)}
                      sx={{ marginBottom: "1rem" }}
                    >
                      <OutlinedInput
                        key={`input-title`}
                        notched={false}
                        size="small"
                        type="text"
                        value={values.title}
                        name="title"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label={`Titulo`}
                        placeholder={`Titulo`}
                        inputProps={{}}
                      />
                      {touched.title && errors.title && (
                        <FormHelperText key={`error-title`} error>
                          {errors.title}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.subtitle && errors.subtitle)}
                      sx={{ marginBottom: "1rem" }}
                    >
                      <OutlinedInput
                        key={`input-subtitle`}
                        notched={false}
                        size="small"
                        type="text"
                        value={values.subtitle}
                        name="subtitle"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label={`Subtitulo`}
                        placeholder={`Subtitulo`}
                        inputProps={{}}
                      />
                      {touched.subtitle && errors.subtitle && (
                        <FormHelperText key={`error-subtitle`} error>
                          {errors.subtitle}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </form>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCancelForm} variant="outlined" sx={{ color: "#000" }}>
                  CANCELAR
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={submitForm}
                  disabled={isSubmitting}
                  sx={{ color: "#FFF" }}
                >
                  GUARDAR
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
      </RootDialog>
    </>
  );
}

KeysCreateDialog.propTypes = {
  mode: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  formState: PropTypes.object.isRequired,
  setFormState: PropTypes.func.isRequired,
  setSubmitForm: PropTypes.func.isRequired,
};

export default KeysCreateDialog;
