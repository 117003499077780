import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { BASE_URL, fetchSeoData, saveSeoData, getLanguages } from "./Data";
import { ToastContainer, toast, Bounce } from "react-toastify";
import PropTypes from "prop-types";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

function EditSeo() {
  const CustomToastContent = ({ title, subtitle }) => (
    <div>
      <strong style={{ fontSize: "16px" }}>{title}</strong>
      {subtitle && <p style={{ fontSize: "14px" }}>{subtitle}</p>}
    </div>
  );
  CustomToastContent.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
  };
  CustomToastContent.defaultProps = {
    title: "¡Guardado correctamente!",
    subtitle: "",
  };

  const msgOk = (title, subtitle) =>
    toast.success(<CustomToastContent title={title} subtitle={subtitle} />, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });

  const msgError = (title, subtitle) =>
    toast.error(<CustomToastContent title={title} subtitle={subtitle} />, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });
  const api_key = localStorage.getItem("access_token") || "";

  const { id } = useParams(); // Obtener el ID desde la URL
  const navigate = useNavigate(); // Hook para navegar programáticamente
  const [seoData, setSeoData] = useState({
    SEO_PAGE_ID: 0,
    NOMBRE: "",
    TEXT: "",
    SEO_TITLE: "",
    SEO_METAKEYRORD: "",
    SEO_METADESCRIPTION: "",
    SEO_URL_FRIENDLY: "",
    LANGUAGE_ID: 1,
  });

  const inputToStateKeyMap = {
    txtKey: "NOMBRE",
    txtTitle: "SEO_TITLE",
    txtUrl: "SEO_URL_FRIENDLY",
    txtMetaWords: "SEO_METAKEYRORD",
    txtMetaDesc: "SEO_METADESCRIPTION",
  };

  const [idiomas, setIdiomas] = useState([]);
  const [idiomaSeleccionado, setIdiomaSeleccionado] = useState(null);

  // Cargar los datos existentes
  useEffect(() => {
    const cargarIdiomas = async () => {
      try {
        const datosIdiomas = await getLanguages();
        setIdiomas(datosIdiomas);
        const idiomaPorDefecto = datosIdiomas.find((idioma) => idioma.ID == 1);
        // Evita establecer el idioma por defecto aquí si ya tienes uno seleccionado
        if (idiomaPorDefecto && !idiomaSeleccionado) {
          setIdiomaSeleccionado(idiomaPorDefecto);
        }
      } catch (error) {
        console.error("Error al cargar los idiomas:", error);
      }
    };

    cargarIdiomas();
  }, []);

  useEffect(() => {
    const loadData = async () => {
      try {
        if (!idiomaSeleccionado) return;
        const data = await fetchSeoData(id, idiomaSeleccionado.ID);
        if (data && data.length > 0) {
          // Asume que el backend devuelve un array y el elemento que necesitas está en la primera posición
          setSeoData({
            SEO_PAGE_ID: data[0].SEO_PAGE_ID,
            NOMBRE: data[0].NOMBRE,
            SEO_TITLE: data[0].SEO_TITLE,
            SEO_URL_FRIENDLY: data[0].SEO_URL_FRIENDLY,
            SEO_METAKEYRORD: data[0].SEO_METAKEYRORD,
            SEO_METADESCRIPTION: data[0].SEO_METADESCRIPTION,
            TEXT: "",
            LANGUAGE_ID: data[0].LANGUAGE_ID,
          });
        } else {
          msgError("No se encontraron datos para el ID proporcionado");
        }
      } catch (error) {
        msgError("Error al obtener datos de SEO:", error);
      }
    };
    loadData();
  }, [id, idiomaSeleccionado]);

  // Manejar los cambios en los inputs
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    const stateKey = inputToStateKeyMap[id] || id; // Usa el mapeo, o el id directamente si no hay mapeo
    setSeoData((prevState) => ({
      ...prevState,
      [stateKey]: value,
    }));
  };
  const handleIdiomaChange = (event, newValue) => {
    // newValue es el objeto de la opción seleccionada
    setIdiomaSeleccionado(newValue); // Guarda el idioma seleccionado si necesitas usarlo en otro lugar
  };

  // Manejar el envío del formulario
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(BASE_URL, {
        method: "POST", // O "PUT", según la configuración de tu backend
        headers: {
          "Content-Type": "application/json",
          authToken: api_key,
        },
        body: JSON.stringify({
          ...seoData,
        }),
      });

      if (response.ok) {
        // Si la solicitud fue exitosa
        msgOk();
        // navigate("/donde-quiera-redirigir"); // Por ejemplo, a la página de confirmación o al listado
      } else {
        // Si hubo un problema con la solicitud
        const errorData = await response.json(); // Suponiendo que el servidor responde con detalles del error en JSON
        msgError(`Error al guardar: ${errorData.message}`);
        // Aquí podrías establecer algún estado para mostrar el error en la UI en lugar de un alert
      }
    } catch (error) {
      // Si hubo un error en la red o algo impidió completar la solicitud
      console.error("Error al hacer la solicitud:", error);
      msgError("Error al intentar guardar los datos.");
      // Similar al caso de error anterior, podrías manejar este error en la UI
    }
  };

  return (
    <DashboardLayout>
      <ToastContainer />
      <DashboardNavbar title={`Edición de ${seoData.NOMBRE}`} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Edición de {seoData.NOMBRE}
                </MDTypography>
              </MDBox>
              <MDBox py={3} px={3}>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={6}>
                    {/* Campos del formulario */}
                    <Grid item xs={6}>
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <MDInput
                            type="text"
                            label="Clave SEO"
                            id="txtKey"
                            variant="standard"
                            fullWidth
                            value={seoData.NOMBRE}
                            onChange={handleInputChange}
                            disabled={true}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <MDInput
                            type="text"
                            label="Título de la página"
                            id="txtTitle"
                            variant="standard"
                            fullWidth
                            value={seoData.SEO_TITLE}
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <MDInput
                            type="text"
                            label="URL amigable"
                            id="txtUrl"
                            variant="standard"
                            fullWidth
                            value={seoData.SEO_URL_FRIENDLY}
                            onChange={handleInputChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container spacing={4}>
                        <Grid item xs={8}>
                          <MDInput
                            type="text"
                            label="Meta palabras clave"
                            id="txtMetaWords"
                            variant="standard"
                            fullWidth
                            value={seoData.SEO_METAKEYRORD}
                            onChange={handleInputChange}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <Autocomplete
                            id="ddLanguages"
                            disableClearable
                            options={idiomas}
                            value={idiomaSeleccionado}
                            getOptionLabel={(option) => option.NOMBRE} // Asumiendo que cada idioma tiene una propiedad 'nombre'
                            renderInput={(params) => (
                              <TextField {...params} label="Idioma" variant="standard" />
                            )}
                            onChange={handleIdiomaChange} // Maneja el cambio de selección
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <MDInput
                            type="text"
                            label="Meta descripción"
                            id="txtMetaDesc"
                            variant="standard"
                            fullWidth
                            value={seoData.SEO_METADESCRIPTION}
                            onChange={handleInputChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <MDBox display="flex" justifyContent="flex-end">
                        <MDButton type="submit" variant="gradient" color="primary" size="medium">
                          GUARDAR DATOS
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                </form>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditSeo;
