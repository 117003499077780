/**
 * Retrieves the list of languages from the API.
 *
 * @return {Promise<Array<Object>>} An array of objects representing the languages.
 * @throws {Error} If there is an error while fetching the data.
 */
export const getLanguages = async () => {
  try {
    const api_key = localStorage.getItem("access_token") || "";
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/traducciones/getIdiomas`, {
      method: "GET",
      headers: {
        authToken: api_key,
      },
    });
    if (!response.ok) {
      throw new Error("Error al obtener los datos de idiomas");
    }
    return await response.json();
  } catch (error) {
    console.error("Error al obtener datos de idiomas:", error);
    throw error; // Propagar el error para manejarlo en el componente.
  }
};
