import * as React from "react";
import { useContext, useEffect } from "react"; // Make sure useContext is imported

import { useLocation, Link, useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ALTHLogo from "../../assets/images/ALTHLogo.svg";
import Cookies from "js-cookie";
import { Container } from "@mui/material";
import SeoRoutesContext from "../shared/SeoRoutesContext"; // Import the custom hook
import { TraduccionesContext } from "../shared/TraduccionesContext";
import CookiePanel from "../cookiePanel/index"; // Importa el componente desde el archivo index.js

const drawerWidth = 240;
const navItems = [
  { label: "INICIO", key: "home" },
  { label: "SERVICIOS", key: "servicios" },
  { label: "LA EMPRESA", key: "empresa" },
  { label: "NOTICIAS", key: "noticias" },
  { label: "CONTACTO", key: "contacto" },
];

function HeaderHome(props) {
  const { window, keySelected } = props; // Destructuración aquí
  const traducciones = useContext(TraduccionesContext);
  const [seoParams, setSeoParams] = React.useState(null);
  const { idioma } = useContext(TraduccionesContext);

  useEffect(() => {
    if (!location.pathname.endsWith("/")) {
      //navigate(location.pathname + "/");
      let a = "";
    }
    if (idioma) {
      const seoParamsMapped = seoRoutes.find(
        (x) =>
          x.SEO_PAGE_ID === "1" &&
          x.ACRONIMO_ISO.toLowerCase() ===
            traducciones.idiomas.find((x) => x.id === traducciones.idioma).label.toLowerCase()
      );
      setSeoParams({
        title: seoParamsMapped.SEO_TITLE || "ALTH LOAN",
        keyword: seoParamsMapped.SEO_METAKEYRORD || "",
        description: seoParamsMapped.SEO_METADESCRIPTION || "",
      });
    }
  }, [idioma]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleOpenLangSelector = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (itemId) => {
    if (!isNaN(itemId)) {
      traducciones.setIdioma(itemId);
      Cookies.set("lang", traducciones.idiomas.find((x) => x.id === itemId).label.toLowerCase(), {
        expires: 7,
      });
      navigate(`/${traducciones.idiomas.find((lang) => lang.id === itemId).label.toLowerCase()}/`);
    }
    setAnchorEl(null);
  };

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const seoRoutes = useContext(SeoRoutesContext); // Correct use of useContext

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const navigateTo = (routeKey, seoRoutes, lang) => {
    let path = "";
    const seoEntry = seoRoutes.find(
      (seo) =>
        seo.PAGE.replaceAll(".aspx", "").toLowerCase() === routeKey.toLowerCase() &&
        seo.ACRONIMO_ISO === lang
    );
    if (seoEntry) {
      path = `/${seoEntry.ACRONIMO_ISO}/${seoEntry.SEO_URL_FRIENDLY}`;
    } else {
      path = routeKey;
    }
    path = path.replaceAll("//", "/");
    path = path + "/";
    path = path.replaceAll("//", "/");
    return path;
  };

  const handleNavigation = (key) => {
    const pathSegments = location.pathname.split("/").filter(Boolean);
    const lang = pathSegments[0] || "es"; // Asumir "es" como idioma por defecto si no se encuentra ninguno
    Cookies.set("lang", lang, { expires: 7 });
    const path = navigateTo(key, seoRoutes, lang);
    return path; // Not typically needed unless you have other uses for path after navigation
  };

  const drawer = (handleNav) => (
    <Box sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        ALTH Loan Services
      </Typography>
      <Divider />
      <Button
        sx={{
          marginX: "1rem",
          padding: "0",
        }}
        fullWidth
      >
        <Typography
          sx={{
            color: "#222",
            fontWeight: "bold",
            cursor: "pointer",
            fontSize: "16px",
            display: "flex",
            width: "100%",
          }}
          onClick={handleOpenLangSelector}
        >
          <Box marginRight=".2rem">
            <KeyboardArrowDownIcon />
          </Box>
          <Box>
            {traducciones.idiomas.length > 0
              ? traducciones.idiomas.find((x) => x.id === traducciones.idioma).label
              : "ES"}
          </Box>
        </Typography>
      </Button>
      <List sx={{ paddingLeft: "1rem" }}>
        {navItems.map((item) => (
          <ListItem key={item.label} disablePadding>
            <Link to={handleNavigation(item.key)} style={{ textDecoration: "none" }}>
              {/* <Link to={handleNavigation(item.key)} style={{ textDecoration: "none" }}> */}
              <ListItemText
                primary={traducciones.traducciones && traducciones.traducciones.MASTER[item.key]}
                sx={{ textAlign: "center", color: "black" }}
              />
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  const validateSelected = (key) => {
    if (key == keySelected) {
      return "2px solid #f7f7f7";
    } else return "";
  };
  return (
    <>
      {seoParams && (
        <Helmet>
          <title>{seoParams.title}</title>
          <meta name="description" content={seoParams.description} />
          <meta name="keywords" content={seoParams.keyword} />
        </Helmet>
      )}
      <Box>
        <CookiePanel />
        <CssBaseline />
        <AppBar component="nav" position="static">
          <Toolbar sx={{ padding: "0 !important" }}>
            <Container
              sx={{
                display: "flex",
                maxWidth: {
                  xl: "1320px!important", // max-width 1320px para xl
                  lg: "1140px!important", // max-width 1140px para lg
                  md: "100%!important", // max-width 100% para md
                  sm: "100%!important", // max-width 100% para sm
                  xs: "100%!important", // max-width 100% para xs
                },
              }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, color: "white !important", display: { md: "none" } }}
              >
                <MenuIcon sx={{ color: "white" }} />
              </IconButton>
              <Link
                to={handleNavigation("home")}
                style={{
                  flexGrow: 1,
                  display: { xs: "none", sm: "block" },
                  marginTop: "16px",
                }}
              >
                <img src={ALTHLogo} alt="alth logo" width="94px" height="35px" />
              </Link>
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  alignItems: "center",
                  gap: {
                    xs: "15px",
                    lg: "35px", // max-width 1140px para lg
                  },
                  marginTop: "16px",
                }}
              >
                {navItems.map((item) => (
                  <Link to={handleNavigation(item.key)} key={`item-${item.label}`}>
                    <Typography
                      sx={{
                        color: "#f7f7f7",
                        paddingBottom: "4px",
                        fontWeight: "bold",
                        fontSize: "12px",
                        cursor: "pointer",
                        borderBottom: validateSelected(item.key),
                        height: "24px",
                        "&:hover": {
                          borderBottom: "2px solid #f7f7f7",
                        },
                      }}
                    >
                      {traducciones.traducciones && traducciones.traducciones.MASTER[item.key]}
                    </Typography>
                  </Link>
                ))}
                <Link
                  to={
                    traducciones.traducciones
                      ? traducciones.traducciones.MASTER.area_cliente_link
                      : ""
                  }
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      width: "auto",
                      height: "32px !important",
                      minHeight: "0",
                      padding: "0 12px",
                      paddingTop: "0px",
                      marginTop: "-5px",
                      border: "1px solid #fff !important",
                      borderRadius: "3px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#f7f7f7",
                        fontWeight: "bold",
                        cursor: "pointer",
                        fontSize: "12px",
                      }}
                    >
                      {traducciones.traducciones && traducciones.traducciones.MASTER.area_cliente}
                    </Typography>
                  </Button>
                </Link>
                <Button
                  sx={{
                    padding: "0px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#f7f7f7",
                      fontWeight: "bold",
                      cursor: "pointer",
                      fontSize: "12px",
                      display: "flex",
                    }}
                    onClick={handleOpenLangSelector}
                  >
                    <Box marginRight=".2rem">
                      <KeyboardArrowDownIcon />
                    </Box>
                    <Box>
                      {traducciones.idiomas.length > 0
                        ? traducciones.idiomas.find((x) => x.id === traducciones.idioma).label
                        : "ES"}
                    </Box>
                  </Typography>
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {traducciones.idiomas.map((item) => (
                    <MenuItem
                      key={`lang-${item.id}`}
                      onClick={() => {
                        handleClose(item.id);
                      }}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Container>
          </Toolbar>
        </AppBar>
        <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{ keepMounted: true }}
            sx={{
              display: { xs: "block", md: "none" },
              "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
            }}
          >
            {drawer(handleNavigation)}
          </Drawer>
        </nav>
      </Box>
    </>
  );
}

HeaderHome.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
  keySelected: PropTypes.string,
};

export default HeaderHome;
