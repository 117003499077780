/**
 * Returns the URL of an image given its filename and folder.
 *
 * @param {string} filename - The name of the image file.
 * @param {string} folder - The folder where the image is located.
 * @return {string} The URL of the image.
 */
export const getImageUrl = (filename, folder) => {
  return `${process.env.REACT_APP_API_BASE_URL}/files/${folder}/${filename}`.replaceAll("api/", "");
};
