import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { BASE_URL, fetchitem, saveData, getLanguages } from "./Data";
import { ToastContainer, toast, Bounce } from "react-toastify";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Importar los estilos para react-quill
import "../../../assets/css/masterAdmin.css";

function EditPaginasLegales() {
  const [editMode, setEditMode] = useState(false); // true para WYSIWYG, false para HTML crudo
  const [htmlContent, setHtmlContent] = useState(""); // Para almacenar y editar HTML crudo
  const [idiomas, setIdiomas] = useState([]);
  const [idiomaSeleccionado, setIdiomaSeleccionado] = useState(null);
  const CustomToastContent = ({ title, subtitle }) => (
    <div>
      <strong style={{ fontSize: "16px" }}>{title}</strong>
      {subtitle && <p style={{ fontSize: "14px" }}>{subtitle}</p>}
    </div>
  );
  CustomToastContent.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
  };
  CustomToastContent.defaultProps = {
    title: "¡Guardado correctamente!",
    subtitle: "",
  };

  const msgOk = (title, subtitle) =>
    toast.success(<CustomToastContent title={title} subtitle={subtitle} />, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      cloLegalPagesnClick: true,
      pauLegalPagesnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });

  const msgError = (title, subtitle) =>
    toast.error(<CustomToastContent title={title} subtitle={subtitle} />, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      cloLegalPagesnClick: true,
      pauLegalPagesnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });
  const api_key = localStorage.getItem("access_token") || "";

  const { id } = useParams(); // Obtener el ID desde la URL
  const navigate = useNavigate(); // Hook para navegar programáticamente
  const [itemEdit, setitem] = useState({
    ID_TITILE: 0,
    TRADUCCION_TITILE: 0,
    ID_TEXT: "",
    TRADUCCION_TEXT: "",
    PAGINA_ASPX: "",
    LANGUAGE_ID: 1,
  });

  const inputToStateKeyMap = {
    txtPagina: "PAGINA_ASPX",
    txtTITULO: "TRADUCCION_TITILE",
    txtTexto: "TRADUCCION_TEXT",
  };

  const titleNav = id == 0 ? "registro" : itemEdit.PAGINA_ASPX;

  // Cargar los datos existentes
  useEffect(() => {
    const cargarIdiomas = async () => {
      try {
        const datosIdiomas = await getLanguages();
        setIdiomas(datosIdiomas);
        const idiomaPorDefecto = datosIdiomas.find((idioma) => idioma.ID == 1);
        // Evita establecer el idioma por defecto aquí si ya tienes uno seleccionado
        if (idiomaPorDefecto && !idiomaSeleccionado) {
          setIdiomaSeleccionado(idiomaPorDefecto);
        }
      } catch (error) {
        console.error("Error al cargar los idiomas:", error);
      }
    };

    cargarIdiomas();
  }, []);
  useEffect(() => {
    const loadData = async () => {
      try {
        if (id == 0) {
          navigate("/PaginasLegales"); // Redirecciona
          return;
        }
        if (!idiomaSeleccionado) return;
        const data = await fetchitem(id, idiomaSeleccionado.ID);
        if (data && data.length > 0) {
          // Asume que el backend devuelve un array y el elemento que necesitas está en la primera posición
          setitem({
            ID_TITILE: data[0].ID_TITILE,
            TRADUCCION_TITILE: data[0].TRADUCCION_TITILE,
            ID_TEXT: data[0].ID_TEXT,
            TRADUCCION_TEXT: data[0].TRADUCCION_TEXT,
            PAGINA_ASPX: data[0].PAGINA_ASPX,
            LANGUAGE_ID: data[0].LANGUAGE_ID,
          });
          setHtmlContent(data[0].TRADUCCION_TEXT);
        } else {
          msgError("No se encontraron datos para el ID proporcionado");
        }
      } catch (error) {
        msgError("Error al obtener datos de LegalPages:", error);
      }
    };
    loadData();
  }, [id, idiomaSeleccionado]);

  // Manejar los cambios en los inputs
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    const stateKey = inputToStateKeyMap[id] || id; // Usa el mapeo, o el id directamente si no hay mapeo
    setitem((prevState) => ({
      ...prevState,
      [stateKey]: value,
    }));
  };

  // Manejar los cambios en handleQuillChange
  const handleQuillChange = (value) => {
    // Aquí, `value` es el contenido actual del editor en HTML
    setitem((prevState) => ({
      ...prevState,
      TRADUCCION_TEXT: value, // Asume que quieres actualizar una propiedad llamada `texto` en tu estado
    }));
  };
  const toggleEditMode = () => {
    setEditMode(!editMode);
    if (!editMode) {
      const escapedHtmlContent = htmlContent
        .replace(/<p><table([^>]*)>/g, "&lt;table$1&gt;") // handle <p><table> case
        .replace(/<\/table><\/p>/g, "&lt;/table&gt;") // handle </table></p> case
        .replace(/<table([^>]*)>/g, "&lt;table$1&gt;")
        .replace(/<\/table>/g, "&lt;/table&gt;")
        .replace(/<thead([^>]*)>/g, "&lt;thead$1&gt;")
        .replace(/<\/thead>/g, "&lt;/thead&gt;")
        .replace(/<tbody([^>]*)>/g, "&lt;tbody$1&gt;")
        .replace(/<\/tbody>/g, "&lt;/tbody&gt;")
        .replace(/<tr([^>]*)>/g, "&lt;tr$1&gt;")
        .replace(/<\/tr>/g, "&lt;/tr&gt;")
        .replace(/<td([^>]*)>/g, "&lt;td$1&gt;")
        .replace(/<\/td>/g, "&lt;/td&gt;")
        .replace(/<th([^>]*)>/g, "&lt;th$1&gt;")
        .replace(/<\/th>/g, "&lt;/th&gt;");
      setitem({ ...itemEdit, TRADUCCION_TEXT: escapedHtmlContent });
    } else {
      const unescapedHtmlContent = itemEdit.TRADUCCION_TEXT.replace(
        /&lt;table([^>]*)&gt;/g,
        "<table$1>"
      )
        .replace(/&lt;\/table&gt;/g, "</table>")
        .replace(/&lt;thead([^>]*)&gt;/g, "<thead$1>")
        .replace(/&lt;\/thead&gt;/g, "</thead>")
        .replace(/&lt;tbody([^>]*)&gt;/g, "<tbody$1>")
        .replace(/&lt;\/tbody&gt;/g, "</tbody>")
        .replace(/&lt;tr([^>]*)&gt;/g, "<tr$1>")
        .replace(/&lt;\/tr&gt;/g, "</tr>")
        .replace(/&lt;td([^>]*)&gt;/g, "<td$1>")
        .replace(/&lt;\/td&gt;/g, "</td>")
        .replace(/&lt;th([^>]*)&gt;/g, "<th$1>")
        .replace(/&lt;\/th&gt;/g, "</th>")
        .replace(/&lt;/g, "<") // handle remaining escaped less-than signs
        .replace(/&gt;/g, ">") // handle remaining escaped greater-than signs
        .replace(/<q2b>/g, "")
        .replace(/<\/q2b>/g, "");
      setHtmlContent(unescapedHtmlContent);
    }
  };

  const handleHtmlContentChange = (e) => {
    setHtmlContent(e.target.value);
    setitem({ ...itemEdit, TRADUCCION_TEXT: e.target.value });
  };

  const handleIdiomaChange = (event, newValue) => {
    // newValue es el objeto de la opción seleccionada
    setIdiomaSeleccionado(newValue); // Guarda el idioma seleccionado si necesitas usarlo en otro lugar
  };

  // Manejar el envío del formulario
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await saveData(itemEdit);

      if (response.ok) {
        const msgResponse = await response.json();
        // Si la solicitud fue exitosa
        if (msgResponse.error != "") {
          msgError(msgResponse.error);
        } else {
          msgOk();
        }
        // navigate("/donde-quiera-redirigir"); // Por ejemplo, a la página de confirmación o al listado
      } else {
        // Si hubo un problema con la solicitud
        const errorData = await response.json(); // Suponiendo que el servidor responde con detalles del error en JSON
        msgError(`Error al guardar: ${errorData.message}`);
        // Aquí podrías establecer algún estado para mostrar el error en la UI en lugar de un alert
      }
    } catch (error) {
      // Si hubo un error en la red o algo impidió completar la solicitud
      console.error("Error al hacer la solicitud:", error);
      msgError("Error al intentar guardar los datos.");
      // Similar al caso de error anterior, podrías manejar este error en la UI
    }
  };

  const modules = {
    clipboard: {
      matchVisual: false, // Permite a Quill conservar el formato HTML al pegar
    },

    toolbar: [
      // Configuración de tu barra de herramientas aquí
      ["bold", "italic", "underline", "strike"], // Estilos de texto enriquecido
      ["blockquote"], // Bloques de cita y código

      [{ header: 1 }, { header: 2 }], // Títulos
      [{ list: "ordered" }, { list: "bullet" }], // Listas
      [{ script: "sub" }, { script: "super" }], // Subíndice/Superíndice
      [{ indent: "-1" }, { indent: "+1" }], // Sangrías
      [{ direction: "rtl" }], // Texto de derecha a izquierda

      [{ size: ["small", false, "large", "huge"] }], // Tamaños de fuente
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // Selector de color
      [{ font: [] }], // Fuente
      [{ align: [] }], // Alineación

      ["link", "image", "video"], // Insertar enlaces, imágenes, videos

      ["clean"], // Limpiar formato
    ],
  };
  return (
    <DashboardLayout>
      <ToastContainer />
      <DashboardNavbar title={`Edición de ${titleNav}`} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Edición del registro
                </MDTypography>
              </MDBox>
              <MDBox py={3} px={3}>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={6}>
                    {/* Campos del formulario */}
                    <Grid item xs={12}>
                      <Grid container spacing={4}>
                        <Grid item xs={4}>
                          <MDInput
                            type="text"
                            label="Página"
                            id="txtPagina"
                            variant="standard"
                            fullWidth
                            value={itemEdit.PAGINA_ASPX}
                            disabled={true}
                          />
                        </Grid>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4}>
                          <Autocomplete
                            id="ddLanguages"
                            disableClearable
                            options={idiomas}
                            value={idiomaSeleccionado}
                            getOptionLabel={(option) => option.NOMBRE} // Asumiendo que cada idioma tiene una propiedad 'nombre'
                            renderInput={(params) => (
                              <TextField {...params} label="Idioma" variant="standard" />
                            )}
                            onChange={handleIdiomaChange} // Maneja el cambio de selección
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <MDInput
                            type="text"
                            label="TITULO"
                            id="txtTITULO"
                            variant="standard"
                            fullWidth
                            value={itemEdit.TRADUCCION_TITILE}
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <FormControlLabel
                            control={<Switch checked={editMode} onChange={toggleEditMode} />}
                            label="Editar como HTML web"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          {editMode ? (
                            <ReactQuill
                              modules={modules}
                              label="Texto"
                              id="txtTexto"
                              value={itemEdit.TRADUCCION_TEXT}
                              onChange={handleQuillChange}
                              theme="snow" // Usa el tema "snow", puedes cambiarlo por "bubble" si prefieres
                              className="react-quill-custom" // Aplica la clase aquí
                            />
                          ) : (
                            <textarea
                              style={{ width: "100%", height: "300px" }}
                              value={htmlContent}
                              onChange={handleHtmlContentChange}
                            ></textarea>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <MDBox display="flex" justifyContent="flex-end">
                        <MDButton type="submit" variant="gradient" color="primary" size="medium">
                          GUARDAR DATOS
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                </form>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
export default EditPaginasLegales;
