import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useNavigate, Link } from "react-router-dom";
import { Card, CardMedia, CardContent, Typography, Box, Button, Chip } from "@mui/material";
import { TraduccionesContext } from "components/shared/TraduccionesContext";

export const ServiceCard = ({ title, content, url, seoURLFriendly }) => {
  const navigate = useNavigate();
  const { traducciones } = useContext(TraduccionesContext);

  return (
    <Card
      sx={{
        width: {
          xs: "100%",
          sm: "Calc(100% - 30px)",
          md: "Calc(100% - 30px)",
          lg: "Calc(100% - 30px)",
          xl: "520px",
        },
        maxWidth: "520px",
        height: "450px",
        borderRadius: "0",
        boxShadow: "none",
        background:
          "transparent linear-gradient(355deg, rgba(162, 75, 93, 1) 0%, rgba(75, 93, 162, 1) 100%) 0% 0% no-repeat padding-box",
        "& *": { fontFamily: "Nunito !important", color: "#FFF" },
      }}
    >
      <CardContent
        sx={{
          textAlign: "left",
          paddingX: "1rem",
          height: "100%",
          padding: {
            xs: "40px 35px",
            sm: "40px 55px",
          },
          position: "relative",
        }}
      >
        <Typography
          sx={{ fontSize: "29px", lineHeight: "32px", marginBottom: "11px", fontWeight: "bold" }}
          component={"h2"}
        >
          {title}
        </Typography>
        <div
          style={{ fontSize: "16px", maxWidth: "345px", lineHeight: "22px", minHeight: "220px" }}
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
        <Box>
          {seoURLFriendly.trim() !== "" && (
            <Link to={"/" + url}>
              <Button
                sx={{
                  position: "absolute",
                  bottom: "47px",
                  background: "#FFF !important",
                  borderRadius: "80px !important",
                  color: "rgba(75, 93, 162, 1) !important",
                  fontSize: "13px",
                  height: "32px",
                  width: "226px",
                  textTransform: "none",
                  fontWeight: "bold",
                }}
              >
                {traducciones && traducciones.SERVICIOS.card_button_text}
              </Button>
            </Link>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

ServiceCard.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  seoURLFriendly: PropTypes.string,
};
