/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactQuill, { Quill } from "react-quill";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Switch,
  IconButton,
  Tooltip,
  Checkbox,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { NoCropper } from "components/Cropper/NoCropper";
import { UploadImageBanner } from "components/shared/UploadImageBanner";
import SectionsTable from "./components/sections_table";
import KeysTable from "./components/keys_table";
import { toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Autocomplete from "@mui/material/Autocomplete";

function ServicioForm({
  mode,
  general,
  sections,
  setSections,
  keys,
  setKeys,
  description,
  setDescription,
  idiomas,
  idiomaSeleccionado,
  ItemidiomaSeleccionado,
  setIdiomaSeleccionado,
  serviceImageUrl,
  recordId,
  setIdiomaSeleccionadoValue,
}) {
  const navigate = useNavigate();
  const api_key = localStorage.getItem("access_token") || "";
  const [serviceHasImage, setServiceHasImage] = useState(Boolean(serviceImageUrl));
  const [imageHasBeenCropped, setImageHasBeenCropped] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [file, setFile] = useState(null);
  const [rootFile, setRootFile] = useState(null);
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    multiple: false,
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      setRootFile(acceptedFiles[0]);
      setFile({
        ...acceptedFiles[0],
        preview: URL.createObjectURL(acceptedFiles[0]),
      });
    },
  });
  useEffect(() => {
    if (croppedImage) {
      setImageHasBeenCropped(true);
    } else {
      setImageHasBeenCropped(false);
    }
  }, [croppedImage]);
  const [editMode, setEditMode] = useState(true); // true para WYSIWYG, false para HTML crudo
  var Block = Quill.import("blots/block");
  Block.tagName = "q2b";
  Quill.register(Block);
  const modules = {
    toolbar: [
      // Configuración de tu barra de herramientas aquí
      ["bold", "italic", "underline", "strike"], // Estilos de texto enriquecido
      ["blockquote"], // Bloques de cita y código

      [{ header: 1 }, { header: 2 }], // Títulos
      [{ list: "ordered" }, { list: "bullet" }], // Listas
      [{ script: "sub" }, { script: "super" }], // Subíndice/Superíndice
      [{ indent: "-1" }, { indent: "+1" }], // Sangrías
      [{ direction: "rtl" }], // Texto de derecha a izquierda

      [{ size: ["small", false, "large", "huge"] }], // Tamaños de fuente
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // Selector de color
      [{ font: [] }], // Fuente
      [{ align: [] }], // Alineación

      ["clean"], // Limpiar formato
    ],
  };
  // Manejar los cambios en handleQuillChange
  const handleQuillChange = (value) => {
    setDescription(value);
  };
  const toggleEditMode = () => {
    setEditMode(!editMode);
    setDescription(description.replaceAll("</q2b>", "").replaceAll("<q2b>", ""));
    if (editMode) {
      if (!description.includes("</q2b>")) {
        setDescription(`<q2b>${description}</q2b>`);
      }
    }
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  // Messages
  const CustomToastContent = ({ title, subtitle }) => (
    <div>
      <strong style={{ fontSize: "16px" }}>{title}</strong>
      {subtitle && <p style={{ fontSize: "14px" }}>{subtitle}</p>}
    </div>
  );
  CustomToastContent.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
  };
  CustomToastContent.defaultProps = {
    title: "¡Guardado correctamente!",
    subtitle: "",
  };

  const msgOk = (title, subtitle) =>
    toast.success(<CustomToastContent title={title} subtitle={subtitle} />, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });

  const msgError = (title, subtitle) =>
    toast.error(<CustomToastContent title={title} subtitle={subtitle} />, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });

  const handleIdiomaChange = (event, newValue) => {
    // newValue es el objeto de la opción seleccionada
    setIdiomaSeleccionado(newValue); // Guarda el idioma seleccionado si necesitas usarlo en otro lugar
    setIdiomaSeleccionadoValue(newValue.ID);
  };

  return (
    <Formik
      initialValues={{
        title: String(general.title),
        subtitle: String(general.subtitle),
        seoTitle: String(general.seoTitle),
        seoMetakeyword: String(general.seoMetakeyword),
        seoMetadescription: String(general.seoMetadescription),
        seoUrlFriendly: String(general.seoUrlFriendly),
        pos: String(general.pos),
        active: String(general.active),
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().max(255).required("Titulo es obligatorio"),
        subtitle: Yup.string().max(255).required("Subtítulo es obligatorio"),
        seoTitle: Yup.string().max(255).required("Titulo SEO es obligatorio"),
        seoMetakeyword: Yup.string().max(255).required("MetaKeyword SEO es obligatorio"),
        seoMetadescription: Yup.string().max(255).required("Descripción SEO es obligatorio"),
        seoUrlFriendly: Yup.string().max(255).required("URL Amigable es obligatorio"),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        if (mode === "create") {
          if (imageHasBeenCropped) {
            let fileToUpload = rootFile;
            if (croppedImage) {
              const res = await fetch(croppedImage);
              const blob = await res.blob();
              fileToUpload = new File([blob], rootFile.name, {
                type: rootFile.type,
              });
            }
            const data = new FormData();
            data.append("file", fileToUpload);
            const imageReq = await fetch(`${process.env.REACT_APP_API_BASE_URL}/uploader`, {
              method: "POST",
              body: data,
              headers: {
                authToken: api_key,
              },
            });
            const imageResult = await imageReq.json();

            const serviceReq = await fetch(`${process.env.REACT_APP_API_BASE_URL}/services`, {
              method: "POST",
              body: JSON.stringify({
                ...values,
                active: 1,
                pos: 1,
                description,
                imagen: `${process.env.REACT_APP_API_BASE_URL}/files/default/${imageResult.path
                  .split("\\")
                  .pop()}`.replaceAll("api/", ""),
                sections: sections.map((section, _index) => ({
                  imagen: section.image,
                  title: section.title,
                  subtitle: section.subtitle,
                  description: section.description,
                  active: 1,
                  deleted: 0,
                  pos: section.pos,
                })),
                keys: keys.map((key, _index) => ({
                  title: key.title,
                  subtitle: key.subtitle,
                  active: 1,
                  deleted: 0,
                  pos: key.pos,
                })),
              }),
              headers: {
                authToken: api_key,
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            });
            const serviceResult = await serviceReq.json();
            console.log(serviceResult);
            if (serviceResult.statusCode && serviceResult.statusCode > 201) {
              msgError("Error", serviceResult.message || "Error al crear servicio");
            } else {
              msgOk("Guardado", "Servicio creado correctamente");
              var id = serviceResult.response;
              navigate("/admin/Servicios/" + id);
            }
          } else {
            msgError("Error", "Se requiere una imagen recortada para crear el servicio");
          }
        }

        if (mode === "edit") {
          let imageUrl = serviceImageUrl;
          // Valida si se cargo una imagen nueva
          if (!serviceHasImage) {
            // if (imageHasBeenCropped) {
            let fileToUpload = rootFile;
            /* if (croppedImage) {
              const res = await fetch(croppedImage);
              const blob = await res.blob();
              fileToUpload = new File([blob], rootFile.name, {
                type: rootFile.type,
              });
            } */
            const data = new FormData();
            data.append("file", fileToUpload);
            const imageReq = await fetch(`${process.env.REACT_APP_API_BASE_URL}/uploader`, {
              method: "POST",
              body: data,
              headers: {
                authToken: api_key,
              },
            });
            const imageResult = await imageReq.json();
            imageUrl = `${process.env.REACT_APP_API_BASE_URL}/files/default/${imageResult.filename
              .split("\\")
              .pop()}`.replaceAll("api/", "");
            /* } else {
              // Error por no tener imagen
              msgError("Error", "Se requiere una imagen recortada para actualizar el servicio");
              setSubmitting(false);
              return;
            } */
          }
          const serviceReq = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/services/update/${recordId}/${idiomaSeleccionado}`,
            {
              method: "PUT",
              body: JSON.stringify({
                ...values,
                active: values.active ? 1 : 0,
                deleted: values.active ? 0 : 1,
                pos: values.pos,
                description,
                image: imageUrl,
                sections: sections.map((section, _index) => ({
                  id: String(section.id).includes("-marker") ? 0 : section.id,
                  imagen: section.image,
                  title: section.title,
                  subtitle: section.subtitle,
                  description: section.description,
                  active: Number(section.id) >= 0 ? 1 : 0,
                  deleted: Number(section.id) >= 0 ? 0 : 1,
                  pos: section.pos,
                })),
                keys: keys.map((key, _index) => ({
                  ...key,
                  id: String(key.id).includes("-marker") ? 0 : key.id,
                  active: Number(key.id) >= 0 ? 1 : 0,
                  deleted: Number(key.id) >= 0 ? 0 : 1,
                  pos: key.pos,
                })),
              }),
              headers: {
                authToken: api_key,
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          );
          const serviceResult = await serviceReq.json();

          if (serviceResult.statusCode && serviceResult.statusCode > 201) {
            msgError("Error", serviceResult.message || "Error al crear servicio");
          } else {
            msgOk("Guardado", "Servicio actualizado correctamente");
            // navigate("/Servicios");
          }
        }
        setSubmitting(false);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        submitForm,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {mode === "edit" && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.pos && errors.pos)}
                    sx={{ marginBottom: "1rem" }}
                  >
                    <TextField
                      type="number"
                      value={values.pos}
                      label="Posición"
                      name="pos"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue("pos", e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") e.preventDefault();
                      }}
                      placeholder="Posición"
                      variant="standard"
                    />
                    {touched.pos && errors.pos && (
                      <FormHelperText error id="standard-weight-helper-text-password-login">
                        {errors.pos}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} sx={{ textAlign: "right" }}>
                  <FormControlLabel
                    label="Activo"
                    control={
                      <Checkbox
                        checked={values.active}
                        onChange={() => setFieldValue("active", !values.active)}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12} md={2} sx={{ textAlign: "right" }}>
                  <Autocomplete
                    id="ddLanguages"
                    disableClearable
                    options={idiomas}
                    value={ItemidiomaSeleccionado}
                    getOptionLabel={(option) => option.NOMBRE} // Asumiendo que cada idioma tiene una propiedad 'nombre'
                    renderInput={(params) => (
                      <TextField {...params} label="Idioma" variant="standard" />
                    )}
                    onChange={handleIdiomaChange} // Maneja el cambio de selección
                  />
                  {/* <FormControl
                    fullWidth
                    error={Boolean(touched.idioma && errors.idioma)}
                    sx={{ marginBottom: "1rem" }}
                  >
                    <TextField
                      select
                      value={idiomaSeleccionado}
                      label="Idioma"
                      name="idioma"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setIdiomaSeleccionado(e.target.value);
                      }}
                      variant="standard"
                    >
                      {idiomas.map((option) => (
                        <MenuItem key={option.ID} value={option.ID}>
                          {option.NOMBRE}
                        </MenuItem>
                      ))}
                    </TextField>
                    {touched.idioma && errors.idioma && (
                      <FormHelperText error id="standard-weight-helper-text-password-login">
                        {errors.idioma}
                      </FormHelperText>
                    )}
                  </FormControl> */}
                </Grid>
              </Grid>
            </>
          )}
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                error={Boolean(touched.title && errors.title)}
                sx={{ marginBottom: "1rem" }}
              >
                <TextField
                  type="text"
                  value={values.title}
                  label="Titulo"
                  name="title"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldValue("title", e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") e.preventDefault();
                  }}
                  placeholder="Titulo"
                  variant="standard"
                />
                {touched.title && errors.title && (
                  <FormHelperText error id="standard-weight-helper-text-password-login">
                    {errors.title}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                error={Boolean(touched.subtitle && errors.subtitle)}
                sx={{ marginBottom: "1rem" }}
              >
                <TextField
                  type="text"
                  value={values.subtitle}
                  label="Subtítulo"
                  name="subtitle"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldValue("subtitle", e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") e.preventDefault();
                  }}
                  placeholder="Subtítulo"
                  variant="standard"
                />
                {touched.subtitle && errors.subtitle && (
                  <FormHelperText error id="standard-weight-helper-text-password-login">
                    {errors.subtitle}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                error={Boolean(touched.seoTitle && errors.seoTitle)}
                sx={{ marginBottom: "1rem" }}
              >
                <TextField
                  type="text"
                  value={values.seoTitle}
                  label="Titulo SEO"
                  name="seoTitle"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldValue("seoTitle", e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") e.preventDefault();
                  }}
                  placeholder="Titulo SEO"
                  variant="standard"
                />
                {touched.seoTitle && errors.seoTitle && (
                  <FormHelperText error id="standard-weight-helper-text-password-login">
                    {errors.seoTitle}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                error={Boolean(touched.seoMetakeyword && errors.seoMetakeyword)}
                sx={{ marginBottom: "1rem" }}
              >
                <TextField
                  type="text"
                  value={values.seoMetakeyword}
                  label="SEO MetaKeyword"
                  name="seoMetakeyword"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldValue("seoMetakeyword", e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") e.preventDefault();
                  }}
                  placeholder="SEO MetaKeyword"
                  variant="standard"
                />
                {touched.seoMetakeyword && errors.seoMetakeyword && (
                  <FormHelperText error id="standard-weight-helper-text-password-login">
                    {errors.seoMetakeyword}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                error={Boolean(touched.seoMetadescription && errors.seoMetadescription)}
                sx={{ marginBottom: "1rem" }}
              >
                <TextField
                  type="text"
                  value={values.seoMetadescription}
                  label="Descripción SEO"
                  name="seoMetadescription"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldValue("seoMetadescription", e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") e.preventDefault();
                  }}
                  placeholder="Descripción SEO"
                  variant="standard"
                />
                {touched.seoMetadescription && errors.seoMetadescription && (
                  <FormHelperText error id="standard-weight-helper-text-password-login">
                    {errors.seoMetadescription}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                error={Boolean(touched.seoUrlFriendly && errors.seoUrlFriendly)}
                sx={{ marginBottom: "1rem" }}
              >
                <TextField
                  type="text"
                  value={values.seoUrlFriendly}
                  label="URL Amigable"
                  name="seoUrlFriendly"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldValue("seoUrlFriendly", e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") e.preventDefault();
                  }}
                  placeholder="URL Amigable"
                  variant="standard"
                />
                {touched.seoUrlFriendly && errors.seoUrlFriendly && (
                  <FormHelperText error id="standard-weight-helper-text-password-login">
                    {errors.seoUrlFriendly}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} justifyContent="end">
              <FormControlLabel
                control={<Switch checked={editMode} onChange={toggleEditMode} />}
                label="Editar como HTML web"
              />
            </Grid>
            <Grid item xs={12}>
              {editMode ? (
                <ReactQuill
                  modules={modules}
                  label="Texto"
                  id="txtTexto"
                  value={description + ""}
                  onChange={handleQuillChange}
                  theme="snow"
                  className="react-quill-custom"
                />
              ) : (
                <textarea
                  style={{ width: "100%", height: "300px" }}
                  value={description}
                  onChange={handleDescriptionChange}
                ></textarea>
              )}
            </Grid>
          </Grid>
          <>
            <Grid container sx={{ paddingY: "1rem" }}>
              <Grid item xs={11}>
                <MDTypography variant="h7">Imagen</MDTypography>
              </Grid>
              {file || serviceHasImage ? (
                <Grid item xs={1} sx={{ textAlign: "right" }}>
                  <Tooltip title="Eliminar imagen">
                    <IconButton
                      onClick={() => {
                        setFile(null);
                        setCroppedImage(null);
                        setServiceHasImage(false);
                      }}
                    >
                      <Close />
                    </IconButton>
                  </Tooltip>
                </Grid>
              ) : (
                <Grid item xs={1} />
              )}
            </Grid>
            {serviceHasImage && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#eee",
                  padding: "2rem",
                  overflow: "hidden",
                }}
              >
                <img src={serviceImageUrl} alt="service" style={{ maxWidth: "100%" }} />
              </Box>
            )}
            {!serviceHasImage && (
              <Grid container>
                {file && rootFile ? (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      ml: 0,
                      mr: 0,
                      minHeight: "350px",
                      width: "100%",
                      border: `1px dashed black`,
                      borderRadius: "0px",
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 600,
                          }}
                        >
                          Imagen Original
                        </Typography>
                        <Box style={{ maxWidth: "90%", padding: "1rem" }}>
                          <NoCropper
                            imageToCrop={URL.createObjectURL(rootFile)}
                            croppedImage={(value) => setCroppedImage(value)}
                            ratio={null}
                          />
                        </Box>
                      </Grid>
                      {/* <Grid item xs={6}>
                        <Typography
                          sx={{
                            fontWeight: 600,
                          }}
                        >
                          Vista Previa
                        </Typography>
                        {croppedImage ? (
                          <img
                            src={croppedImage}
                            alt="preview"
                            style={{ maxWidth: "90%", padding: "1rem" }}
                          />
                        ) : (
                          <img
                            src={file.preview}
                            alt="preview"
                            style={{ maxWidth: "90%", padding: "1rem" }}
                          />
                        )}
                      </Grid> */}
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    item
                    id="imageBAnnercontainer"
                    xs={12}
                    sx={{
                      ml: 0,
                      mr: 0,
                      minHeight: "189px",
                      width: "100%",
                      border: `1px dashed black`,
                      borderRadius: "0px",
                      padding: "2rem",
                    }}
                  >
                    <UploadImageBanner rootProps={getRootProps} />
                    <input accept=".jpg, .jpeg, .png, .gif, .svg, .pdf" {...getInputProps()} />
                  </Grid>
                )}
              </Grid>
            )}
          </>
          <SectionsTable mode={mode} list={sections} setList={setSections} />
          <KeysTable mode={mode} list={keys} setList={setKeys} />
          <Grid item xs={12} sx={{ marginTop: "2rem" }}>
            <MDBox display="flex" justifyContent="flex-end">
              <MDButton type="submit" variant="gradient" color="primary" size="medium">
                GUARDAR DATOS
              </MDButton>
            </MDBox>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

ServicioForm.propTypes = {
  mode: PropTypes.string.isRequired,
  general: PropTypes.object.isRequired,
  sections: PropTypes.array.isRequired,
  setSections: PropTypes.func.isRequired,
  keys: PropTypes.array.isRequired,
  setKeys: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  setDescription: PropTypes.func.isRequired,
  idiomas: PropTypes.array.isRequired,
  idiomaSeleccionado: PropTypes.number.isRequired,
  setIdiomaSeleccionado: PropTypes.func.isRequired,
  ItemidiomaSeleccionado: PropTypes.object.isRequired,
  setIdiomaSeleccionadoValue: PropTypes.object.isRequired,
  serviceImageUrl: PropTypes.string,
  recordId: PropTypes.string,
};

export default ServicioForm;
