import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { BASE_URL, fetchSMTPData, saveSMTPData } from "./data/Data";
import { ToastContainer, toast, Bounce } from "react-toastify";
import PropTypes from "prop-types";
import "react-toastify/dist/ReactToastify.css";

function EditSMTP() {
  const CustomToastContent = ({ title, subtitle }) => (
    <div>
      <strong style={{ fontSize: "16px" }}>{title}</strong>
      {subtitle && <p style={{ fontSize: "14px" }}>{subtitle}</p>}
    </div>
  );
  CustomToastContent.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
  };
  CustomToastContent.defaultProps = {
    title: "¡Guardado correctamente!",
    subtitle: "",
  };

  const msgOk = (title, subtitle) =>
    toast.success(<CustomToastContent title={title} subtitle={subtitle} />, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      cloSMTPnClick: true,
      pauSMTPnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });

  const msgError = (title, subtitle) =>
    toast.error(<CustomToastContent title={title} subtitle={subtitle} />, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      cloSMTPnClick: true,
      pauSMTPnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });
  const api_key = localStorage.getItem("access_token") || "";

  const { id } = useParams(); // Obtener el ID desde la URL
  const navigate = useNavigate(); // Hook para navegar programáticamente
  const [SMTPData, setSMTPData] = useState({
    ID: 0,
    SMTP_VALUE: "",
    ALIAS_EMISOR: "",
    SMTP_SMTP_MAILTO: "",
    SMTP_USER: "",
    SMTP_PASS: "",
    PORT: "",
    SSL: true,
  });

  const inputToStateKeyMap = {
    txtSMTP: "SMTP_VALUE",
    txtAliasEmisor: "ALIAS_EMISOR",
    txtEmailReceptor: "SMTP_MAILTO",
    txtEmailEmisor: "EMAIL_EMISOR",
    txtSMTP_USER: "SMTP_USER",
    txtSmtpPass: "SMTP_PASS",
    txtPort: "PORT",
    checkSSL: "SSL",
  };

  const titleNav = "SMTP";

  // Cargar los datos existentes
  useEffect(() => {
    const loadData = async () => {
      try {
        if (id == 0) return;
        const data = await fetchSMTPData(id);
        if (data && data.length > 0) {
          // Asume que el backend devuelve un array y el elemento que necesitas está en la primera posición
          setSMTPData({
            ID: data[0].Id,
            SMTP_VALUE: data[0].smtpValue,
            EMAIL_EMISOR: data[0].mail,
            ALIAS_EMISOR: data[0].ALIAS_EMISOR,
            SMTP_MAILTO: data[0].mailTo,
            SMTP_USER: data[0].smtpuser,
            SMTP_PASS: data[0].smtppassword,
            PORT: data[0].PORT,
            SSL: data[0].ssl,
          });
        } else {
          msgError("No se encontraron datos para el ID proporcionado");
        }
      } catch (error) {
        msgError("Error al obtener datos de SMTP:", error);
      }
    };
    loadData();
  }, [id]);

  // Manejar los cambios en los inputs
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    const stateKey = inputToStateKeyMap[id] || id; // Usa el mapeo, o el id directamente si no hay mapeo
    setSMTPData((prevState) => ({
      ...prevState,
      [stateKey]: value,
    }));
  };
  // Manejar los cambios en los checks
  const handleChecksChange = (e) => {
    const { id, checked } = e.target;
    const stateKey = inputToStateKeyMap[id] || id; // Usa el mapeo, o el id directamente si no hay mapeo
    setSMTPData((prevState) => ({
      ...prevState,
      [stateKey]: checked,
    }));
  };

  // Manejar el envío del formulario
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await saveSMTPData(SMTPData);

      if (response.ok) {
        const msgResponse = await response.json();
        // Si la solicitud fue exitosa
        if (msgResponse.error != "") {
          msgError(msgResponse.error);
        } else {
          msgOk();
        }
        // navigate("/donde-quiera-redirigir"); // Por ejemplo, a la página de confirmación o al listado
      } else {
        // Si hubo un problema con la solicitud
        const errorData = await response.json(); // Suponiendo que el servidor responde con detalles del error en JSON
        msgError(`Error al guardar: ${errorData.message}`);
        // Aquí podrías establecer algún estado para mostrar el error en la UI en lugar de un alert
      }
    } catch (error) {
      // Si hubo un error en la red o algo impidió completar la solicitud
      console.error("Error al hacer la solicitud:", error);
      msgError("Error al intentar guardar los datos.");
      // Similar al caso de error anterior, podrías manejar este error en la UI
    }
  };

  return (
    <DashboardLayout>
      <ToastContainer />
      <DashboardNavbar title={`Edición de ${titleNav}`} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Edición del registro
                </MDTypography>
              </MDBox>
              <MDBox py={3} px={3}>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={6}>
                    {/* Campos del formulario */}
                    <Grid item xs={6}>
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <MDInput
                            type="text"
                            label="SMTP"
                            id="txtSMTP"
                            variant="standard"
                            fullWidth
                            value={SMTPData.SMTP_VALUE}
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <MDInput
                            type="text"
                            label="Correo electrónico del remitente"
                            id="txtEmailEmisor"
                            variant="standard"
                            fullWidth
                            value={SMTPData.EMAIL_EMISOR + ""}
                            onChange={handleInputChange}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <MDInput
                            type="text"
                            label="Alias ​​del remitente"
                            id="txtAliasEmisor"
                            variant="standard"
                            fullWidth
                            value={SMTPData.ALIAS_EMISOR + ""}
                            onChange={handleInputChange}
                            autoComplete="off"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <MDInput
                            type="text"
                            label="Correo electrónico del destinatario"
                            id="txtEmailReceptor"
                            variant="standard"
                            fullWidth
                            value={SMTPData.SMTP_MAILTO + ""}
                            onChange={handleInputChange}
                            autoComplete="off"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container spacing={4}>
                        <Grid item xs={6}>
                          <MDInput
                            type="text"
                            label="Usuario"
                            id="txtSMTP_USER"
                            variant="standard"
                            fullWidth
                            value={SMTPData.SMTP_USER}
                            onChange={handleInputChange}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <MDInput
                            type="text"
                            label="Contraseña"
                            id="txtSmtpPass"
                            variant="standard"
                            fullWidth
                            value={SMTPData.SMTP_PASS}
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <MDInput
                            type="text"
                            label="Puerto"
                            id="txtPort"
                            variant="standard"
                            fullWidth
                            value={SMTPData.PORT}
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={6} textAlign={"end"}>
                          <FormControlLabel
                            control={
                              <Switch
                                id="checkSSL"
                                defaultChecked
                                checked={SMTPData.SSL}
                                onChange={handleChecksChange}
                              />
                            }
                            label="SSL"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <MDBox display="flex" justifyContent="flex-end">
                        <MDButton type="submit" variant="gradient" color="primary" size="medium">
                          GUARDAR DATOS
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                </form>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditSMTP;
