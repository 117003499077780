/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PropTypes from "prop-types";

function Basic() {
  const CustomToastContent = ({ title, subtitle }) => (
    <div>
      <strong style={{ fontSize: "16px" }}>{title}</strong>
      {subtitle && <p style={{ fontSize: "14px" }}>{subtitle}</p>}
    </div>
  );

  CustomToastContent.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
  };

  CustomToastContent.defaultProps = {
    subtitle: "",
  };

  const msgOk = (title, subtitle) =>
    toast.success(<CustomToastContent title={title} subtitle={subtitle} />, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });

  const msgError = (title, subtitle) =>
    toast.error(<CustomToastContent title={title} subtitle={subtitle} />, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailRecover, setEmailRecover] = useState("");

  const [isLoginVisible, setIsLoginVisible] = useState(true); // Controla la visibilidad del panel de login
  const navigate = useNavigate(); // Obtén acceso al historial de navegación

  // Este método se llamará cuando el usuario quiera mostrar el panel de recuperación de contraseña
  const handleShowRecoverPassword = () => {
    setIsLoginVisible(false); // Oculta el panel de login y muestra el de recuperar contraseña
  };
  const handleShowLogin = () => {
    setIsLoginVisible(true); // Oculta el panel de login y muestra el de recuperar contraseña
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Previene el comportamiento por defecto del formulario

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        // Almacenar el token en el almacenamiento local
        localStorage.setItem("access_token", data.accessToken);
        // Redireccionar al usuario o actualizar la UI según sea necesario
        navigate("/admin/SEO"); // Usa history.push para redirigir
      } else {
        msgError("Usuario o contraseña incorrecta");
      }
    } catch (error) {
      msgError("Error al intentar conectar con el servidor", error);
    }
  };

  const handleSubmitRecoverPass = async (e) => {
    e.preventDefault(); // Previene el comportamiento por defecto del formulario

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/recover-pass`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: emailRecover }),
      });

      if (response.ok) {
        const data = await response.json();
        // Almacenar el token en el almacenamiento local
        if (data.error == "") {
          msgOk("Email enviado correctamente");
        } else {
          msgError(data.error);
        }
      } else {
        console.error("Error en el inicio de sesión");
      }
    } catch (error) {
      console.error("Error al intentar conectar con el servidor", error);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <ToastContainer />
      {isLoginVisible ? (
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDButton variant="gradient" color="info" fullWidth>
              ATHLOAN
            </MDButton>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form" onSubmit={handleSubmit}>
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  label="Email"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Password"
                  fullWidth
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="info" fullWidth type="submit">
                  Iniciar sesion
                </MDButton>
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  ¿Olvidaste tu contraseña? Sigue este{" "}
                  <MDTypography
                    component={Link}
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                    onClick={handleShowRecoverPassword}
                  >
                    enlace
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      ) : (
        // Panel de recuperar contraseña (oculto por defecto)
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDButton variant="gradient" color="info" fullWidth>
              ATHLOAN
            </MDButton>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form" onSubmit={handleSubmitRecoverPass}>
              <MDTypography
                variant="h4"
                color="black"
                mb={3}
                textAlign={"center"}
                fontWeight={"bold"}
              >
                RESTABLECIMIENTO DE CONTRASEÑA
              </MDTypography>
              <MDTypography variant="body2" color="text" textAlign={"center"}>
                Introduce la dirección de email asociada a tu cuenta. Enviaremos instrucciones para
                restablecer tu contraseña.
              </MDTypography>
              <MDBox mb={2} mt={4}>
                <MDInput
                  type="email"
                  id="txtEmailRecover"
                  label="Email"
                  fullWidth
                  value={emailRecover}
                  onChange={(e) => setEmailRecover(e.target.value)}
                />
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="info" fullWidth type="submit">
                  Recuperar contraseña
                </MDButton>
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  Puedes iniciar sesión desde este{" "}
                  <MDTypography
                    component={Link}
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                    onClick={handleShowLogin}
                  >
                    enlace
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      )}
    </BasicLayout>
  );
}

export default Basic;
