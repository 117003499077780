// Asume que la URL base de tu API podría cambiar o ser reutilizada, por lo que podría ser buena idea definirla como constante.
export const BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/seo`;

/**
 * Obtiene los datos de SEO para un ID específico.
 * @param {string} id - El ID del registro SEO que se desea obtener.
 * @returns {Promise<any>} - Los datos de SEO obtenidos.
 */
export const fetchSeoData = async (id, lang) => {
  try {
    const response = await fetch(`${BASE_URL}/${id}?lang=${lang}`, {
      method: "GET",
      headers: {
        authToken: api_key,
      },
    });
    if (!response.ok) {
      throw new Error("Error al obtener los datos de SEO");
    }
    return await response.json();
  } catch (error) {
    console.error("Error al obtener datos de SEO:", error);
    throw error; // Propagar el error para manejarlo en el componente.
  }
};

/**
 * Envía los datos modificados de SEO al servidor.
 * @param {Object} seoData - Los datos modificados de SEO a guardar.
 * @returns {Promise<void>}
 */
const api_key = localStorage.getItem("access_token") || "";
export const saveSeoData = async (seoData) => {
  try {
    const response = await fetch(`${BASE_URL}`, {
      method: "POST", // Cambiar a "PUT" si tu API usa PUT para actualizaciones.
      headers: {
        "Content-Type": "application/json",
        authToken: api_key,
      },
      body: JSON.stringify({
        ...seoData,
        TEXT: "", // Siempre vacío según tu especificación.
      }),
    });

    if (!response.ok) {
      throw new Error("Error al guardar los datos de SEO");
    }
    // Opcional: Procesar la respuesta si es necesario.
  } catch (error) {
    console.error("Error al guardar datos de SEO:", error);
    throw error; // Propagar el error para manejarlo en el componente.
  }
};

export const getLanguages = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/traducciones/getIdiomas`, {
      method: "GET",
      headers: {
        authToken: api_key,
      },
    });
    if (!response.ok) {
      throw new Error("Error al obtener los datos");
    }
    return await response.json();
  } catch (error) {
    console.error("Error al obtener datos :", error);
    throw error; // Propagar el error para manejarlo en el componente.
  }
};
