import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { ToastContainer, toast, Bounce } from "react-toastify";
import PropTypes from "prop-types";
import "react-toastify/dist/ReactToastify.css";
import "react-quill/dist/quill.snow.css"; // Importar los estilos para react-quill
import "../../../assets/css/masterAdmin.css";
import { getLanguages } from "shared/getLanguages";
import ServicioForm from "../form";

function CreateServicios() {
  const api_key = localStorage.getItem("access_token") || "";
  const [idiomas, setIdiomas] = useState([]);
  const [sections, setSections] = useState([]);
  const [description, setDescription] = useState("");
  const [keys, setKeys] = useState([]);
  const [formState, setFormState] = useState({
    general: {
      active: true,
      imagen: "",
      pos: 1,
      title: "",
      subtitle: "",
      seoTitle: "",
      seoMetakeyword: "",
      seoMetadescription: "",
      seoUrlFriendly: "",
    },
  });
  const [idiomaSeleccionado, setIdiomaSeleccionado] = useState(null);
  const CustomToastContent = ({ title, subtitle }) => (
    <div>
      <strong style={{ fontSize: "16px" }}>{title}</strong>
      {subtitle && <p style={{ fontSize: "14px" }}>{subtitle}</p>}
    </div>
  );
  CustomToastContent.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
  };
  CustomToastContent.defaultProps = {
    title: "¡Guardado correctamente!",
    subtitle: "",
  };

  const msgOk = (title, subtitle) =>
    toast.success(<CustomToastContent title={title} subtitle={subtitle} />, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      cloLegalPagesnClick: true,
      pauLegalPagesnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });

  const msgError = (title, subtitle) =>
    toast.error(<CustomToastContent title={title} subtitle={subtitle} />, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      cloLegalPagesnClick: true,
      pauLegalPagesnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });

  const navigate = useNavigate();

  // Cargar los datos existentes
  useEffect(() => {
    const cargarIdiomas = async () => {
      try {
        const datosIdiomas = await getLanguages();
        setIdiomas(
          datosIdiomas || [
            { ID: 1, NOMBRE: "ESPAÑOL" },
            { ID: 2, NOMBRE: "ENGLISH" },
          ]
        );
        const idiomaPorDefecto = datosIdiomas.find((idioma) => idioma.ID == 1);
        // Evita establecer el idioma por defecto aquí si ya tienes uno seleccionado
        if (idiomaPorDefecto && !idiomaSeleccionado) {
          setIdiomaSeleccionado(idiomaPorDefecto);
        }
      } catch (error) {
        console.error("Error al cargar los idiomas:", error);
        setIdiomas([
          { ID: 1, NOMBRE: "ESPAÑOL" },
          { ID: 2, NOMBRE: "ENGLISH" },
        ]);
      }
    };

    cargarIdiomas();
  }, []);

  const handleIdiomaChange = (event, newValue) => {
    setIdiomaSeleccionado(newValue);
  };

  return (
    <DashboardLayout>
      <ToastContainer />
      <DashboardNavbar title={`Creación de Servicio`} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Creación de Servicio
                </MDTypography>
              </MDBox>
              <MDBox py={3} px={3}>
                <ServicioForm
                  mode="create"
                  general={formState.general}
                  sections={sections}
                  setSections={setSections}
                  keys={keys}
                  setKeys={setKeys}
                  description={description}
                  setDescription={setDescription}
                  idiomas={idiomas}
                  idiomaSeleccionado={idiomaSeleccionado}
                  setIdiomaSeleccionado={setIdiomaSeleccionado}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
export default CreateServicios;
