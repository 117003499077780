import PropTypes from "prop-types";
import { Box, Card, CardActions, CardContent, IconButton, Typography } from "@mui/material";
import { CloudUpload } from "@mui/icons-material";

export const UploadImageBanner = ({ rootProps }) => {
  return (
    <Box
      sx={{
        // height: 750,
        position: "relative",
        marginLeft: 0,
        marginRight: 0,
        cursor: "pointer",
      }}
      {...rootProps({ className: "dropzone" })}
    >
      <Card sx={{ background: "transparent", boxShadow: "none" }}>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <IconButton
            size="large"
            sx={{
              fontSize: 24,
            }}
          >
            <CloudUpload fontSize="large" />
          </IconButton>

          <CardActions>
            <Typography sx={{ fontWeight: 600 }}>
              Drag & drop an image or <span>browse</span>
            </Typography>
          </CardActions>
          <Typography color="text.secondary" gutterBottom sx={{ fontSize: 12 }}>
            Accepted formats: PNG, JPG
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

UploadImageBanner.propTypes = {
  rootProps: PropTypes.func.isRequired,
};
