import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "dayjs/locale/es";

import { useFormik, Formik, Form, Field, useFormikContext } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  FormHelperText,
  Switch,
  FormControlLabel,
  Divider,
  Typography,
} from "@mui/material";
import MDInput from "components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
// Eliminado import { Autocomplete as FormikAutocomplete } from "formik-mui-lab";
import PropTypes from "prop-types";
import { Grid, Card, Box } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getLanguages, fetchData, saveData, saveImgFolder, getTags } from "./Data";
import colors from "assets/theme/base/colors";
import { UploadImageBanner } from "components/shared/UploadImageBanner";
import { Cropper } from "components/Cropper/Cropper";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Close } from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Importar los estilos para react-quill
import "../../../assets/css/masterAdmin.css";

// // Import required plugins
// import customParseFormat from "dayjs/plugin/customParseFormat";
// import localizedFormat from "dayjs/plugin/localizedFormat";
// import weekOfYear from "dayjs/plugin/weekOfYear";
// import isBetween from "dayjs/plugin/isBetween";
// import advancedFormat from "dayjs/plugin/advancedFormat";

// Extend Day.js with required plugins
dayjs.extend(customParseFormat);
// dayjs.extend(localizedFormat);
// dayjs.extend(weekOfYear);
// dayjs.extend(isBetween);
// dayjs.extend(advancedFormat);

function Edit() {
  const [idiomas, setIdiomas] = useState([]);
  const [idiomaSeleccionado, setIdiomaSeleccionado] = useState(null);
  const [tags, setTags] = useState([]);
  const [tagSelected, setTagSelected] = useState([]);

  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({
    ID: 0,
    LANGUAGE_ID: 1,
    ACTIVO: true,
    FECHA_PUBLICACION: dayjs().format("DD/MM/YYYY"), // Formats today's date as 'YYYY-MM-DD'
    IMAGEN: "",
    TITULO: "",
    DESC_CORTA: "",
    DESC_LARGA: "",
    SEO_TITLE: "",
    SEO_METAKEYWORD: "",
    SEO_METADESCRIPTION: "",
    SEO_URL_FRIENDLY: "",
    TAGS: [],
  });

  // const [data, setData] = useState({
  //   ID: 0,
  //   ACTIVO: true,
  //   IMAGEN: "",
  //   FECHA_PUBLICACION: "",
  //   LANGUAGE_ID: 1,
  //   TITULO: "1",
  //   DESC_CORTA: "",
  //   DESC_LARGA: "2",
  //   SEO_TITLE: "3",
  //   SEO_METAKEYWORD: "4",
  //   SEO_METADESCRIPTION: "5",
  //   SEO_URL_FRIENDLY: "6",
  // });
  useEffect(() => {
    const loadLanguages = async () => {
      const langs = await getLanguages();
      setIdiomas(langs);
      const idiomaPorDefecto = langs.find((idioma) => idioma.ID == 1);
      // Evita establecer el idioma por defecto aquí si ya tienes uno seleccionado
      if (idiomaPorDefecto && !idiomaSeleccionado) {
        setIdiomaSeleccionado(idiomaPorDefecto);
      }
      const tags = await getTags();
      // Convertir cada ID de tag a número si actualmente es una cadena
      const normalizedTags = tags.map((tag) => ({
        ID: +tag.ID, // Usando el operador unario '+' para convertir a número
        NOMBRE: tag.NOMBRE,
      }));

      setTags(normalizedTags);
    };
    loadLanguages();
  }, []);

  useEffect(() => {
    // Carga los datos iniciales
    const loadData = async () => {
      if (!idiomaSeleccionado) return;
      const data = await fetchData(id, idiomaSeleccionado.ID); // Asumiendo que fetchData es una función que obtiene los datos del registro por su ID
      var item = data[0];
      item.TAGS = item.TAGS ? JSON.parse(item.TAGS) : [];
      setTagSelected(item.TAGS);
      setData(item);
    };
    if (id > 0) {
      loadData();
    } else {
    }
  }, [id, idiomaSeleccionado]);

  const handleIdiomaChange = (event, newValue) => {
    // newValue es el objeto de la opción seleccionada
    setIdiomaSeleccionado(newValue); // Guarda el idioma seleccionado si necesitas usarlo en otro lugar
  };
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const lastValidDateRef = useRef(selectedDate); // Ref to keep track of the last valid date

  // Effect to update the selectedDate when values.FECHA_PUBLICACION changes
  useEffect(() => {
    if (data.FECHA_PUBLICACION) {
      setSelectedDate(dayjs(data.FECHA_PUBLICACION, "DD/MM/YYYY"));
    }
  }, [data.FECHA_PUBLICACION]);

  const handleDateChange = (newValue) => {
    if (newValue && newValue.isValid()) {
      setSelectedDate(newValue);
      lastValidDateRef.current = newValue; // Update ref to the new valid date
      setData({ ...data, FECHA_PUBLICACION: newValue.format("DD/MM/YYYY") });
    } else {
      setSelectedDate(lastValidDateRef.current); // Reset to the last valid date if new value is invalid
      setData({ ...data, FECHA_PUBLICACION: "" });
    }
  };

  const CustomToastContent = ({ title, subtitle }) => (
    <div>
      <strong style={{ fontSize: "16px" }}>{title}</strong>
      {subtitle && <p style={{ fontSize: "14px" }}>{subtitle}</p>}
    </div>
  );
  CustomToastContent.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
  };
  CustomToastContent.defaultProps = {
    title: "¡Guardado correctamente!",
    subtitle: "",
  };

  const msgOk = (title, subtitle) =>
    toast.success(<CustomToastContent title={title} subtitle={subtitle} />, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });

  const msgError = (title, subtitle) =>
    toast.error(<CustomToastContent title={title} subtitle={subtitle} />, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });
  const modules = {
    toolbar: [
      // Configuración de tu barra de herramientas aquí
      ["bold", "italic", "underline", "strike"], // Estilos de texto enriquecido
      ["blockquote"], // Bloques de cita y código

      [{ header: 1 }, { header: 2 }], // Títulos
      [{ list: "ordered" }, { list: "bullet" }], // Listas
      [{ script: "sub" }, { script: "super" }], // Subíndice/Superíndice
      [{ indent: "-1" }, { indent: "+1" }], // Sangrías
      [{ direction: "rtl" }], // Texto de derecha a izquierda

      [{ size: ["small", false, "large", "huge"] }], // Tamaños de fuente
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // Selector de color
      [{ font: [] }], // Fuente
      [{ align: [] }], // Alineación
      ["link", "image", "video"], // Insertar enlaces, imágenes, videos

      ["clean"], // Limpiar formato
    ],
  };

  const FormikAutocomplete = ({ ...props }) => {
    const { setFieldValue } = useFormikContext();

    return (
      <Autocomplete
        disableClearable
        options={idiomas}
        value={idiomaSeleccionado}
        getOptionLabel={(option) => option.NOMBRE} // Asumiendo que cada idioma tiene una propiedad 'nombre'
        renderInput={(params) => <TextField {...params} label="Idioma" variant="standard" />}
        onChange={handleIdiomaChange} // Maneja el cambio de selección
      />
    );
  };

  const FormikAutocompleteTags = ({ ...props }) => {
    const { setFieldValue } = useFormikContext();
    const handleTagChange = (event, newValue) => {
      // Rastrear cada ID y cuántas veces aparece
      const idCounts = new Map();
      newValue.forEach((tag) => {
        idCounts.set(tag.ID, (idCounts.get(tag.ID) || 0) + 1);
      });

      // Filtrar para eliminar todos los tags cuyos IDs aparecen más de una vez
      const filteredNewValue = newValue.filter((tag) => idCounts.get(tag.ID) === 1);

      setTagSelected(filteredNewValue); // Actualiza el estado con los valores filtrados
      setFieldValue("TAGS", filteredNewValue); // Actualiza Formik
    };

    return (
      <Autocomplete
        disableClearable
        multiple
        options={tags}
        value={tagSelected}
        onChange={handleTagChange}
        getOptionLabel={(option) => option.NOMBRE}
        getOptionSelected={(option, value) => option.ID === value.ID}
        renderInput={(params) => <TextField {...params} label="Tags" variant="standard" />}
        // Asegurándonos de que cada opción tiene una clave única
        key={(option) => option.ID}
      />
    );
  };

  const titleNav = id == 0 ? "registro" : "registro " + data.TITULO;

  // Esquema de validación
  const validationSchema = Yup.object().shape({
    TITULO: Yup.string().max(500).required("Campo obligatorio"),
    FECHA_PUBLICACION: Yup.string().required("Campo obligatorio"),
    LANGUAGE_ID: Yup.number().required("Campo obligatorio"),
    SEO_TITLE: Yup.string().required("Campo obligatorio"),
    SEO_URL_FRIENDLY: Yup.string().required("Campo obligatorio"),
    // Incluye otras validaciones según corresponda
  });
  const handleSubmit = async (values, actions) => {
    try {
      var imgOK = await saveImgOnSubmit(values);
      if (!imgOK) return;
      const response = await saveData(values);
      if (response.ok) {
        const msgResponse = await response.json();
        // Si la solicitud fue exitosa
        if (msgResponse.error != "") {
          msgError(msgResponse.error);
        } else {
          msgOk();
          var id = msgResponse.response[0][""];
          navigate("/admin/noticias/" + id);
        }
      } else {
        // Si hubo un problema con la solicitud
        const errorData = await response.json(); // Suponiendo que el servidor responde con detalles del error en JSON
        msgError(`Error al guardar: ${errorData.message}`);
        // Aquí podrías establecer algún estado para mostrar el error en la UI en lugar de un alert
      }
    } catch (error) {
      msgError("Ocurrió un error al guardar los datos");
      console.error(error);
    } finally {
      actions.setSubmitting(false);
    }
  };
  /*CROPPPER*/
  const folderSave = "noticias";
  const [imageHasBeenCropped, setImageHasBeenCropped] = useState(false);
  const [showImageError, setShowImageError] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [file, setFile] = useState(null);
  const [rootFile, setRootFile] = useState(null);
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    multiple: false,
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      setRootFile(acceptedFiles[0]);
      setFile({
        ...acceptedFiles[0],
        preview: URL.createObjectURL(acceptedFiles[0]),
      });
    },
  });

  useEffect(() => {
    if (croppedImage) {
      setImageHasBeenCropped(true);
    } else {
      setImageHasBeenCropped(false);
    }
  }, [croppedImage]);

  async function saveImgOnSubmit(values) {
    var res = true;
    if (imageHasBeenCropped) {
      let image = values.IMAGEN;
      if (!image) {
        let fileToUpload = rootFile;
        if (croppedImage) {
          const res = await fetch(croppedImage);
          const blob = await res.blob();
          fileToUpload = new File([blob], rootFile.name, {
            type: rootFile.type,
          });
        }
        const data = new FormData();
        data.append("file", fileToUpload);
        const imageReq = await saveImgFolder(data, folderSave);
        const imageResult = imageReq.filename;
        // image = `${
        //   process.env.REACT_APP_API_BASE_URL
        // }/files/${folderSave}/${imageResult.path.split("\\").pop()}`.replaceAll("api/", "");
        var image_path =
          `${process.env.REACT_APP_API_BASE_URL}/files/${folderSave}/${imageResult}`.replaceAll(
            "api/",
            ""
          );
        image = imageResult;
      }
      setData({
        ...data,
        IMAGEN: image,
      });
      values.IMAGEN = image;
      setFile(null);
      setCroppedImage(null);
      // setSubmitForm(true);
    }
    if (!imageHasBeenCropped && !values.IMAGEN) {
      setShowImageError(true);
      res = false;
    }
    return res;
  }
  function getFullPathImg(img) {
    var image_path = `${process.env.REACT_APP_API_BASE_URL}/files/${folderSave}/${img}`.replaceAll(
      "api/",
      ""
    );
    return image_path;
  }
  // Manejar los cambios en handleQuillChange

  /*//CROPPER*/
  return (
    <DashboardLayout>
      <ToastContainer />
      <DashboardNavbar title={`Edición de ${titleNav}`} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Edición del registro
                </MDTypography>
              </MDBox>
              <MDBox py={3} px={3}>
                <Formik
                  initialValues={data} // Usas directamente el estado data como initialValues
                  enableReinitialize // Esto asegura que los valores del formulario se actualicen cuando cambie data
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    submitForm,
                    isSubmitting,
                    touched,
                    values,
                    setFieldValue,
                  }) => (
                    <Form>
                      <MDBox pt={0} pb={3}>
                        <Grid container spacing={6}>
                          <Grid item xs={12}>
                            <Grid container spacing={6}>
                              <Grid item xs={6}>
                                <Grid container spacing={4}>
                                  <Grid item xs={12}>
                                    <MDInput
                                      variant="standard"
                                      fullWidth
                                      name="TITULO"
                                      label="Título"
                                      error={touched.TITULO && Boolean(errors.TITULO)}
                                      value={values.TITULO}
                                      onChange={handleChange}
                                    />
                                    {touched.TITULO && errors.TITULO && (
                                      <FormHelperText error>{errors.TITULO}</FormHelperText>
                                    )}
                                  </Grid>

                                  <Grid item xs={12}>
                                    <FormikAutocompleteTags
                                      name="TAGS"
                                      fullWidth
                                      options={tags}
                                      getOptionLabel={(option) => option.label}
                                      onChange={handleChange}
                                      value={values.TAGS}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={6}>
                                <Grid container spacing={4}>
                                  <Grid item xs={3} textAlign={"start"}>
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          checked={values.ACTIVO}
                                          onChange={handleChange}
                                          name="ACTIVO"
                                        />
                                      }
                                      label="Activo"
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    {/* {selectedDate.format("DD/MM/YYYY")} */}
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                      adapterLocale="es"
                                    >
                                      <DatePicker
                                        label="Fecha de publicación"
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        textField={{
                                          component: (params) => (
                                            <TextField
                                              {...params}
                                              InputProps={{ ...params.InputProps, disabled: true }}
                                            />
                                          ),
                                        }}
                                        error={
                                          touched.FECHA_PUBLICACION &&
                                          Boolean(errors.FECHA_PUBLICACION)
                                        }
                                      />
                                      {touched.FECHA_PUBLICACION && errors.FECHA_PUBLICACION && (
                                        <FormHelperText error>
                                          {errors.FECHA_PUBLICACION}
                                        </FormHelperText>
                                      )}
                                    </LocalizationProvider>
                                    {/* 
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                      <DatePicker
                                        variant="standard"
                                        fullWidth
                                        name="FECHA_PUBLICACION"
                                        label="Fecha publicación"
                                        error={touched.FECHA_PUBLICACION && Boolean(errors.FECHA_PUBLICACION)}
                                        value={values.FECHA_PUBLICACION}
                                        onChange={handleChange}
                                        renderInput={(params) => <TextField {...params} />}
                                      />
                                      {touched.FECHA_PUBLICACION && errors.FECHA_PUBLICACION && (
                                        <FormHelperText error>{errors.FECHA_PUBLICACION}</FormHelperText>
                                      )}
                                    </LocalizationProvider> */}
                                  </Grid>
                                  <Grid item xs={5}>
                                    <FormikAutocomplete
                                      name="LANGUAGE_ID"
                                      fullWidth
                                      options={idiomas}
                                      getOptionLabel={(option) => option.label}
                                      label="Idioma"
                                      onChange={handleChange}
                                      value={values.LANGUAGE_ID}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid item xs={12} pb={3}>
                                <Box
                                  sx={{
                                    borderBottom: 2,
                                    borderColor: "black",
                                    width: "100%",
                                  }}
                                ></Box>
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                sx={{
                                  paddingTop: "20px!important",
                                  borderRight: "1px solid #ccc",
                                  paddingRight: "30px",
                                }}
                              >
                                <Grid item xs={12} pb={3}>
                                  <MDTypography variant="h7">Panel SEO</MDTypography>
                                </Grid>
                                <Grid container spacing={4}>
                                  <Grid item xs={12}>
                                    <MDInput
                                      variant="standard"
                                      fullWidth
                                      name="SEO_TITLE"
                                      label="Título"
                                      error={touched.SEO_TITLE && Boolean(errors.SEO_TITLE)}
                                      value={values.SEO_TITLE}
                                      onChange={handleChange}
                                    />
                                    {touched.SEO_TITLE && errors.SEO_TITLE && (
                                      <FormHelperText error>{errors.SEO_TITLE}</FormHelperText>
                                    )}
                                  </Grid>
                                  <Grid item xs={6}>
                                    <MDInput
                                      variant="standard"
                                      fullWidth
                                      name="SEO_URL_FRIENDLY"
                                      label="Url amigable"
                                      error={
                                        touched.SEO_URL_FRIENDLY && Boolean(errors.SEO_URL_FRIENDLY)
                                      }
                                      value={values.SEO_URL_FRIENDLY}
                                      onChange={handleChange}
                                    />
                                    {touched.SEO_URL_FRIENDLY && errors.SEO_URL_FRIENDLY && (
                                      <FormHelperText error>
                                        {errors.SEO_URL_FRIENDLY}
                                      </FormHelperText>
                                    )}
                                  </Grid>
                                  <Grid item xs={6}>
                                    <MDInput
                                      variant="standard"
                                      fullWidth
                                      name="SEO_METAKEYWORD"
                                      label="MetaKeywords"
                                      // error={
                                      //   touched.SEO_METAKEYWORD && Boolean(errors.SEO_METAKEYWORD)
                                      // }
                                      value={values.SEO_METAKEYWORD + ""}
                                      onChange={handleChange}
                                    />
                                    {/* {touched.SEO_METAKEYWORD && errors.SEO_METAKEYWORD && (
                                      <FormHelperText error>
                                        {errors.SEO_METAKEYWORD}
                                      </FormHelperText>
                                    )} */}
                                  </Grid>
                                  <Grid item xs={6}>
                                    <MDInput
                                      variant="standard"
                                      fullWidth
                                      name="SEO_METADESCRIPTION"
                                      label="Metadescripción"
                                      // error={
                                      //   touched.SEO_METADESCRIPTION &&
                                      //   Boolean(errors.SEO_METADESCRIPTION)
                                      // }
                                      value={values.SEO_METADESCRIPTION}
                                      onChange={handleChange}
                                    />
                                    {/* {touched.SEO_METADESCRIPTION && errors.SEO_METADESCRIPTION && (
                                      <FormHelperText error>
                                        {errors.SEO_METADESCRIPTION}
                                      </FormHelperText>
                                    )} */}
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                sx={{
                                  paddingTop: "20px!important",
                                  // paddingLeft: "30px!important",
                                }}
                              >
                                <Grid container>
                                  <Grid item xs={11} pb={3} mb={3}>
                                    <MDTypography variant="h7">Imagen</MDTypography>
                                  </Grid>

                                  {file || values.IMAGEN ? (
                                    <Grid item xs={1}>
                                      <IconButton
                                        onClick={() => {
                                          setData({
                                            ...values,
                                            IMAGEN: "",
                                          });
                                          setFile(null);
                                          setCroppedImage(null);
                                          setImageHasBeenCropped(false);
                                        }}
                                      >
                                        <Close />
                                      </IconButton>
                                    </Grid>
                                  ) : (
                                    <Grid item xs={1} />
                                  )}
                                </Grid>
                                <Grid container spacing={4}>
                                  {/* IMG */}
                                  <>
                                    {values.IMAGEN !== "" && (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          backgroundColor: "#eee",
                                          padding: "2rem",
                                          width: "100%",
                                          height: "100%",
                                          minHeight: "300px",
                                        }}
                                      >
                                        <img src={getFullPathImg(values.IMAGEN)} alt="service" />
                                      </Box>
                                    )}
                                    {values.IMAGEN === "" && (
                                      <Grid container>
                                        {file && rootFile ? (
                                          <Grid
                                            item
                                            xs={12}
                                            sx={{
                                              ml: 0,
                                              mr: 0,
                                              minHeight: "350px",
                                              width: "100%",
                                              border: `1px dashed black`,
                                              borderRadius: "0px",
                                              textAlign: "center",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <Grid container>
                                              <Grid item xs={12}>
                                                <MDTypography
                                                  sx={{
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  Imagen Original
                                                </MDTypography>
                                                <Box
                                                  style={{
                                                    maxWidth: "100%",
                                                    padding: "1rem",
                                                    margin: "auto",
                                                  }}
                                                >
                                                  <Cropper
                                                    imageToCrop={URL.createObjectURL(rootFile)}
                                                    croppedImage={(value) => setCroppedImage(value)}
                                                    ratio={690 / 376}
                                                  />
                                                </Box>
                                              </Grid>
                                              <Grid item xs={12}>
                                                <MDTypography
                                                  sx={{
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  Vista Previa
                                                </MDTypography>
                                                {croppedImage ? (
                                                  <img
                                                    src={croppedImage}
                                                    alt="preview"
                                                    style={{ maxWidth: "90%", padding: "1rem" }}
                                                  />
                                                ) : (
                                                  <img
                                                    src={file.preview}
                                                    alt="preview"
                                                    style={{ maxWidth: "90%", padding: "1rem" }}
                                                  />
                                                )}
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        ) : (
                                          <Grid
                                            item
                                            id="imageBAnnercontainer"
                                            xs={12}
                                            sx={{
                                              ml: "30px",
                                              mr: 0,
                                              minHeight: "250px",
                                              width: "100%",
                                              border: `1px dashed black`,
                                              borderRadius: "0px",
                                              padding: "2rem",
                                            }}
                                          >
                                            <UploadImageBanner rootProps={getRootProps} />
                                            <input
                                              accept=".jpg, .jpeg, .png, .gif, .svg, .pdf"
                                              {...getInputProps()}
                                            />
                                          </Grid>
                                        )}
                                      </Grid>
                                    )}
                                    {showImageError && (
                                      <MDTypography fontSize={14} color="error" px={4} pt={1}>
                                        Debe subir y recortar una imagen
                                      </MDTypography>
                                    )}
                                  </>
                                  {/* //IMG */}
                                </Grid>
                              </Grid>

                              <Grid item xs={12} pb={0}>
                                <Box
                                  sx={{
                                    borderBottom: 2,
                                    borderColor: "black",
                                    width: "100%",
                                  }}
                                ></Box>
                              </Grid>
                              <Grid item xs={12}>
                                <MDInput
                                  type="text"
                                  variant="standard"
                                  fullWidth
                                  name="DESC_CORTA"
                                  label="Descripción corta"
                                  error={touched.DESC_CORTA && Boolean(errors.DESC_CORTA)}
                                  value={values.DESC_CORTA}
                                  onChange={handleChange}
                                  multiline
                                  rows={5}
                                />
                                {touched.DESC_CORTA && errors.DESC_CORTA && (
                                  <FormHelperText error>{errors.DESC_CORTA}</FormHelperText>
                                )}
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant="" fontSize={"12px"} color={"#7b809a"}>
                                  Descripción larga
                                </Typography>
                                <ReactQuill
                                  modules={modules}
                                  name="DESC_LARGA"
                                  fullWidth
                                  label="Descripción larga"
                                  // error={touched.DESC_LARGA && Boolean(errors.DESC_LARGA)}
                                  value={values.DESC_LARGA}
                                  onChange={(content, delta, source, editor) =>
                                    setFieldValue("DESC_LARGA", editor.getHTML())
                                  }
                                  theme="snow"
                                  className="react-quill-custom"
                                />

                                {/* {values.DESC_LARGA && values.DESC_LARGA && (
                                  <FormHelperText error>{errors.DESC_LARGA}</FormHelperText>
                                )} */}
                              </Grid>

                              <Grid item xs={12}>
                                <MDBox display="flex" justifyContent="flex-end">
                                  <MDButton
                                    type="submit"
                                    variant="gradient"
                                    color="primary"
                                    size="medium"
                                  >
                                    GUARDAR DATOS
                                  </MDButton>
                                </MDBox>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </MDBox>
                    </Form>
                  )}
                </Formik>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Edit;
