import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { BASE_URL, fetchUsersData, saveUsersData } from "./Data";
import { ToastContainer, toast, Bounce } from "react-toastify";
import PropTypes from "prop-types";
import "react-toastify/dist/ReactToastify.css";

function EditUsers() {
  const CustomToastContent = ({ title, subtitle }) => (
    <div>
      <strong style={{ fontSize: "16px" }}>{title}</strong>
      {subtitle && <p style={{ fontSize: "14px" }}>{subtitle}</p>}
    </div>
  );
  CustomToastContent.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
  };
  CustomToastContent.defaultProps = {
    title: "¡Guardado correctamente!",
    subtitle: "",
  };

  const msgOk = (title, subtitle) =>
    toast.success(<CustomToastContent title={title} subtitle={subtitle} />, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      cloUsersnClick: true,
      pauUsersnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });

  const msgError = (title, subtitle) =>
    toast.error(<CustomToastContent title={title} subtitle={subtitle} />, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      cloUsersnClick: true,
      pauUsersnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });
  const api_key = localStorage.getItem("access_token") || "";

  const { id } = useParams(); // Obtener el ID desde la URL
  const navigate = useNavigate(); // Hook para navegar programáticamente
  const [UsersData, setUsersData] = useState({
    ID: 0,
    NOMBRE: "",
    APELLIDOS: "",
    EMAIL: "",
    CREATE_DATE: "",
    PASSWORD: "",
    PASSWORD2: "",
    ACTIVO: true,
  });

  const inputToStateKeyMap = {
    txtNombre: "NOMBRE",
    txtApellidos: "APELLIDOS",
    txtFechaCreacion: "CREATE_DATE",
    txtEmail: "EMAIL",
    txtPassword: "PASSWORD",
    txtPassword2: "PASSWORD2",
    checkActivo: "ACTIVO",
  };

  const titleNav = id == 0 ? "usuario" : UsersData.NOMBRE + " " + UsersData.APELLIDOS;

  // Cargar los datos existentes
  useEffect(() => {
    const loadData = async () => {
      try {
        if (id == 0) return;
        const data = await fetchUsersData(id);
        if (data && data.length > 0) {
          // Asume que el backend devuelve un array y el elemento que necesitas está en la primera posición
          setUsersData({
            ID: data[0].ID,
            NOMBRE: data[0].NOMBRE,
            APELLIDOS: data[0].APELLIDOS,
            EMAIL: data[0].EMAIL,
            CREATE_DATE: data[0].FECHA_ALTA,
            PASSWORD: "",
            ACTIVO: data[0].ACTIVO,
          });
        } else {
          msgError("No se encontraron datos para el ID proporcionado");
        }
      } catch (error) {
        msgError("Error al obtener datos de Users:", error);
      }
    };
    loadData();
  }, [id]);

  // Manejar los cambios en los inputs
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    const stateKey = inputToStateKeyMap[id] || id; // Usa el mapeo, o el id directamente si no hay mapeo
    setUsersData((prevState) => ({
      ...prevState,
      [stateKey]: value,
    }));
  };
  // Manejar los cambios en los checks
  const handleChecksChange = (e) => {
    const { id, checked } = e.target;
    const stateKey = inputToStateKeyMap[id] || id; // Usa el mapeo, o el id directamente si no hay mapeo
    setUsersData((prevState) => ({
      ...prevState,
      [stateKey]: checked,
    }));
  };

  // Manejar el envío del formulario
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await saveUsersData(UsersData);

      if (response.ok) {
        const msgResponse = await response.json();
        // Si la solicitud fue exitosa
        if (msgResponse.error != "") {
          msgError(msgResponse.error);
        } else {
          msgOk();
          var id = msgResponse.response[0][""];
          navigate("/admin/Users/" + id);
        }
        // navigate("/donde-quiera-redirigir"); // Por ejemplo, a la página de confirmación o al listado
      } else {
        // Si hubo un problema con la solicitud
        const errorData = await response.json(); // Suponiendo que el servidor responde con detalles del error en JSON
        msgError(`Error al guardar: ${errorData.message}`);
        // Aquí podrías establecer algún estado para mostrar el error en la UI en lugar de un alert
      }
    } catch (error) {
      // Si hubo un error en la red o algo impidió completar la solicitud
      console.error("Error al hacer la solicitud:", error);
      msgError("Error al intentar guardar los datos.");
      // Similar al caso de error anterior, podrías manejar este error en la UI
    }
  };

  return (
    <DashboardLayout>
      <ToastContainer />
      <DashboardNavbar title={`Edición de ${titleNav}`} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Edición del registro
                </MDTypography>
              </MDBox>
              <MDBox py={3} px={3}>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={6}>
                    {/* Campos del formulario */}
                    <Grid item xs={6}>
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <MDInput
                            type="text"
                            label="Nombre"
                            id="txtNombre"
                            variant="standard"
                            fullWidth
                            value={UsersData.NOMBRE}
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <MDInput
                            type="text"
                            label="Apellidos"
                            id="txtApellidos"
                            variant="standard"
                            fullWidth
                            value={UsersData.APELLIDOS}
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <MDInput
                            type="text"
                            label="Email"
                            id="txtEmail"
                            variant="standard"
                            fullWidth
                            value={UsersData.EMAIL}
                            onChange={handleInputChange}
                            autoComplete="off"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container spacing={4}>
                        <Grid item xs={6}>
                          <MDInput
                            type="text"
                            label="Fecha de alta"
                            id="txtFechaCreacion"
                            variant="standard"
                            fullWidth
                            disabled={true}
                            value={UsersData.CREATE_DATE}
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={6} textAlign={"end"}>
                          <FormControlLabel
                            control={
                              <Switch
                                id="checkActivo"
                                defaultChecked
                                checked={UsersData.ACTIVO}
                                onChange={handleChecksChange}
                              />
                            }
                            label="Activo"
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <MDInput
                            type="password"
                            label="Nueva contraseña"
                            id="txtPassword"
                            variant="standard"
                            fullWidth
                            value={UsersData.PASSWORD}
                            autoComplete="new-password"
                            onChange={handleInputChange}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <MDInput
                            type="password"
                            label="Repite contraseña"
                            id="txtPassword2"
                            autoComplete="off"
                            variant="standard"
                            fullWidth
                            value={UsersData.PASSWORD2}
                            onChange={handleInputChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <MDBox display="flex" justifyContent="flex-end">
                        <MDButton type="submit" variant="gradient" color="primary" size="medium">
                          GUARDAR DATOS
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                </form>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditUsers;
