/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// react-router-dom components
import React, { useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PropTypes from "prop-types";

function Basic() {
  const CustomToastContent = ({ title, subtitle }) => (
    <div>
      <strong style={{ fontSize: "16px" }}>{title}</strong>
      {subtitle && <p style={{ fontSize: "14px" }}>{subtitle}</p>}
    </div>
  );

  CustomToastContent.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
  };

  CustomToastContent.defaultProps = {
    subtitle: "",
  };

  const msgOk = (title, subtitle) =>
    toast.success(<CustomToastContent title={title} subtitle={subtitle} />, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });

  const msgError = (title, subtitle) =>
    toast.error(<CustomToastContent title={title} subtitle={subtitle} />, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });
  const { id } = useParams(); // Obtener el ID desde la URL
  const [email, setEmail] = useState("");
  const [NewPassword, setPassword] = useState("");
  const [NewPasswordRepeat, setPasswordRepeat] = useState("");
  const navigate = useNavigate(); // Obtén acceso al historial de navegación

  const handleSubmit = async (e) => {
    e.preventDefault(); // Previene el comportamiento por defecto del formulario

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/resetPass`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id, NewPassword, NewPasswordRepeat }),
      });

      if (response.ok) {
        const data = await response.json();
        // Redireccionar al usuario o actualizar la UI según sea necesario
        if (data.error == "") {
          msgOk("Guardado correctamente");
          navigate("/authentication/sign-in"); // Usa history.push para redirigir
        } else {
          msgError(data.error);
        }
      } else {
        msgError("Error al intentar conectar con el servidor");
      }
    } catch (error) {
      msgError("Error al intentar conectar con el servidor", error);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <ToastContainer />
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDButton variant="gradient" color="info" fullWidth>
            ATHLOAN
          </MDButton>
        </MDBox>
        <MDTypography display="block" variant="button" color="dark" my={1} textAlign={"center"}>
          Escribe tu nueva contraseña
        </MDTypography>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Nueva contraseña"
                fullWidth
                value={NewPassword}
                onChange={(e) => setPassword(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Repite contraseña"
                fullWidth
                value={NewPasswordRepeat}
                onChange={(e) => setPasswordRepeat(e.target.value)}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit">
                Cambiar contraseña
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                O puedes iniciar sesión desde este{" "}
                <Link to="/authentication/sign-in" style={{ textDecoration: "none" }}>
                  <MDTypography variant="button" color="info" fontWeight="medium" textGradient>
                    enlace
                  </MDTypography>
                </Link>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
