import React, { useState, useContext, useEffect } from "react";
import { Grid, Box, Typography, Container, Button, Divider } from "@mui/material";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Cookies from "js-cookie";
import EmpresaCover from "../../../assets/images/empresa.png";
import Pilares from "../../../assets/images/Pilares.png";
import Premio from "../../../assets/images/Premio.png";
import HeaderFrontOffice from "../../../components/FrontOfficeHeaders/HeaderFrontOffice";
import { FrontOfficeFooter } from "../../../components/FrontOfficeFooter";
import { ServiceCardSmall } from "../../../components/ServiceCardSmall";
import SeoRoutesContext from "../../../components/shared/SeoRoutesContext";
import { TraduccionesContext } from "components/shared/TraduccionesContext";
import { getImageUrl } from "../../../shared/getImageUrl";
import { Helmet } from "react-helmet";

function EmpresaPage() {
  const location = useLocation();
  const seoRoutes = useContext(SeoRoutesContext);
  const navigate = useNavigate();
  const navigateTo = (routeKey, seoRoutes, lang) => {
    let path = "";
    const seoEntry = seoRoutes.find(
      (seo) =>
        seo.PAGE.replaceAll(".aspx", "").toLowerCase() === routeKey.toLowerCase() &&
        seo.ACRONIMO_ISO === lang
    );
    if (seoEntry) {
      path = `/${seoEntry.ACRONIMO_ISO}/${seoEntry.SEO_URL_FRIENDLY}`;
    } else {
      path = routeKey;
    }
    path = path.replace("//", "/");
    return path;
  };

  const handleNavigation = (key) => {
    const pathSegments = location.pathname.split("/").filter(Boolean);
    const lang = pathSegments[0] || "es"; // Asumir "es" como idioma por defecto si no se encuentra ninguno
    Cookies.set("lang", lang, { expires: 7 });
    const path = navigateTo(key, seoRoutes, lang);
    return path; // Not typically needed unless you have other uses for path after navigation
  };
  const { pathname } = useLocation();
  const { traducciones, loading, setLoading, idioma } = useContext(TraduccionesContext);
  const [services, setServices] = useState([]);
  const [equipo, setEquipo] = useState([]);
  const [equipoImg, setEquipoImg] = useState(null);

  useEffect(() => {
    const cargarServicios = async () => {
      setLoading(true);
      try {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/services/list/${idioma}`, {
          method: "GET",
        })
          .then((response) => response.json())
          .then((data) => {
            setLoading(false);
            setServices(
              data.response.data
                .filter((item) => item.active)
                .sort((a, b) => {
                  const nameA = a.pos;
                  const nameB = b.pos;
                  if (nameA < nameB) {
                    return -1;
                  }
                  if (nameA > nameB) {
                    return 1;
                  }
                  return 0;
                })
                .map((item) => ({
                  title: item.title,
                  // url: `${handleNavigation("servicios")}/${item.id}/${item.seoURLFriendly}`,
                  url: `${pathname.substring(0, pathname.lastIndexOf("/"))}/${item.seoURLFriendly}`,
                  seoURLFriendly: item.seoURLFriendly,
                }))
            );
          })
          .catch((error) => {
            setLoading(false);
            console.error("Error fetching data: ", error);
          });
      } catch (error) {
        setLoading(false);
        console.error("Error al cargar los datos:", error);
      }
    };
    const cargarEquipo = async () => {
      setLoading(true);
      try {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/equipos?lang=${idioma}`, {
          method: "GET",
        })
          .then((response) => response.json())
          .then((data) => {
            setLoading(false);
            setEquipo(
              data
                .filter((item) => item.ACTIVO)
                .sort((a, b) => {
                  const nameA = a.POS;
                  const nameB = b.POS;
                  if (nameA < nameB) {
                    return -1;
                  }
                  if (nameA > nameB) {
                    return 1;
                  }
                  return 0;
                })
                .map((item) => ({
                  title: item.NOMBRE,
                  subtitle: item.SUBTITULO,
                  content:
                    item.DESC_CORTA.length > 100
                      ? item.DESC_CORTA.slice(0, 100) + "..."
                      : item.DESC_CORTA,
                  url: `${handleNavigation("empresa")}/${item.SEO_URL_FRIENDLY}/`,
                  showButton: item.TIENE_DETALLE,
                  image: getImageUrl(item.IMAGEN, "equipo"),
                }))
            );
            setEquipoImg(getImageUrl(data[0].FOTO_EQUIPO, "equipo"));
          })
          .catch((error) => {
            setLoading(false);
            console.error("Error fetching data: ", error);
          });
      } catch (error) {
        setLoading(false);
        console.error("Error al cargar los datos:", error);
      }
    };
    if (idioma) {
      cargarServicios();
      cargarEquipo();
    }
  }, [idioma]);

  useEffect(() => {
    if (location.hash) {
      setTimeout(() => {
        const element = document.querySelector(location.hash);
        if (element) {
          const yOffset = -50; // Offset de 50 píxeles hacia arriba
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({ top: y });
        }
      }, 400);
    }
  }, [location]);

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${document.location.href}`} />
        {seoRoutes
          .filter((x) => x.SEO_PAGE_ID === "4")
          .map(
            (
              lang,
              index // `index` es el índice del elemento actual en el array
            ) => (
              <link
                key={lang.ACRONIMO_ISO + index} // Concatenación del ISO y el índice para asegurar unicidad
                rel="alternate"
                hrefLang={lang.ACRONIMO_ISO.toLowerCase()}
                href={`${document.location.origin}/${lang.ACRONIMO_ISO.toLowerCase()}/${
                  lang.SEO_URL_FRIENDLY.endsWith("/")
                    ? lang.SEO_URL_FRIENDLY
                    : lang.SEO_URL_FRIENDLY + "/"
                }`}
              />
            )
          )}
      </Helmet>

      <Box sx={{ backgroundColor: "#FFF", "& *": { fontFamily: "Nunito !important" } }}>
        <Box marginBottom={"84px"}>
          <HeaderFrontOffice keySelected="empresa" />
        </Box>
        <Box
          sx={{
            minHeight: "815px",
            backgroundImage: `url(${EmpresaCover})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            paddingY: "54px",
          }}
        >
          <Container
            sx={{
              color: "#FFF",
              maxWidth: {
                xl: "1320px!important", // max-width 1320px para xl
                lg: "1140px!important", // max-width 1140px para lg
                md: "100%!important", // max-width 100% para md
                sm: "100%!important", // max-width 100% para sm
                xs: "100%!important", // max-width 100% para xs
              },
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <Box width={"100%"}>
                  <Typography
                    sx={{ fontSize: "38px", letterSpacing: "0.48px", fontWeight: "bolder" }}
                    component="h1"
                  >
                    {traducciones && traducciones.EMPRESA.title}
                  </Typography>
                  <Typography
                    sx={{ fontSize: "36px", letterSpacing: "0.19px", marginTop: "30px" }}
                    component="h2"
                  >
                    {traducciones && traducciones.EMPRESA.subtitle}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container marginTop={"10px"}>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                sx={{
                  paddingTop: "28px",
                  paddingRight: {
                    lg: "0", // max-width 100% para md
                    xs: "15px", // max-width 100% para xs
                  },
                  paddingLeft: {
                    lg: "0", // max-width 100% para md
                    xs: "15px", // max-width 100% para xs
                  },
                }}
              >
                <Typography
                  sx={{ fontSize: "24px", letterSpacing: "0.29px", fontWeight: "bolder" }}
                  component="h3"
                >
                  {traducciones && traducciones.EMPRESA.begins_title}
                </Typography>
                {traducciones && (
                  <Box
                    marginTop={"21px"}
                    sx={{
                      fontSize: "15px",
                      lineHeight: "21px",
                      letterSpacing: "0.18px",
                      "& p": { marginBottom: "2rem" },
                      maxWidth: {
                        lg: "300px", // max-width 100% para md
                        xs: "100%", // max-width 100% para xs
                      },
                    }}
                    dangerouslySetInnerHTML={{
                      __html: traducciones.EMPRESA.begins_description,
                    }}
                  />
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                sx={{
                  paddingTop: "28px",
                  paddingLeft: {
                    lg: "0", // max-width 100% para md
                    xs: "15px", // max-width 100% para xs
                  },
                  paddingRight: {
                    lg: "0", // max-width 100% para md
                    xs: "15px", // max-width 100% para xs
                  },
                }}
              >
                <Typography
                  sx={{ fontSize: "24px", letterSpacing: "0.29px", fontWeight: "bolder" }}
                  component="h3"
                >
                  {traducciones && traducciones.EMPRESA.present_title}
                </Typography>
                {traducciones && (
                  <Box
                    marginTop={"21px"}
                    sx={{
                      fontSize: "15px",
                      lineHeight: "21px",
                      letterSpacing: "0.18px",
                      "& p": { marginBottom: "2rem" },
                      maxWidth: {
                        lg: "300px", // max-width 100% para md
                        xs: "100%", // max-width 100% para xs
                      },
                    }}
                    dangerouslySetInnerHTML={{
                      __html: traducciones && traducciones.EMPRESA.present_description,
                    }}
                  />
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                sx={{ marginTop: { xs: "28px", sm: "28px", md: "0px", lg: "0px", xl: "0px" } }}
              >
                <Box
                  sx={{
                    backgroundColor: "#FFF",
                    color: "#222222",
                    borderRadius: "16px",
                    padding: "28px",
                  }}
                >
                  <Typography
                    sx={{ fontSize: "24px", letterSpacing: "0.29px", fontWeight: "bolder" }}
                    component="h3"
                  >
                    {traducciones && traducciones.EMPRESA.future_title}
                  </Typography>
                  <Box
                    marginTop={"21px"}
                    sx={{
                      fontSize: "15px",
                      lineHeight: "21px",
                      letterSpacing: "0.18px",
                      "& p": { marginBottom: "2rem" },
                      maxWidth: {
                        lg: "300px", // max-width 100% para md
                        xs: "100%", // max-width 100% para xs
                      },
                    }}
                    dangerouslySetInnerHTML={{
                      __html: traducciones && traducciones.EMPRESA.future_description,
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Container sx={{ paddingY: "28px" }}>
          <Grid container sx={{ marginBottom: "96px" }}>
            <Grid item xs={12}>
              <Typography
                gutterBottom
                fontSize={"40px"}
                sx={{ lineHeight: "1", fontWeight: "bold" }}
                component="h2"
              >
                {traducciones && traducciones.EMPRESA.team_title}
              </Typography>
              <Typography gutterBottom fontSize={"18px"} sx={{ lineHeight: "20px" }}>
                {traducciones && traducciones.EMPRESA.team_subtitle}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  width: "100%",
                  height: "361px",
                  backgroundImage: `url("${equipoImg}")`,
                  backgroundSize: "cover",
                  backgroundColor: "#F0F0F0",
                  marginTop: "23px",
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            {equipo.slice(0, 2).map((item, index) => (
              <Grid
                key={`${item.url}-index`}
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                sx={{ marginBottom: "56px" }}
              >
                <Box
                  width="100%"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: {
                      xs: "center",
                      sm: "center",
                      md: "start",
                      lg: "start",
                      xl: "start",
                    },
                    justifyContent: {
                      xs: "center",
                      sm: "center",
                      md: "start",
                      lg: "start",
                      xl: "start",
                    },
                  }}
                >
                  <Box
                    sx={{
                      backgroundImage: `url("${item.image}")`,
                      backgroundColor: "#F0F0F0",
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                      width: "200px",
                      height: "200px",
                      borderRadius: "200px",
                      marginBottom: "1rem",
                    }}
                  />
                  <Box
                    width="90%"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: {
                        xs: "center",
                        sm: "center",
                        md: "start",
                        lg: "start",
                        xl: "start",
                      },
                      justifyContent: {
                        xs: "center",
                        sm: "center",
                        md: "start",
                        lg: "start",
                        xl: "start",
                      },
                    }}
                  >
                    <Typography
                      sx={{ fontSize: "24px", lineHeight: "44px", letterSpacing: "0.29px" }}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        lineHeight: "44px",
                        letterSpacing: "0.18px",
                        color: "#6C6B70",
                      }}
                    >
                      {item.subtitle}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        lineHeight: "21px",
                        letterSpacing: "0.18px",
                        textAlign: {
                          xs: "center",
                          sm: "center",
                          md: "left",
                          lg: "left",
                          xl: "left",
                        },
                      }}
                    >
                      {item.content}
                    </Typography>
                    {item.showButton && (
                      <Link to={`${item.url}`}>
                        <Button
                          sx={{
                            background:
                              "transparent linear-gradient(352deg, #A24B5D 0%, #4B5DA2 100%) 0% 0% no-repeat padding-box",
                            borderRadius: "80px !important",
                            color: "#FFFFFF !important",
                            fontSize: "13px",
                            height: "32px",
                            width: "226px",
                            textTransform: "none",
                            marginTop: "2rem",
                          }}
                        >
                          {traducciones && traducciones.EMPRESA.more_info_button_text}
                        </Button>
                      </Link>
                    )}
                  </Box>
                </Box>
              </Grid>
            ))}
            {equipo.slice(2, equipo.length).map((item, index) => (
              <Grid
                key={`${item.url}-index`}
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                xl={3}
                sx={{ marginBottom: "56px" }}
              >
                <Box
                  width="100%"
                  maxWidth={"414px"}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      backgroundImage: `url("${item.image}")`,
                      backgroundColor: "#F0F0F0",
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                      width: "200px",
                      height: "200px",
                      borderRadius: "200px",
                      marginBottom: "1rem",
                    }}
                  />
                  <Box
                    width="100%"
                    maxWidth={"414px"}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{ fontSize: "24px", lineHeight: "44px", letterSpacing: "0.29px" }}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        lineHeight: "44px",
                        letterSpacing: "0.18px",
                        color: "#6C6B70",
                      }}
                    >
                      {item.subtitle}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
        <Grid
          container
          component={Box}
          sx={{
            padding: "61px 0 77px 0",
            "& *": { fontFamily: "Nunito !important" },
            background: "white",
            height: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          id="div-Valores"
        >
          <Grid item xs={12}>
            <Box textAlign={"center"}>
              <Typography
                gutterBottom
                fontSize={"40px"}
                sx={{ lineHeight: "1", fontWeight: "bold" }}
                component="h2"
              >
                {traducciones && traducciones.EMPRESA.values_title}
              </Typography>
              <Typography gutterBottom fontSize={"18px"} sx={{ lineHeight: "20px" }}>
                {traducciones && traducciones.EMPRESA.values_subtitle}
              </Typography>
              <Box
                display={{ xs: "none", sm: "none", md: "block", lg: "block", xl: "block" }}
                marginTop={"40px"}
              >
                <img src={Pilares} alt="Pilares" />
              </Box>
              <Box
                sx={{
                  width: "100%",
                  justifyContent: "space-evenly",
                  padding: "0px",
                  marginTop: "60px",
                  maxWidth: "830px",
                  margin: "auto",
                }}
                display={{ xs: "block", sm: "block", md: "none", lg: "none", xl: "none" }}
              >
                <Box>
                  <Typography fontSize={"48px"} sx={{ color: "#A24B5D" }}>
                    A
                  </Typography>
                  <Typography
                    fontSize={"16px"}
                    sx={{ color: "#666", fontWeight: "bold" }}
                    dangerouslySetInnerHTML={{
                      __html:
                        traducciones &&
                        traducciones.EMPRESA.Asesoramiento_en_recuperacion.replaceAll(
                          "\n",
                          "<br/>"
                        ),
                    }}
                  ></Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column-reverse",
                  }}
                >
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{
                      borderRight: "2px solid",
                      height: "20%",
                      position: "relative",
                      top: "-40px",
                    }}
                  />
                </Box>
                <Box>
                  <Typography fontSize={"48px"} sx={{ color: "#A24B5D" }}>
                    L
                  </Typography>
                  <Typography
                    fontSize={"16px"}
                    sx={{ color: "#666", fontWeight: "bold" }}
                    dangerouslySetInnerHTML={{
                      __html:
                        traducciones && traducciones.EMPRESA.L_texto.replaceAll("\n", "<br/>"),
                    }}
                  ></Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column-reverse",
                  }}
                >
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{
                      borderRight: "2px solid",
                      height: "20%",
                      position: "relative",
                      top: "-40px",
                    }}
                  />
                </Box>
                <Box>
                  <Typography fontSize={"48px"} sx={{ color: "#A24B5D" }}>
                    T
                  </Typography>
                  <Typography
                    fontSize={"16px"}
                    sx={{ color: "#666", fontWeight: "bold" }}
                    dangerouslySetInnerHTML={{
                      __html:
                        traducciones && traducciones.EMPRESA.T_texto.replaceAll("\n", "<br/>"),
                    }}
                  ></Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column-reverse",
                  }}
                >
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{
                      borderRight: "2px solid",
                      height: "20%",
                      position: "relative",
                      top: "-40px",
                    }}
                  />
                </Box>
                <Box>
                  <Typography fontSize={"48px"} sx={{ color: "#A24B5D" }}>
                    H
                  </Typography>
                  <Typography
                    fontSize={"16px"}
                    sx={{ color: "#666", fontWeight: "bold" }}
                    dangerouslySetInnerHTML={{
                      __html:
                        traducciones && traducciones.EMPRESA.H_texto.replaceAll("\n", "<br/>"),
                    }}
                  ></Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Container>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column-reverse",
                sm: "column-reverse",
                md: "row",
                lg: "row",
                xl: "row",
              },
            }}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box
                sx={{
                  background:
                    "transparent linear-gradient(354deg, #A24B5D 0%, #4B5DA2 100%) 0% 0% no-repeat padding-box",
                  height: "500px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "406.5px",
                    paddingLeft: "18.5px",
                    paddingRight: "18.5px",
                    borderLeft: {
                      xs: "none",
                      sm: "none",
                      md: "3px solid #FFF",
                      lg: "3px solid #FFF",
                      xl: "3px solid #FFF",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "#FFF",
                      font: "normal normal normal 16px/20px Nunito",
                      letterSpacing: "0.19px",
                    }}
                  >
                    {traducciones && traducciones.EMPRESA.a_description}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  height: "500px",
                }}
              >
                <Typography sx={{ color: "#4B5DA22E", fontSize: "455px" }}>A</Typography>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "calc(50% - 200px)",
                    width: "400px",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#A24B5D",
                      fontSize: "36px",
                      lineHeight: "46px",
                    }}
                    component={"h3"}
                  >
                    {traducciones && traducciones.EMPRESA.Asesoramiento_en_recuperacion_Upper}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container sx={{ display: "flex", flexDirection: "row" }}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  height: "500px",
                }}
              >
                <Typography sx={{ color: "#4B5DA22E", fontSize: "455px" }}>L</Typography>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "calc(50% - 200px)",
                    width: "400px",
                    textAlign: "center",
                  }}
                  component={"h3"}
                >
                  <Typography sx={{ color: "#A24B5D", fontSize: "36px", lineHeight: "46px" }}>
                    {traducciones && traducciones.EMPRESA.L_texto_Upper}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box
                sx={{
                  background:
                    "transparent linear-gradient(354deg, #A24B5D 0%, #4B5DA2 100%) 0% 0% no-repeat padding-box",
                  height: "500px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "406.5px",
                    paddingLeft: "18.5px",
                    paddingRight: "18.5px",
                    borderLeft: {
                      xs: "none",
                      sm: "none",
                      md: "3px solid #FFF",
                      lg: "3px solid #FFF",
                      xl: "3px solid #FFF",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "#FFF",
                      font: "normal normal normal 16px/20px Nunito",
                      letterSpacing: "0.19px",
                    }}
                  >
                    {traducciones && traducciones.EMPRESA.l_description}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column-reverse",
                sm: "column-reverse",
                md: "row",
                lg: "row",
                xl: "row",
              },
            }}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box
                sx={{
                  background:
                    "transparent linear-gradient(354deg, #A24B5D 0%, #4B5DA2 100%) 0% 0% no-repeat padding-box",
                  height: "500px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "406.5px",
                    paddingLeft: "18.5px",
                    paddingRight: "18.5px",
                    borderLeft: {
                      xs: "none",
                      sm: "none",
                      md: "3px solid #FFF",
                      lg: "3px solid #FFF",
                      xl: "3px solid #FFF",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "#FFF",
                      font: "normal normal normal 16px/20px Nunito",
                      letterSpacing: "0.19px",
                    }}
                  >
                    {traducciones && traducciones.EMPRESA.t_description}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  height: "500px",
                }}
              >
                <Typography sx={{ color: "#4B5DA22E", fontSize: "455px" }}>T</Typography>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "calc(50% - 200px)",
                    width: "400px",
                    textAlign: "center",
                  }}
                  component={"h3"}
                >
                  <Typography sx={{ color: "#A24B5D", fontSize: "36px", lineHeight: "46px" }}>
                    {traducciones && traducciones.EMPRESA.T_texto_Upper}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container sx={{ display: "flex", flexDirection: "row" }}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  height: "500px",
                }}
              >
                <Typography sx={{ color: "#4B5DA22E", fontSize: "455px" }}>H</Typography>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "calc(50% - 200px)",
                    width: "400px",
                    textAlign: "center",
                  }}
                  component={"h3"}
                >
                  <Typography sx={{ color: "#A24B5D", fontSize: "36px", lineHeight: "46px" }}>
                    {traducciones && traducciones.EMPRESA.h_texto_Upper}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box
                sx={{
                  background:
                    "transparent linear-gradient(354deg, #A24B5D 0%, #4B5DA2 100%) 0% 0% no-repeat padding-box",
                  height: "500px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "406.5px",
                    paddingLeft: "18.5px",
                    paddingRight: "18.5px",
                    borderLeft: {
                      xs: "none",
                      sm: "none",
                      md: "3px solid #FFF",
                      lg: "3px solid #FFF",
                      xl: "3px solid #FFF",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "#FFF",
                      font: "normal normal normal 16px/20px Nunito",
                      letterSpacing: "0.19px",
                    }}
                  >
                    {traducciones && traducciones.EMPRESA.h_description}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              height: {
                xs: "auto",
                sm: "auto",
                md: "auto",
                lg: "450px",
                xl: "573px",
              },
              padding: "0",
              "& *": { fontFamily: "Nunito !important" },
              background: "white",
            }}
          >
            <Container
              sx={{
                maxWidth: {
                  xl: "1320px!important", // max-width 1320px para xl
                  lg: "1140px!important", // max-width 1140px para lg
                  md: "100%!important", // max-width 100% para md
                  sm: "100%!important", // max-width 100% para sm
                  xs: "100%!important", // max-width 100% para xs
                },
                height: "100%",
              }}
            >
              <Box width={"100%"} marginY={"96px"}>
                <Typography
                  gutterBottom
                  sx={{
                    fontSize: {
                      xs: "40px",
                      sm: "40px",
                      md: "48px",
                      lg: "48px",
                      xl: "48px",
                    },
                    lineHeight: "56px",
                    color: "#222222",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                  component="h2"
                >
                  {traducciones && traducciones.EMPRESA.awards_title}
                </Typography>
                <Typography
                  fontSize={"18px"}
                  sx={{
                    fontSize: {
                      xs: "16px",
                      sm: "16px",
                      md: "18px",
                      lg: "18px",
                      xl: "18px",
                    },
                    lineHeight: "20px",
                    color: "#222222",
                    textAlign: "center",
                  }}
                >
                  {traducciones && traducciones.EMPRESA.awards_subtitle}
                </Typography>
              </Box>
              <Grid
                container
                component={Box}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: {
                    xs: "row",
                    sm: "row",
                    md: "row-reverse",
                    lg: "row-reverse",
                    xl: "row-reverse",
                  },
                  // maxWidth: "1300px",
                  padding: {
                    xs: "20px 0 30px 0",
                    lg: "0",
                  },
                }}
              >
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <img
                    src={Premio}
                    alt="PremioBestPerfomance"
                    style={{ maxWidth: "500px", width: "100%" }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Box
                    sx={{
                      width: "325px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        color: "#020200",
                        lineHeight: "26px",
                        letterSpacing: "0.22px",
                      }}
                    >
                      {traducciones && traducciones.HOME.reconocimiento}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Container>
        <Container>
          <Grid container sx={{ marginY: "4rem", textAlign: "center" }}>
            <Grid item xs={12}>
              <Typography
                gutterBottom
                fontSize={"40px"}
                sx={{ lineHeight: "1", color: "#01101D", fontWeight: "bold" }}
                component="h2"
              >
                {traducciones && traducciones.EMPRESA.services_title}
              </Typography>
              <Typography fontSize={"16px"} sx={{ color: "#666666" }}>
                {traducciones && traducciones.EMPRESA.services_subtitle}
              </Typography>
            </Grid>
            {!loading && (
              <Grid item xs={12} mt={"3rem"}>
                <Grid container rowGap={"1rem"} gap={"1rem"} justifyContent={"center"}>
                  {services.map((key) => (
                    <Grid
                      item
                      key={key.title}
                      xs={12}
                      sm={12}
                      md={3}
                      lg={3}
                      xl={3}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ServiceCardSmall
                        title={key.title}
                        url={key.url}
                        seoURLFriendly={key.seoURLFriendly}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Container>
        <FrontOfficeFooter />
      </Box>
    </>
  );
}
export default EmpresaPage;
