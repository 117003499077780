/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import { Formik } from "formik";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  DialogActions,
  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { UploadImageBanner } from "components/shared/UploadImageBanner";
import { RootDialog } from "components/RootDialog/RootDialog";
import { Cropper } from "components/Cropper/Cropper";

function SectionsCreateDialog({ mode, open, setOpen, formState, setFormState, setSubmitForm }) {
  const api_key = localStorage.getItem("access_token") || "";
  const [imageHasBeenCropped, setImageHasBeenCropped] = useState(false);
  const [showImageError, setShowImageError] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [file, setFile] = useState(null);
  const [rootFile, setRootFile] = useState(null);
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    multiple: false,
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      setRootFile(acceptedFiles[0]);
      setFile({
        ...acceptedFiles[0],
        preview: URL.createObjectURL(acceptedFiles[0]),
      });
    },
  });

  useEffect(() => {
    if (croppedImage) {
      setImageHasBeenCropped(true);
    } else {
      setImageHasBeenCropped(false);
    }
  }, [croppedImage]);
  const handleClose = () => {
    setOpen(false);
    setFile(null);
    setCroppedImage(null);
  };

  const handleCancelForm = () => {
    handleClose();
  };

  return (
    <>
      <RootDialog
        maxWidth="sm"
        fullWidth
        onClose={handleClose}
        aria-labelledby="compatible-mfa-applications-modal"
        open={open}
      >
        <Formik
          initialValues={{
            title: formState.title || "",
            subtitle: formState.subtitle || "",
            description: formState.description || "",
            pos: formState.pos || "",
          }}
          validationSchema={Yup.object().shape({
            title: Yup.string().max(255).required("Titulo es obligatorio"),
            subtitle: Yup.string().max(255).required("Subtitulo es obligatorio"),
            description: Yup.string(),
            pos: Yup.string().max(255).required("Posición es obligatorio"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            setSubmitting(true);
            setShowImageError(false);
            // if (imageHasBeenCropped || formState.image) {
            let { image } = formState;
            if (!image && rootFile) {
              let fileToUpload = rootFile;
              if (croppedImage) {
                const res = await fetch(croppedImage);
                const blob = await res.blob();
                fileToUpload = new File([blob], rootFile.name, {
                  type: rootFile.type,
                });
              }
              const data = new FormData();
              data.append("file", fileToUpload);
              const imageReq = await fetch(`${process.env.REACT_APP_API_BASE_URL}/uploader`, {
                method: "POST",
                body: data,
                headers: {
                  authToken: api_key,
                },
              });
              const imageResult = await imageReq.json();
              image = `${process.env.REACT_APP_API_BASE_URL}/files/default/${imageResult.filename
                .split("\\")
                .pop()}`.replaceAll("api/", "");
            }
            if (mode === "create") {
              setFormState({
                ...values,
                id: formState.id || `${Date.now()}-marker`,
                image,
              });
            } else {
              setFormState({
                ...values,
                id: formState.id || 0,
                image,
              });
            }

            setFile(null);
            setCroppedImage(null);
            setSubmitForm(true);
            // }
            // else {
            //   setFormState({
            //     ...values,
            //     id: formState.id || `${Date.now()}-marker`,
            //     image: "",
            //   });
            //   setFile(null);
            //   setCroppedImage(null);
            //   setSubmitForm(true);
            // }
            /* if (!imageHasBeenCropped && !formState.image) {
              setShowImageError(true);
            } */
            setSubmitting(false);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            submitForm,
            setFieldValue,
            touched,
            values,
          }) => (
            <>
              <DialogTitle sx={{ m: 0, p: 2, paddingBottom: "0 !important" }} variant="h6">
                Nueva Sección
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: "#CCC",
                }}
              >
                <CloseIcon />
              </IconButton>
              <DialogContent>
                <Grid container>
                  <form noValidate onSubmit={handleSubmit} style={{ width: "100%" }}>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.pos && errors.pos)}
                      sx={{ marginBottom: "1rem" }}
                    >
                      <OutlinedInput
                        key={`input-pos`}
                        notched={false}
                        size="small"
                        type="number"
                        value={values.pos}
                        name="pos"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label={`Posición`}
                        placeholder={`Posición`}
                        inputProps={{}}
                      />
                      {touched.pos && errors.pos && (
                        <FormHelperText key={`error-pos`} error>
                          {errors.pos}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.title && errors.title)}
                      sx={{ marginBottom: "1rem" }}
                    >
                      <OutlinedInput
                        key={`input-title`}
                        notched={false}
                        size="small"
                        type="text"
                        value={values.title}
                        name="title"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label={`Titulo`}
                        placeholder={`Titulo`}
                        inputProps={{}}
                      />
                      {touched.title && errors.title && (
                        <FormHelperText key={`error-title`} error>
                          {errors.title}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.subtitle && errors.subtitle)}
                      sx={{ marginBottom: "1rem" }}
                    >
                      <OutlinedInput
                        key={`input-subtitle`}
                        notched={false}
                        size="small"
                        type="text"
                        value={values.subtitle}
                        name="subtitle"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label={`Subtitulo`}
                        placeholder={`Subtitulo`}
                        inputProps={{}}
                      />
                      {touched.subtitle && errors.subtitle && (
                        <FormHelperText key={`error-subtitle`} error>
                          {errors.subtitle}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.description && errors.description)}
                      sx={{ marginBottom: "1rem" }}
                    >
                      <OutlinedInput
                        multiline
                        rows={5}
                        key={`input-description`}
                        notched={false}
                        size="small"
                        type="text"
                        value={values.description}
                        name="description"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label={`Descripción`}
                        placeholder={`Descripción`}
                        inputProps={{}}
                      />
                      {touched.description && errors.description && (
                        <FormHelperText key={`error-description`} error>
                          {errors.description}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </form>
                </Grid>
                <>
                  <Grid container>
                    <Grid item xs={11}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                      >
                        Imagen
                      </Typography>
                    </Grid>
                    {file || formState.image ? (
                      <Grid item xs={1}>
                        <IconButton
                          onClick={() => {
                            setFormState({
                              ...formState,
                              image: "",
                            });
                            setFile(null);
                            setCroppedImage(null);
                            setImageHasBeenCropped(false);
                          }}
                        >
                          <Close />
                        </IconButton>
                      </Grid>
                    ) : (
                      <Grid item xs={1} />
                    )}
                  </Grid>
                  {formState.image !== "" && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#eee",
                        padding: "2rem",
                      }}
                    >
                      <img src={formState.image} alt="service" />
                    </Box>
                  )}
                  {formState.image === "" && (
                    <Grid container>
                      {file && rootFile ? (
                        <Grid
                          item
                          xs={12}
                          sx={{
                            ml: 0,
                            mr: 0,
                            minHeight: "350px",
                            width: "100%",
                            border: `1px dashed black`,
                            borderRadius: "0px",
                            textAlign: "center",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Grid container>
                            <Grid item xs={6}>
                              <Typography
                                sx={{
                                  fontWeight: 600,
                                }}
                              >
                                Imagen Original
                              </Typography>
                              <Box style={{ maxWidth: "90%", padding: "1rem" }}>
                                <Cropper
                                  imageToCrop={URL.createObjectURL(rootFile)}
                                  croppedImage={(value) => setCroppedImage(value)}
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography
                                sx={{
                                  fontWeight: 600,
                                }}
                              >
                                Vista Previa
                              </Typography>
                              {croppedImage ? (
                                <img
                                  src={croppedImage}
                                  alt="preview"
                                  style={{ maxWidth: "90%", padding: "1rem" }}
                                />
                              ) : (
                                <img
                                  src={file.preview}
                                  alt="preview"
                                  style={{ maxWidth: "90%", padding: "1rem" }}
                                />
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid
                          item
                          id="imageBAnnercontainer"
                          xs={12}
                          sx={{
                            ml: 0,
                            mr: 0,
                            minHeight: "189px",
                            width: "100%",
                            border: `1px dashed black`,
                            borderRadius: "0px",
                            padding: "2rem",
                          }}
                        >
                          <UploadImageBanner rootProps={getRootProps} />
                          <input
                            accept=".jpg, .jpeg, .png, .gif, .svg, .pdf"
                            {...getInputProps()}
                          />
                        </Grid>
                      )}
                    </Grid>
                  )}
                  {showImageError && (
                    <Typography fontSize={14} color="error">
                      Debe subir y recortar una imagen
                    </Typography>
                  )}
                </>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCancelForm} variant="outlined" sx={{ color: "#000" }}>
                  CANCELAR
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={submitForm}
                  disabled={isSubmitting}
                  sx={{ color: "#FFF" }}
                >
                  GUARDAR
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
      </RootDialog>
    </>
  );
}

SectionsCreateDialog.propTypes = {
  mode: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  formState: PropTypes.object.isRequired,
  setFormState: PropTypes.func.isRequired,
  setSubmitForm: PropTypes.func.isRequired,
};

export default SectionsCreateDialog;
