// Asume que la URL base de tu API podría cambiar o ser reutilizada, por lo que podría ser buena idea definirla como constante.
export const BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/SMTP`;

/**
 * Obtiene los datos de SMTP para un ID específico.
 * @param {string} id - El ID del registro SMTP que se desea obtener.
 * @returns {Promise<any>} - Los datos de SMTP obtenidos.
 */
export const fetchSMTPData = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}`, {
      method: "GET",
      headers: {
        authToken: api_key,
      },
    });
    if (!response.ok) {
      throw new Error("Error al obtener los datos de SMTP");
    }
    return await response.json();
  } catch (error) {
    console.error("Error al obtener datos de SMTP:", error);
    throw error; // Propagar el error para manejarlo en el componente.
  }
};

/**
 * Envía los datos modificados de SMTP al servidor.
 * @param {Object} SMTPData - Los datos modificados de SMTP a guardar.
 * @returns {Promise<void>}
 */
const api_key = localStorage.getItem("access_token") || "";
export const saveSMTPData = async (SMTPData) => {
  var response = null;
  try {
    response = await fetch(`${BASE_URL}`, {
      method: "POST", // Cambiar a "PUT" si tu API usa PUT para actualizaciones.
      headers: {
        "Content-Type": "application/json",
        authToken: api_key,
      },
      body: JSON.stringify({
        ...SMTPData,
      }),
    });

    // Opcional: Procesar la respuesta si es necesario.
  } catch (error) {
    throw error; // Propagar el error para manejarlo en el componente.
  }
  return response;
};
