// Asume que la URL base de tu API podría cambiar o ser reutilizada, por lo que podría ser buena idea definirla como constante.
export const BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/webDefaults`;

/**
 * Obtiene los datos de SEO para un ID específico.
 * @param {string} id - El ID del registro SEO que se desea obtener.
 * @returns {Promise<any>} - Los datos de SEO obtenidos.
 */
export const fetchData = async () => {
  try {
    const response = await fetch(`${BASE_URL}/get_editSiteMap`, {
      method: "GET",
      headers: {
        authToken: api_key,
      },
    });
    if (!response.ok) {
      throw new Error("Error al obtener los datos");
    }
    return await response.json();
  } catch (error) {
    console.error("Error al obtener datos:", error);
    throw error; // Propagar el error para manejarlo en el componente.
  }
};

/**
 * Envía los datos modificados de SEO al servidor.
 * @param {Object} Data - Los datos modificados de SEO a guardar.
 * @returns {Promise<void>}
 */
const api_key = localStorage.getItem("access_token") || "";
export const saveData = async (Data) => {
  try {
    const response = await fetch(`${BASE_URL}/update_editSiteMap`, {
      method: "POST", // Cambiar a "PUT" si tu API usa PUT para actualizaciones.
      headers: {
        "Content-Type": "application/json",
        authToken: api_key,
      },
      body: JSON.stringify({
        ...Data,
      }),
    });

    if (!response.ok) {
      throw new Error("Error al guardar los datos");
    }
    return response;
    // Opcional: Procesar la respuesta si es necesario.
  } catch (error) {
    console.error("Error al guardar datos:", error);
    throw error; // Propagar el error para manejarlo en el componente.
  }
};
export const handleGenerateSitemap = async () => {
  try {
    const response = await fetch(`${BASE_URL}/get_SiteMap`, {
      method: "GET",
      headers: {
        authToken: api_key,
      },
    });

    if (!response.ok) {
      throw new Error("Error al obtener los datos");
    }

    // Handle Blob for downloading the file
    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", "sitemap.xml"); // Suggests save filename
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.error("Error al obtener datos:", error);
    throw error; // Propagate the error for further handling
  }
};
