import React, { useState, useContext } from "react";
import { Grid, Box, Typography, Divider, Button, Container } from "@mui/material";
import HeaderFrontOffice from "../../../components/FrontOfficeHeaders/HeaderFrontOffice";
import { FrontOfficeFooter } from "../../../components/FrontOfficeFooter";
import { TraduccionesContext } from "components/shared/TraduccionesContext";
import SeoRoutesContext from "../../../components/shared/SeoRoutesContext";
import { Helmet } from "react-helmet";

function Page() {
  const { traducciones } = useContext(TraduccionesContext);
  const seoRoutes = useContext(SeoRoutesContext);
  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${document.location.href}`} />
        {seoRoutes
          .filter((x) => x.SEO_PAGE_ID === "9")
          .map(
            (
              lang,
              index // `index` es el índice del elemento actual en el array
            ) => (
              <link
                key={lang.ACRONIMO_ISO + index} // Concatenación del ISO y el índice para asegurar unicidad
                rel="alternate"
                hrefLang={lang.ACRONIMO_ISO.toLowerCase()}
                href={`${document.location.origin}/${lang.ACRONIMO_ISO.toLowerCase()}/${
                  lang.SEO_URL_FRIENDLY.endsWith("/")
                    ? lang.SEO_URL_FRIENDLY
                    : lang.SEO_URL_FRIENDLY + "/"
                }`}
              />
            )
          )}
      </Helmet>
      <style>
        {`
        table .head {
          background-color: black;
          color: white;
        }
        .tableOverflow{
          width: 100%;
          overflow: auto;
        }
      `}
      </style>
      <Box>
        <HeaderFrontOffice />
      </Box>
      <Box
        sx={{
          background: "white",
          display: "flex",
          justifyContent: "center",
          marginTop: "85px",
          paddingTop: "50px",
          "& *": { fontFamily: "Nunito !important" },
        }}
      >
        <Container>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              fontSize: "36px",
              color: "#222222",
            }}
          >
            {traducciones && traducciones.COOKIES.Titulo}
          </Box>
          <Box
            sx={{
              marginBottom: "81px",
              ".ql-size-large": {
                fontSize: "24px",
                lineHeight: "30px",
                margin: "auto",
                display: "block",
                width: "100%",
                maxWidth: "546px",
                marginBottom: "54px",
                marginTop: "14px",
              },
              ".ql-align-center": {
                textAlign: "center",
              },
              table: {
                border: "1px solid black",
                width: "100%",
                margin: "auto",
              },
              td: {
                padding: "10px",
                border: "1px solid black",
              },
              th: {
                padding: "10px",
                border: "1px solid black",
              },
            }}
          >
            {traducciones && (
              <div
                dangerouslySetInnerHTML={{
                  __html: traducciones.COOKIES.Text.replace(/&lt;\/table&gt;/g, "</table>")
                    .replace(/&lt;thead([^>]*)&gt;/g, "<thead$1>")
                    .replace(/&lt;\/thead&gt;/g, "</thead>")
                    .replace(/&lt;tbody([^>]*)&gt;/g, "<tbody$1>")
                    .replace(/&lt;\/tbody&gt;/g, "</tbody>")
                    .replace(/&lt;tr([^>]*)&gt;/g, "<tr$1>")
                    .replace(/&lt;\/tr&gt;/g, "</tr>")
                    .replace(/&lt;td([^>]*)&gt;/g, "<td$1>")
                    .replace(/&lt;\/td&gt;/g, "</td>")
                    .replace(/&lt;th([^>]*)&gt;/g, "<th$1>")
                    .replace(/&lt;\/th&gt;/g, "</th>")
                    .replace(/&lt;/g, "<") // handle remaining escaped less-than signs
                    .replace(/&gt;/g, ">"), // handle remaining escaped greater-than signs
                }}
              />
            )}
          </Box>
        </Container>
      </Box>
      <FrontOfficeFooter />
    </>
  );
}
export default Page;
