import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Container,
  Button,
  Divider,
} from "@mui/material";
import Cookies from "js-cookie";
import SeoRoutesContext from "../../../components/shared/SeoRoutesContext";
import IconLocation from "../../../assets/images/icon-location.svg";
import IconBulb from "../../../assets/images/icon-bulb.svg";
import IconUniversity from "../../../assets/images/icon-university.svg";
import HeaderFrontOffice from "../../../components/FrontOfficeHeaders/HeaderFrontOffice";
import { FrontOfficeFooter } from "../../../components/FrontOfficeFooter";
import { TraduccionesContext } from "components/shared/TraduccionesContext";
import { useParams } from "react-router-dom";
import { getImageUrl } from "../../../shared/getImageUrl";
import { Helmet } from "react-helmet";

function EquipoPage() {
  const [seoParams, setSeoParams] = React.useState(null);
  const seoRoutes = useContext(SeoRoutesContext);
  const { traducciones, loading, setLoading, idioma, idiomas } = useContext(TraduccionesContext);
  const [services, setServices] = useState([]);
  const [equipo, setEquipo] = useState(null);
  const [equipoLangChanged, setequipoLangChanged] = useState(null);
  const { id: searchParam } = useParams();
  const navigateTo = (routeKey, seoRoutes, lang) => {
    let path = "";
    const seoEntry = seoRoutes.find(
      (seo) =>
        seo.PAGE.replaceAll(".aspx", "").toLowerCase() === routeKey.toLowerCase() &&
        seo.ACRONIMO_ISO === lang
    );
    if (seoEntry) {
      path = `/${seoEntry.ACRONIMO_ISO}/${seoEntry.SEO_URL_FRIENDLY}`;
    } else {
      path = routeKey;
    }
    path = path.replace("//", "/");
    return path;
  };

  const handleNavigation = (key) => {
    const pathSegments = location.pathname.split("/").filter(Boolean);
    const lang = pathSegments[0] || "es"; // Asumir "es" como idioma por defecto si no se encuentra ninguno
    Cookies.set("lang", lang, { expires: 7 });
    const path = navigateTo(key, seoRoutes, lang);
    return path; // Not typically needed unless you have other uses for path after navigation
  };

  useEffect(() => {
    const cargarServicios = async () => {
      setLoading(true);
      try {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/services/list/${idioma}`, {
          method: "GET",
        })
          .then((response) => response.json())
          .then((data) => {
            setLoading(false);
            setServices(
              data.response.data
                .filter((item) => item.active)
                .sort((a, b) => {
                  const nameA = a.pos;
                  const nameB = b.pos;
                  if (nameA < nameB) {
                    return -1;
                  }
                  if (nameA > nameB) {
                    return 1;
                  }
                  return 0;
                })
                .map((item) => ({
                  title: item.title,
                  url: `${handleNavigation("servicios")}/${item.id}/${item.seoURLFriendly}`,
                }))
            );
          })
          .catch((error) => {
            setLoading(false);
            console.error("Error fetching data: ", error);
          });
      } catch (error) {
        setLoading(false);
        console.error("Error al cargar los datos:", error);
      }
    };
    const cargarEquipo = async () => {
      var LangItem = idiomas.filter((item) => item.id == idioma)[0];
      setLoading(true);
      try {
        fetch(
          `${process.env.REACT_APP_API_BASE_URL}/equipos/getbyseo/${searchParam.replaceAll(
            "%20",
            " "
          )}?lang=${idioma}`,
          {
            method: "GET",
          }
        )
          .then((response) => response.json())
          .then((data) => {
            if (data !== "not-found") {
              setEquipo({
                ...data,
                IMAGEN: getImageUrl(data.IMAGEN, "equipo"),
                iso: LangItem.label.toLowerCase(),
                fullurl:
                  document.location.origin +
                  "/" +
                  LangItem.label.toLowerCase() +
                  "/" +
                  seoRoutes.filter((x) => x.SEO_PAGE_ID === "4" && x.LANGUAGE_ID == LangItem.id)[0]
                    .SEO_URL_FRIENDLY +
                  "/" +
                  data.SEO_URL_FRIENDLY,
              });

              setSeoParams({
                title: data.SEO_TITLE || "ALTH LOAN",
                keyword: data.SEO_METAKEYWORD || "",
                description: data.SEO_METADESCRIPTION || "",
              });
            }
            console.log(data);
            setLoading(false);

            try {
              var otherLangItem = idiomas.filter((item) => item.id != idioma)[0];
              var otherLang = otherLangItem.id;
              fetch(`${process.env.REACT_APP_API_BASE_URL}/equipos/${data.ID}?lang=${otherLang}`, {
                method: "GET",
              })
                .then((response) => response.json())
                .then((data) => {
                  if (data !== "not-found") {
                    setequipoLangChanged({
                      ...data,
                      IMAGEN: getImageUrl(data.IMAGEN, "equipo"),
                      iso: otherLangItem.label.toLowerCase(),
                      fullurl:
                        document.location.origin +
                        "/" +
                        otherLangItem.label.toLowerCase() +
                        "/" +
                        seoRoutes.filter(
                          (x) => x.SEO_PAGE_ID === "4" && x.LANGUAGE_ID == otherLangItem.id
                        )[0].SEO_URL_FRIENDLY +
                        "/" +
                        data[0].SEO_URL_FRIENDLY,
                    });
                  }
                })
                .catch((error) => {
                  console.error("Error fetching data: ", error);
                });
            } catch (error) {
              setLoading(false);
              console.error("Error al cargar los datos:", error);
            }
          })
          .catch((error) => {
            setLoading(false);
            console.error("Error fetching data: ", error);
          });
      } catch (error) {
        setLoading(false);
        console.error("Error al cargar los datos:", error);
      }
    };

    if (idioma) {
      cargarServicios();
      cargarEquipo();
    }
  }, [idioma]);

  return (
    <>
      {seoParams && equipoLangChanged && (
        <Helmet>
          <title>{seoParams.title}</title>
          <meta name="description" content={seoParams.description} />
          <meta name="keywords" content={seoParams.keyword} />
          <link rel="canonical" href={`${document.location.href}`} />
          <link rel="alternate" hrefLang={equipo.iso} href={`${equipo.fullurl}`} />
          <link
            rel="alternate"
            hrefLang={equipoLangChanged.iso}
            href={`${equipoLangChanged.fullurl}`}
          />
        </Helmet>
      )}
      <Box sx={{ backgroundColor: "#FFF", "& *": { fontFamily: "Nunito !important" } }}>
        <Box marginBottom={"84px"}>
          {equipoLangChanged && (
            <HeaderFrontOffice
              keySelected="empresa"
              currentPage="empresaDetail"
              urlLangChanged={
                seoRoutes.filter(
                  (x) => x.SEO_PAGE_ID === "4" && x.LANGUAGE_ID == equipoLangChanged[0].LANGUAGE_ID
                )[0].SEO_URL_FRIENDLY +
                "/" +
                equipoLangChanged[0].SEO_URL_FRIENDLY
              }
            />
          )}
        </Box>
        <Container sx={{ paddingY: "96px" }}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              sx={{ display: "flex", justifyContent: "center", marginBottom: "56px" }}
            >
              <Box
                sx={{
                  backgroundColor: "#F0F0F0",
                  background: `url("${equipo && equipo.IMAGEN}") no-repeat center center`,
                  backgroundSize: "contains",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  width: {
                    xs: "200px",
                    sm: "200px",
                    md: "399px",
                    lg: "399px",
                    xl: "399px",
                  },
                  height: {
                    xs: "200px",
                    sm: "200px",
                    md: "399px",
                    lg: "399px",
                    xl: "399px",
                  },
                  borderRadius: {
                    xs: "200px",
                    sm: "200px",
                    md: "399px",
                    lg: "399px",
                    xl: "399px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography
                sx={{
                  textAlign: { xs: "center", sm: "center", md: "left", lg: "left", xl: "left" },
                  fontSize: "24px",
                  lineHeight: "44px",
                  letterSpacing: "0.29px",
                }}
              >
                {equipo && equipo.NOMBRE}
              </Typography>
              <Typography
                sx={{
                  textAlign: { xs: "center", sm: "center", md: "left", lg: "left", xl: "left" },
                  fontSize: "15px",
                  lineHeight: "44px",
                  letterSpacing: "0.18px",
                  color: "#6C6B70",
                }}
              >
                {equipo && equipo.SUBTITULO}
              </Typography>
              <Box width={"414px"} maxWidth={"100%"}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    lineHeight: "21px",
                    letterSpacing: "0.18px",
                    textAlign: {
                      xs: "center",
                      sm: "center",
                      md: "left",
                      lg: "left",
                      xl: "left",
                    },
                  }}
                  dangerouslySetInnerHTML={{
                    __html: equipo && equipo.DESC_LARGA.replaceAll("\n", "<br/>"),
                  }}
                ></Typography>
              </Box>
              <Box
                width={"452px"}
                maxWidth={"100%"}
                sx={{
                  background:
                    "transparent linear-gradient(185deg, #A24B5D 0%, #4B5DA2 100%) 0% 0% no-repeat padding-box",
                  padding: "24px",
                  color: "#FFF",
                  marginY: "96px",
                  borderRadius: "12px",
                }}
              >
                <Grid container sx={{ display: "flex", alignItems: "center" }}>
                  <Grid
                    item
                    xs={1}
                    sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                  >
                    <img src={IconLocation} alt="location" />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography
                      sx={{
                        font: "normal normal normal 14px/21px Nunito",
                        letterSpacing: "0.17px",
                        marginY: "16px",
                      }}
                    >
                      {equipo && equipo.UBICACION}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container sx={{ display: "flex", alignItems: "center" }}>
                  <Grid
                    item
                    xs={1}
                    sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                  >
                    <img src={IconUniversity} alt="university" />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography
                      sx={{
                        font: "normal normal normal 14px/21px Nunito",
                        letterSpacing: "0.17px",
                        marginY: "16px",
                      }}
                    >
                      {equipo && equipo.ESTUDIOS}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ display: "flex", alignItems: "center" }}>
                  <Grid
                    item
                    xs={1}
                    sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                  >
                    <img src={IconBulb} alt="bulb" />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography
                      sx={{
                        font: "normal normal normal 14px/21px Nunito",
                        letterSpacing: "0.17px",
                        marginY: "16px",
                      }}
                    >
                      {equipo && equipo.CONOCIMIENTOS}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <FrontOfficeFooter />
      </Box>
    </>
  );
}
export default EquipoPage;
