import PropTypes from "prop-types";
import { Search } from "@mui/icons-material";
import { Grid, TextField, InputAdornment, Box } from "@mui/material";
import { GridToolbarContainer, GridToolbarFilterButton } from "@mui/x-data-grid";

function CustomDataGridToolBar({ searchValue, onSearchValueChange }) {
  return (
    <GridToolbarContainer>
      <Grid container sx={{ padding: "1rem 2rem" }}>
        <Grid item xs={11}>
          <TextField
            value={searchValue}
            onChange={onSearchValueChange}
            fullWidth
            id="table-searchbar"
            label="Buscar"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search sx={{ color: "#227bea" }} fontSize="medium" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={1}>
          <Box display="flex" justifyContent="flex-end" alignItems="center" height="100%">
            <GridToolbarFilterButton />
          </Box>
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
}

CustomDataGridToolBar.propTypes = {
  searchValue: PropTypes.string.isRequired,
  onSearchValueChange: PropTypes.func.isRequired,
};

export default CustomDataGridToolBar;
