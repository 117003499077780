import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, Card, Grid, Typography, Divider, IconButton } from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import KeysCreateDialog from "./keys_create_dialog";
import MDTypography from "components/MDTypography";
function KeysTable({ mode, list, setList }) {
  //   Modal
  const [dialogOpen, setDialogOpen] = useState(false);
  const [submitForm, setSubmitForm] = useState(false);
  const [formState, setFormState] = useState({
    image: "",
    title: "",
    subtitle: "",
  });

  useEffect(() => {
    if (
      submitForm &&
      formState.image !== "" &&
      formState.title !== "" &&
      formState.subtitle !== ""
    ) {
      let keyList = list.filter((key) => key.title && key.title !== "");
      if (formState.id && !String(formState.id).includes("-marker") && Number(formState.id) > 0) {
        keyList = keyList.map((key) => (key.id === formState.id ? formState : key));
      } else if (
        String(formState.id).includes("-marker") &&
        keyList.find((key) => key.id === formState.id)
      ) {
        keyList = keyList.map((key) => (key.id === formState.id ? formState : key));
      } else {
        keyList.push(formState);
      }
      setList(keyList);
      setFormState({
        image: "",
        title: "",
        subtitle: "",
      });
      setSubmitForm(false);
      setDialogOpen(false);
    }
  }, [submitForm, formState, list, setList]);

  const handleNew = () => {
    setFormState({
      pos: 1,
      image: "",
      title: "",
      subtitle: "",
    });
    setDialogOpen(true);
  };

  const handleDelete = (index) => {
    const newList = [...list];
    if (mode === "create") {
      newList.splice(index, 1);
      setList(newList);
    } else {
      setList(
        newList.map((item, _pos) =>
          _pos !== index ? item : { ...item, id: Number(`-${item.id}`) }
        )
      );
    }
  };

  const handleEdit = (index) => {
    setFormState(
      list.sort((a, b) => {
        const nameA = a.pos;
        const nameB = b.pos;
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })[index]
    );
    setDialogOpen(true);
  };

  const handleClosePreview = () => {
    setPreviewDialogOpen(false);
  };

  return (
    <Box>
      <Card
        sx={{
          marginTop: "2rem",
          padding: "2rem",
          border: "1px solid rgb(118, 118, 118)",
          borderRadius: 0,
        }}
      >
        <Box
          sx={{
            marginBottom: "1rem",
          }}
        >
          <Grid container sx={{ alignItems: "center" }}>
            <Grid item xs={8}>
              <MDTypography variant="h7">Claves</MDTypography>
            </Grid>
            {mode !== "view" && (
              <Grid item xs={4}>
                <Button
                  sx={{ textTransform: "capitalize", color: "#FFF" }}
                  disableElevation
                  fullWidth
                  size="large"
                  variant="contained"
                  onClick={handleNew}
                >
                  Agregar Clave <Add />
                </Button>
              </Grid>
            )}
          </Grid>
          <Grid container sx={{ alignItems: "center", padding: ".5rem" }}>
            <Grid item xs={5} sx={{ textAlign: "left" }}>
              <Typography fontsize={"16px"}>Titulo</Typography>
            </Grid>
            <Grid item xs={5} sx={{ textAlign: "left" }}>
              <Typography fontsize={"16px"}>Subtitulo</Typography>
            </Grid>
          </Grid>
          <Divider sx={{ borderColor: "#000" }} />
          {list.length > 0 ? (
            <>
              {list
                .filter((item) => String(item.id).includes("-marker") || item.id >= 0)
                .sort((a, b) => {
                  const nameA = a.pos;
                  const nameB = b.pos;
                  if (nameA < nameB) {
                    return -1;
                  }
                  if (nameA > nameB) {
                    return 1;
                  }
                  return 0;
                })
                .map((item, _index) => (
                  <>
                    <Grid key={_index} container sx={{ alignItems: "center", padding: ".5rem" }}>
                      <Grid item xs={5} sx={{ textAlign: "left" }}>
                        <Typography fontSize={"14px"}>{item.title}</Typography>
                      </Grid>
                      <Grid item xs={5} sx={{ textAlign: "left" }}>
                        <Typography fontSize={"14px"}>{item.subtitle}</Typography>
                      </Grid>
                      <Grid item xs={2} sx={{ textAlign: "right" }}>
                        <Grid container>
                          <Grid item xs={8} />
                          <Grid item xs={2}>
                            <IconButton
                              onClick={() => {
                                handleEdit(_index);
                              }}
                            >
                              <Edit sx={{ color: "#000" }} />
                            </IconButton>
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton
                              onClick={() => {
                                handleDelete(_index);
                              }}
                            >
                              <Delete sx={{ color: "#000" }} />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider key={`${_index}-divider`} sx={{ borderColor: "#000" }} />
                  </>
                ))}
            </>
          ) : (
            <Box sx={{ margin: "1rem 0" }}>
              <Typography sx={{ textAlign: "center" }}>Sin claves agregadas</Typography>
            </Box>
          )}
        </Box>
        <KeysCreateDialog
          open={dialogOpen}
          setOpen={setDialogOpen}
          formState={formState}
          setFormState={setFormState}
          setSubmitForm={setSubmitForm}
          mode={mode}
        />
      </Card>
    </Box>
  );
}

KeysTable.propTypes = {
  mode: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  setList: PropTypes.func.isRequired,
};

export default KeysTable;
