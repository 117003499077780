import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { ToastContainer, toast, Bounce } from "react-toastify";
import PropTypes from "prop-types";
import "react-toastify/dist/ReactToastify.css";
import "react-quill/dist/quill.snow.css"; // Importar los estilos para react-quill
import "../../../assets/css/masterAdmin.css";
import { getLanguages } from "shared/getLanguages";
import ServicioForm from "../form";
import { BeatLoader } from "react-spinners";

function EditServicios() {
  const api_key = localStorage.getItem("access_token") || "";
  const { id: recordId } = useParams(); // Obtener el ID desde la URL
  const [loading, setLoading] = useState(true);
  const [idiomas, setIdiomas] = useState([]);
  const [sections, setSections] = useState([]);
  const [description, setDescription] = useState("");
  const [keys, setKeys] = useState([]);
  const [formState, setFormState] = useState({
    general: {
      active: true,
      imagen: "",
      pos: 1,
      title: "",
      subtitle: "",
      seoTitle: "",
      seoMetakeyword: "",
      seoMetadescription: "",
      seoUrlFriendly: "",
    },
  });
  const [ItemidiomaSeleccionado, setIdiomaSeleccionado] = useState(null);
  const [idiomaSeleccionado, setIdiomaSeleccionadoValue] = useState(1);
  const CustomToastContent = ({ title, subtitle }) => (
    <div>
      <strong style={{ fontSize: "16px" }}>{title}</strong>
      {subtitle && <p style={{ fontSize: "14px" }}>{subtitle}</p>}
    </div>
  );
  CustomToastContent.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
  };
  CustomToastContent.defaultProps = {
    title: "¡Guardado correctamente!",
    subtitle: "",
  };

  const msgOk = (title, subtitle) =>
    toast.success(<CustomToastContent title={title} subtitle={subtitle} />, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      cloLegalPagesnClick: true,
      pauLegalPagesnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });

  const msgError = (title, subtitle) =>
    toast.error(<CustomToastContent title={title} subtitle={subtitle} />, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      cloLegalPagesnClick: true,
      pauLegalPagesnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });

  const navigate = useNavigate();

  // Cargar los datos existentes
  useEffect(() => {
    const cargarIdiomas = async () => {
      try {
        setLoading(true);
        fetch(
          `${process.env.REACT_APP_API_BASE_URL}/services/detail/${recordId}/${idiomaSeleccionado}`,
          {
            method: "GET",
            headers: {
              authToken: api_key,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            const servicioInfo = data.response.data;
            setFormState({
              general: {
                active: servicioInfo.active,
                imagen: servicioInfo.image,
                pos: servicioInfo.pos,
                title: servicioInfo.title,
                subtitle: servicioInfo.subtitle,
                seoTitle: servicioInfo.seoTitle,
                seoMetakeyword: servicioInfo.seoMetaKeyword,
                seoMetadescription: servicioInfo.seoMetaDescription,
                seoUrlFriendly: servicioInfo.seoURLFriendly,
              },
            });
            setDescription(servicioInfo.description);
            setSections(
              servicioInfo.sections
                .sort((a, b) => {
                  const nameA = a.pos;
                  const nameB = b.pos;
                  if (nameA < nameB) {
                    return -1;
                  }
                  if (nameA > nameB) {
                    return 1;
                  }
                  return 0;
                })
                .map((section) => ({
                  id: section.id,
                  image: section.image,
                  title: section.title,
                  subtitle: section.subtitle,
                  description: section.description,
                  pos: section.pos,
                  active: section.active,
                  pos: section.pos,
                }))
            );
            setKeys(
              servicioInfo.keys
                .sort((a, b) => {
                  const nameA = a.pos;
                  const nameB = b.pos;
                  if (nameA < nameB) {
                    return -1;
                  }
                  if (nameA > nameB) {
                    return 1;
                  }
                  return 0;
                })
                .map((key) => ({
                  id: key.id,
                  title: key.title,
                  subtitle: key.subtitle,
                  pos: key.pos,
                }))
            );
            setLoading(false);
          })
          .catch((error) => console.error("Error fetching data: ", error));
      } catch (error) {
        console.error("Error al cargar los datos:", error);
        msgError("Error", "Error al cargar los datos");
        setLoading(false);
      }
    };

    cargarIdiomas();
  }, [idiomaSeleccionado, recordId]);

  // Cargar los datos existentes
  useEffect(() => {
    const cargarIdiomas = async () => {
      try {
        setLoading(true);
        const datosIdiomas = await getLanguages();
        setIdiomas(
          datosIdiomas || [
            { ID: 1, NOMBRE: "ESPAÑOL" },
            { ID: 2, NOMBRE: "ENGLISH" },
          ]
        );
        const idiomaPorDefecto = datosIdiomas.find((idioma) => idioma.ID == 1);
        // Evita establecer el idioma por defecto aquí si ya tienes uno seleccionado
        if (idiomaPorDefecto && !ItemidiomaSeleccionado) {
          setIdiomaSeleccionado(idiomaPorDefecto);
          setIdiomaSeleccionadoValue(idiomaPorDefecto.LANGUAGE_ID);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error al cargar los idiomas:", error);
        setIdiomas([
          { ID: 1, NOMBRE: "ESPAÑOL" },
          { ID: 2, NOMBRE: "ENGLISH" },
        ]);
        setLoading(false);
      }
    };

    cargarIdiomas();
  }, []);

  return (
    <DashboardLayout>
      <ToastContainer />
      <DashboardNavbar title={`Edición de Servicio`} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Edición de Servicio
                </MDTypography>
              </MDBox>
              {(loading || formState.general.title === "") && (
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: `450px`,
                  }}
                >
                  <BeatLoader />
                </Box>
              )}
              {!loading && formState.general.title !== "" && (
                <MDBox py={3} px={3}>
                  <ServicioForm
                    mode="edit"
                    general={formState.general}
                    sections={sections}
                    setSections={setSections}
                    keys={keys}
                    setKeys={setKeys}
                    description={description}
                    setDescription={setDescription}
                    idiomas={idiomas}
                    idiomaSeleccionado={idiomaSeleccionado}
                    setIdiomaSeleccionado={setIdiomaSeleccionado}
                    serviceImageUrl={formState.general.imagen}
                    recordId={recordId}
                    ItemidiomaSeleccionado={ItemidiomaSeleccionado}
                    setIdiomaSeleccionadoValue={setIdiomaSeleccionadoValue}
                  />
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
export default EditServicios;
